import React from "react"
import { useAppTranslation } from "hooks/hooks"
import { useLanguage } from "hooks/useTranslate"
import { Container, Text, Title } from "@mantine/core"
import * as classes from "./MaintenanceMode.module.css"
import { LocalizedString } from "@appnflat-types/types"

export function MaintenanceMode({
    maintenanceMessage,
}: {
    maintenanceMessage: LocalizedString | undefined
}) {
    const t = useAppTranslation()
    const language = useLanguage()

    return (
        <Container className={classes.root}>
            <Title className={classes.title}>{t("core:app_under_maintenance")}</Title>
            <Text className={classes.description}>
                {maintenanceMessage?.[language] ?? t("core:please_come_back_in_a_few_minutes")}
            </Text>
        </Container>
    )
}
