import { z } from "zod"
import { emailString, mediumString, uidString } from "./BaseStrings"
import { objectWithAttachmentsSchema } from "./Common"
import { addOpenAPI } from "./zodExtensions"
import { requestEmailUUIDSchema, requestUUIDSchema } from "./Id"

/** The maximum number of attachments a request email can have. */
export const MAX_ATTACHMENTS_REQUEST_EMAIL = 10

const emailAddressAndNameSchema = z.object({
    /** The email address. */
    address: emailString,
    /** The name part of the email/group. */
    name: z.string().optional(),
})
export type EmailAddressAndName = z.infer<typeof emailAddressAndNameSchema>

/**
 * A request email.
 *
 * Corresponds to the document `db/buildings/{buildingRef}/requestEmails/{uuid}`. To get the
 * email itself, fetch it from the `bucket/buildings/{buildingRef}/requestEmails/{uuid}/email.eml`
 * object.
 */
export const requestEmailDocumentSchema = addOpenAPI(
    objectWithAttachmentsSchema(MAX_ATTACHMENTS_REQUEST_EMAIL).extend({
        uuid: requestEmailUUIDSchema,
        /** The uuid of the request.
         * If it is spam, the value should be `"spam"`.
         * If it is not associated to a request, the value should be `"unknown"`.
         * If it was not associated to a request and trashed, the value should be "trashed". */
        requestUUID: requestUUIDSchema,
        date: z.number(),
        preview: z.object({
            /** The first 80 characters of the email subject. */
            subject: mediumString,
            /** The first 80 characters of the email body. */
            body: mediumString,
        }),
        /** The author of the email. "self" if the author is the user, an email otherwise. */
        from: z.union([emailAddressAndNameSchema, z.literal("self")]),
        /** The uid of the user who sent the email. */
        internalSentBy: z.string().optional(),
        /** The recipients of the email. Currently limited to a maximum of 20 recipients. */
        recipients: z.array(emailAddressAndNameSchema).max(20),
        /** The uid of the first person to read the email or false if unread or "self" if sent by a user of the app. */
        read: z.union([uidString, z.literal(false), z.literal("self")]),
        /** The MessageID. */
        messageId: z.string().optional(),
        /** The messageID of the email this is in reply to (i.e., the In-Reply-To header). */
        inReplyTo: z.string().optional(),
        /** The References header (a list of messageIDs). */
        references: z.array(z.string()).optional(),
    }),
    "RequestEmailDocument"
)
/**
 * A request email.
 * Corresponds to the document `db/buildings/{buildingRef}/requestEmails/{uuid}`. To get the
 * email itself, fetch it from the `bucket/buildings/{buildingRef}/requestEmails/{uuid}/` object.
 */
export type RequestEmailDocument = z.infer<typeof requestEmailDocumentSchema>
