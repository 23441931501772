import { z } from "zod"
import { mediumString, shortMarkdownString } from "./BaseStrings"
import { addOpenAPI } from "./zodExtensions"

export const emailTemplateKindSchema = addOpenAPI(
    z.enum([
        /** Reminder of an insurance being invalid. */
        "insurance-incomplete-reminder",
        /** Reminder when an insurance is about to expire. */
        "insurance-about-to-expire-reminder",
        /** Reminder of a water heater being invalid. */
        "water-heater-incomplete-reminder",
        /** Reminder when a water heater is about to expire. */
        "water-heater-about-to-expire-reminder",
        /** Reminder for the n-th week of a unit having an unpaid balance. */
        "unit-unpaid-balance-week-0",
        "unit-unpaid-balance-week-1",
        "unit-unpaid-balance-week-2",
        "unit-unpaid-balance-week-3",
        "unit-unpaid-balance-week-4",
        "unit-unpaid-balance-week-5",
        "unit-unpaid-balance-week-6",
        "unit-unpaid-balance-week-7",
        "unit-unpaid-balance-week-8",
        "unit-unpaid-balance-week-9",
        "unit-unpaid-balance-week-10",
        "unit-unpaid-balance-week-11",
        "unit-unpaid-balance-week-12",
        "unit-unpaid-balance-week-13",
        "unit-unpaid-balance-week-14",
    ]),
    "EmailTemplateKind"
)

/** The schema for an email template.
 *
 * Injectable values:
 * - `$link`: A link to the building.
 * - `$unitNumber`: The number of the unit.
 * - `$unitUUID`: The UUID of the unit.
 * - `$buildingRef`: The reference of the building.
 * - `$fiscalYear`: The fiscal year of the building.
 * - `$amount`: The amount of money due by the unit (only for `unit-unpaid-balance-week-*` kind).
 */
export const emailTemplateSchema = addOpenAPI(
    z.object({
        /** The subject of the email template. */
        subject: mediumString,
        /** The body of the email template. */
        body: shortMarkdownString,
        /** The kind of email template. */
        kind: emailTemplateKindSchema,
    }),
    "EmailTemplate"
)
export type EmailTemplate = z.infer<typeof emailTemplateSchema>
