import z from "zod"
import { uuidString } from "./BaseStrings"
import { addOpenAPI, zodRegex } from "./zodExtensions"

/** The length of ids. */
export const ID_LENGTH = 22

/** The prefixes of ids. */
export const enum ID_PREFIXES {
    REQUEST_EMAIL_TEMPLATE = "ret",
    REQUEST_TAG = "rt",
    BILLING_ACCOUNT = "ba",
    RECURRING_BILL = "rb",
    BUILDING_GROUP = "bg",
    CHANGE_REQUEST = "cr",
    POST_TEMPLATE = "pt",
    TRANSACTION_BATCH = "tbt",
}

/** Generates a zod schema for an id. */
export function idSchemaGenerator<T extends ID_PREFIXES>(prefix: T) {
    return addOpenAPI(
        zodRegex<`${T}_${string}`>(new RegExp(`^${prefix}_[a-zA-Z0-9]{${ID_LENGTH}}$`)),
        `IdWithPrefix_${prefix}`
    )
}

/** The schema for a management company id. */
export const buildingGroupIdSchema = idSchemaGenerator(ID_PREFIXES.BUILDING_GROUP)
export type BuildingGroupId = z.infer<typeof buildingGroupIdSchema>

type BrandedUUIDs =
    | "SupplierUUID"
    | "BankUUID"
    | "UnitUUID"
    | "PersonUUID"
    | "ParkingUUID"
    | "LockerUUID"
    | "TransactionUUID"
    | "RequestUUID"
    | "RequestEmailUUID"
    | "CreditNoteUUID"
    | "PostUUID"
    | "CategoryUUID"

function brandedUUIDSchemaGenerator<B extends BrandedUUIDs>(brand: B) {
    return addOpenAPI(uuidString.brand<B>(), [brand, { format: brand }])
}

export const supplierUUIDSchema = brandedUUIDSchemaGenerator("SupplierUUID")
export type SupplierUUID = z.infer<typeof supplierUUIDSchema>
export const bankUUIDSchema = brandedUUIDSchemaGenerator("BankUUID")
export type BankUUID = z.infer<typeof bankUUIDSchema>
export const unitUUIDSchema = brandedUUIDSchemaGenerator("UnitUUID")
export type UnitUUID = z.infer<typeof unitUUIDSchema>
export const personUUIDSchema = brandedUUIDSchemaGenerator("PersonUUID")
export type PersonUUID = z.infer<typeof personUUIDSchema>
export const parkingUUIDSchema = brandedUUIDSchemaGenerator("ParkingUUID")
export type ParkingUUID = z.infer<typeof parkingUUIDSchema>
export const lockerUUIDSchema = brandedUUIDSchemaGenerator("LockerUUID")
export type LockerUUID = z.infer<typeof lockerUUIDSchema>
export const transactionUUIDSchema = brandedUUIDSchemaGenerator("TransactionUUID")
export type TransactionUUID = z.infer<typeof transactionUUIDSchema>
export const requestUUIDSchema = brandedUUIDSchemaGenerator("RequestUUID")
export type RequestUUID = z.infer<typeof requestUUIDSchema>
export const creditNoteUUIDSchema = brandedUUIDSchemaGenerator("CreditNoteUUID")
export type CreditNoteUUID = z.infer<typeof creditNoteUUIDSchema>
export const postUUIDSchema = brandedUUIDSchemaGenerator("PostUUID")
export type PostUUID = z.infer<typeof postUUIDSchema>
export const categoryUUIDSchema = brandedUUIDSchemaGenerator("CategoryUUID")
export type CategoryUUID = z.infer<typeof categoryUUIDSchema>
export const requestEmailUUIDSchema = brandedUUIDSchemaGenerator("RequestEmailUUID")
export type RequestEmailUUID = z.infer<typeof requestEmailUUIDSchema>
