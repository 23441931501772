import React, { useEffect, useRef, useState } from "react"
import { Anchor, ActionIcon, FileButton, Text, FileButtonProps, Button } from "@mantine/core"
import { WProps, useWProps } from "./WHelpers"
import { IconCloudUpload, IconFileDownload, IconX } from "@tabler/icons-react"
import { useAppTranslation } from "hooks/hooks"
import convertBase64 from "logic/files/convertBase64"
import { useFileURL } from "hooks/useFileURL"
import * as classes from "./WFileInput.module.css"
import { FilePathParams } from "@shared/pathForFile"
import { FileUpload, FileUploadOnlyPDF } from "@appnflat-types/FileUpload"

export type WFileInputAccept = "image/*" | "application/pdf" | "image/*,application/pdf"

type WFileInputProps = WProps<
    Omit<FileButtonProps, "onChange" | "type" | "resetRef" | "children">,
    "table" | "table-first-row" | "long"
> & {
    // fileURL?: string
    error?: string
    filePathParams?: FilePathParams | string | true
    uniqueCheckID?: string | number
    /** If true, mark the button as deactivated and show to the user that a file is currently being uploaded. */
    isUploading?: boolean
    /** If true, a download button will be present when the file exists on the backend and we are editing.
     * Otherwise, the download button will only be visible when not editing.
     */
    alwaysShowDownload?: boolean
} & (
        | {
              /** The kinds of files that can be uploaded. */
              accept: "application/pdf"
              /** Custom onChange function.
               * @param value A base64 encoded Data URL. */
              onChange?: (value: FileUploadOnlyPDF | null) => void
          }
        | {
              accept?: WFileInputAccept
              /** Custom onChange function.
               * @param value A base64 encoded Data URL. */
              onChange?: (value: FileUpload | null) => void
          }
    )

enum WFileInputStatus {
    empty,
    localFileSelected,
    remoteFileExists,
    uploading,
}

export function isFileUploadPdf(file: FileUpload | null): file is FileUploadOnlyPDF | null {
    return !file || (file.mimeType === "application/pdf" && file.fileName.endsWith(".pdf"))
}

/** A wrapper around FileInput. */
export function WFileInput(props: WFileInputProps) {
    const t = useAppTranslation()
    const {
        hidden,
        // fileURL,
        onChange,
        description,
        disabled,
        label,
        error,
        variant,
        filePathParams,
        uniqueCheckID,
        isUploading,
        accept,
        alwaysShowDownload,
        ...otherProps
    } = useWProps<WFileInputProps>(props)
    const [value, setValue] = useState<File | null>(null)
    const [localUniqueCheckID, setLocalUniqueCheckID] = useState<number>(0)
    const resetRef = useRef<() => void>(null)
    const url = useFileURL(filePathParams, `${uniqueCheckID},${localUniqueCheckID}`)

    useEffect(() => {
        if (!isUploading) setLocalUniqueCheckID((prev) => prev + 1)
    }, [isUploading])

    // const url = fileURL ?? _url
    if (hidden && !url) return undefined

    const status =
        isUploading ? WFileInputStatus.uploading
        : value !== null && !disabled ? WFileInputStatus.localFileSelected
        : url ? WFileInputStatus.remoteFileExists
        : WFileInputStatus.empty

    /** Responds to input changes. */
    async function localOnChange(value: File | null) {
        if (disabled) return
        setValue(value)
        if (onChange) {
            let params: FileUpload | null = null
            if (value) {
                const base64 = await convertBase64(value)
                const dataURL = base64.toString()
                const fileName = value.name
                const mimeType = value.type
                params = { dataURL, fileName, mimeType }
            }
            if (accept === "application/pdf") {
                if (isFileUploadPdf(params)) onChange(params)
            } else {
                onChange(params as any)
            }
        }
    }

    /** Clears a local file. */
    function clearFile() {
        if (disabled) return
        localOnChange(null)
        resetRef?.current?.()
    }

    function RightSection() {
        if (status === WFileInputStatus.uploading) return undefined
        return (
            <>
                {url && (alwaysShowDownload || disabled) ?
                    <Anchor
                        href={url}
                        target="_blank"
                        className={classes["download-button"]}
                        data-full-width={!disabled || undefined}
                    >
                        <IconFileDownload /> {t("core:download")}
                    </Anchor>
                :   undefined}
                {status === WFileInputStatus.localFileSelected && !disabled ?
                    <ActionIcon
                        color="gray"
                        onClick={clearFile}
                        aria-label={t("core:delete_file")}
                        className={classes["clear-button"]}
                    >
                        <IconX />
                    </ActionIcon>
                :   undefined}
            </>
        )
    }

    return (
        <div className={classes.root} data-variant={variant}>
            <label className={classes.label} data-variant={variant}>
                {label}
            </label>
            {!disabled ?
                <FileButton
                    resetRef={resetRef}
                    {...otherProps}
                    accept={accept}
                    disabled={disabled || status === WFileInputStatus.uploading}
                    onChange={localOnChange}
                    multiple={false}
                >
                    {(props) => (
                        <Button
                            loading={status === WFileInputStatus.uploading}
                            className={classes["upload-button"]}
                            leftSection={<IconCloudUpload />}
                            {...props}
                        >
                            {t(
                                (
                                    status === WFileInputStatus.remoteFileExists ||
                                        status === WFileInputStatus.localFileSelected
                                ) ?
                                    "core:change_file"
                                :   "core:upload_file"
                            )}
                        </Button>
                    )}
                </FileButton>
            :   undefined}
            <RightSection />
            {description ?
                <Text className={classes.description}>{description}</Text>
            :   undefined}
            {error ?
                <Text className={classes.error}>{error}</Text>
            :   undefined}
        </div>
    )
}
