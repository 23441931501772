import React, { ChangeEvent, useState, KeyboardEvent, useCallback, useMemo } from "react"
import {
    RecursiveKeyOf,
    RecursiveKeysOfType,
    DeepPartialPathValuePairs,
    DeepPartialPreservingBrand,
    InferUnbranded,
    DeepPartial,
} from "@appnflat-types/helpers"
import { z, ZodIssue, ZodTypeAny } from "zod"
import { DineroStorable, dineroStorableSchema } from "@appnflat-types/Common"
import cloneDeep from "lodash/cloneDeep"
import type { GetFieldType } from "lodash"
import { IconTrash } from "@tabler/icons-react"
import { WSelectOption } from "components/Inputs/WSelect"
import { useDeepCompareEffect } from "./useDeepCompareEffect"
import { useAppTranslation, useKey } from "./hooks"
import { useLanguage } from "./useTranslate"
import { showErrorNotification } from "logic/notifications"
import { ParsingErrors, parsingErrorLocale } from "@appnflat-types/parsingErrors"
import { DateTime } from "@shared/dates"
import { set, unset, get as sharedGet, omitByDeep } from "@shared/objects"
import { FileUpload, FileUploadOnlyPDF, fileUploadSchema } from "@appnflat-types/FileUpload"
import { LocalizedString } from "@appnflat-types/types"
import { isFileUploadPdf, WFileInputAccept } from "components/Inputs/WFileInput"
import { isRequiredField, limitsOfField } from "@appnflat-types/zodExtensions"
import { zodErrorDescription } from "@shared/localization/zodErrorDescription"

/** This hook returns a set of functions and objects to help manage forms. */
export function useForm<S extends ZodTypeAny, T = z.infer<S>>(
    schema: S,
    /** The function to call when submitting the form. */
    onSubmit: (value: T) => void,
    {
        initialValues,
        beforeOnSubmit,
        beforeSetInitialData,
        silentFailSubmit = false,
        clearOnSubmit = true,
        submitOnEnter = false,
    }: UseFormOptions<T> = {}
): UseFormReturn<S, T> {
    /** The return type of the useForm hook. */
    type Form = UseFormReturn<S, T>
    const language = useLanguage()
    const t = useAppTranslation()
    /** The initial values of the form. */
    const [initialData, setInitialData] = useState<Form["data"]>(undefined)
    /** The edited values of the form. */
    const [data, setData] = useState<Form["data"]>(undefined)
    /** The list of issues in the form. */
    const [errors, setErrors] = useState<ZodIssue[]>([])

    const get = useCallback(
        (path: string | symbol | number): any => sharedGet<any, any, any>(data ?? {}, path),
        [data]
    )

    const reset: Form["reset"] = useCallback(() => {
        setErrors([])
        setData(initialData)
    }, [initialData])

    const submit: Form["submit"] = useCallback(() => {
        try {
            const beforeOnSubmitData = beforeOnSubmit?.(data) ?? data
            const cleanedData = omitByDeep(beforeOnSubmitData, isOmittable)
            const validatedData = schema.safeParse(cleanedData)
            if (!validatedData.success) {
                console.error("Submit form parsing error:", {
                    errors: validatedData.error,
                    cleanedData,
                    beforeOnSubmitData,
                })
                const issues = validatedData.error.issues.map((issue) => ({
                    ...issue,
                    message: parsingErrorLocale(issue.message, language),
                }))
                setErrors(validatedData.error.issues)
                if (!silentFailSubmit) {
                    const message =
                        !issues[0]?.path.length ?
                            t("core:please_fill_the_form") // There is absolutely no data in the form.
                        :   zodErrorDescription(validatedData.error, language)
                    showErrorNotification({ customMessage: message })
                }
            } else {
                console.debug("Submit form result:", {
                    data: validatedData.data,
                    cleanedData,
                    beforeOnSubmitData,
                })
                onSubmit(validatedData.data)
                if (clearOnSubmit) reset()
                else setErrors([])
            }
        } catch (error) {
            if (error instanceof FormParseError) {
                console.error("Submit form error:", error)
                if (!silentFailSubmit) {
                    showErrorNotification({ customMessage: error.message[language] })
                }
            }
        }
    }, [
        beforeOnSubmit,
        data,
        schema,
        t,
        silentFailSubmit,
        language,
        onSubmit,
        clearOnSubmit,
        reset,
    ])

    useKey("CmdOrCtrl", "Enter", submitOnEnter ? submit : undefined)

    /** Submits the form when the enter key is pressed. */
    const sharedOptions = useCallback(
        (field: string | number | symbol, options?: FieldOptions): SharedOptions => ({
            onKeyDown:
                submitOnEnter && options?.submitOnEnter !== false ?
                    function submitOnEnter(e: KeyboardEvent<HTMLInputElement>) {
                        if (e.key === "Enter") {
                            e.preventDefault()
                            submit()
                        }
                    }
                :   undefined,
            error: findErrorMessage(errors, field, language),
            required: isRequiredField(schema, field as any),
        }),
        [errors, language, schema, submit, submitOnEnter]
    )

    const propsForWValueDisplay: Form["propsForWValueDisplay"] = useCallback(
        (field) => ({
            // @ts-ignore
            value: toString(get(field)),
        }),
        [get]
    )

    const propsForWDateInput: Form["propsForWDateInput"] = useCallback(
        (field, options) => {
            const value = get(field)
            const { min, max } = limitsOfField(schema, field as any)
            return {
                value,
                onChange: (newValue: number | undefined | null) => {
                    const updatedData = cloneDeep(data ?? {}) as any
                    set<any, any>(updatedData, field, newValue)
                    setData(updatedData)
                },
                maxDate: max ? new DateTime(max).toDate() : undefined,
                minDate: min ? new DateTime(min).toDate() : undefined,
                ...sharedOptions(field, options),
            }
        },
        [data, get, schema, sharedOptions]
    )

    const propsForWTextInput: Form["propsForWTextInput"] = useCallback(
        (field, options) => {
            // @ts-ignore
            const { min, max } = limitsOfField(schema, field)
            return {
                // @ts-ignore
                value: toString(get(field)),
                onChange: (event: ChangeEvent<HTMLInputElement>) => {
                    const updatedData = cloneDeep(data ?? {}) as any
                    set<any, any>(updatedData, field, event.currentTarget.value)
                    setData(updatedData)
                },
                maxLength: max,
                minLength: min,
                ...sharedOptions(field, options),
            }
        },
        [data, get, schema, sharedOptions]
    )

    const propsForWColorPicker: Form["propsForWColorPicker"] = useCallback(
        (field, options) => ({
            value: toString(get(field)),
            onChange: (value: string) => {
                const updatedData = cloneDeep(data ?? {}) as any
                set<any, any>(updatedData, field, value)
                setData(updatedData)
            },
            ...sharedOptions(field, options),
        }),
        [data, get, sharedOptions]
    )

    const propsForWNumberInput: Form["propsForWNumberInput"] = useCallback(
        (field, options) => {
            const { min, max } = limitsOfField(schema, field as any)
            return {
                value: get(field),
                onChange: (newValue: number | string | undefined) => {
                    const updatedData = cloneDeep(data ?? {}) as any
                    set<any, any>(updatedData, field, newValue)
                    setData(updatedData)
                },
                min,
                max,
                ...sharedOptions(field, options),
            }
        },
        [data, get, schema, sharedOptions]
    )

    const propsForWMarkdownEditor: Form["propsForWMarkdownEditor"] = useCallback(
        (field) => {
            const value = z.string().safeParse(get(field))
            return {
                value: value.success ? value.data : "",
                setValue: (newValue: string | undefined) => {
                    const updatedData = cloneDeep(data ?? {}) as any
                    set<any, any>(updatedData, field, newValue)
                    setData(updatedData)
                },
                ...sharedOptions(field, { submitOnEnter: false }),
            }
        },
        [data, get, sharedOptions]
    )

    const propsForWDineroInput: Form["propsForWDineroInput"] = useCallback(
        (field, options) => {
            const value = dineroStorableSchema.safeParse(get(field))
            return {
                value: value.success ? value.data : undefined,
                setValue: (newValue: DineroStorable | undefined) => {
                    const updatedData = cloneDeep(data ?? {}) as any
                    set<any, any>(updatedData, field, newValue)
                    setData(updatedData)
                },
                ...sharedOptions(field, options),
            }
        },
        [data, get, sharedOptions]
    )

    const propsForWSelect: Form["propsForWSelect"] = useCallback(
        (field) => {
            return {
                value: get(field),
                onChange: (newValue: string | null) => {
                    const updatedData = cloneDeep(data ?? {}) as any
                    set<any, any>(updatedData, field, newValue)
                    setData(updatedData)
                },
                ...sharedOptions(field, { submitOnEnter: false }),
            }
        },
        [data, get, sharedOptions]
    )

    const propsForWSelectMapped: Form["propsForWSelectMapped"] = useCallback(
        (field, options, entryToValue, valueToEntry) => {
            return {
                value: entryToValue(options, get(field)),
                onChange: (newValue: string | null) => {
                    const updatedData = cloneDeep(data ?? {}) as any
                    set<any, any>(updatedData, field, valueToEntry(options, newValue))
                    setData(updatedData)
                },
                options,
                ...sharedOptions(field, { submitOnEnter: false }),
            }
        },
        [data, get, sharedOptions]
    )

    const propsForWMultiSelect: Form["propsForWMultiSelect"] = useCallback(
        (field) => ({
            // @ts-ignore
            value: get(field),
            onChange: (newValue: string[] | null) => {
                const updatedData = cloneDeep(data ?? {}) as any
                set<any, any>(updatedData, field, newValue)
                setData(updatedData)
            },
            ...sharedOptions(field, { submitOnEnter: false }),
        }),
        [data, get, sharedOptions]
    )

    const propsForWEmailsInput: Form["propsForWEmailsInput"] = useCallback(
        (field) => ({
            value: get(field),
            onChange: (newValue: { address: string; name: string | undefined }[] | null) => {
                const updatedData = cloneDeep(data ?? {}) as any
                set<any, any>(updatedData, field, newValue)
                setData(updatedData)
            },
            ...sharedOptions(field, { submitOnEnter: false }),
        }),
        [data, get, sharedOptions]
    )

    const propsForWCheckbox: Form["propsForWCheckbox"] = useCallback(
        (field) => ({
            checked: Boolean(get(field)),
            onChange: (event: ChangeEvent<HTMLInputElement>) => {
                const updatedData = cloneDeep(data ?? {}) as any
                set<any, any>(updatedData, field, event.currentTarget.checked)
                setData(updatedData)
            },
            ...sharedOptions(field, { submitOnEnter: false }),
        }),
        [data, get, sharedOptions]
    )

    const propsForWFileInput: Form["propsForWFileInput"] = useCallback(
        (field, options) => ({
            onChange: (
                file:
                    | ((typeof options)["accept"] extends "application/pdf" ? FileUploadOnlyPDF
                      :   FileUpload)
                    | null
            ) => {
                // If we only accept PDFs and the file is not a PDF, do nothing.
                if (file && options.accept === "application/pdf" && !isFileUploadPdf(file)) return
                const updatedData = cloneDeep(data ?? {}) as any
                if (file) {
                    set<any, any>(updatedData, field, file)
                } else {
                    set<any, any>(updatedData, field, "delete")
                }
                setData(updatedData)
            },
            accept: options.accept,
            ...sharedOptions(field, { submitOnEnter: false }),
        }),
        [data, sharedOptions]
    )

    const propsForWMultipleFileInput: Form["propsForWMultipleFileInput"] = useCallback(
        (field) => {
            const parseResult = z.array(fileUploadSchema.partial()).safeParse(get(field))
            return {
                files: parseResult.success ? parseResult.data : undefined,
                onChange: (file: FileUpload | null, index: number) => {
                    const updatedData = cloneDeep(data ?? {}) as any
                    const currentFiles = sharedGet<any, any, FileUpload[] | undefined>(
                        updatedData,
                        field
                    )
                    const newFiles: (FileUpload | null)[] = []
                    for (
                        let i = 0, n = Math.max(currentFiles?.length ?? 0, index + 1);
                        i < n;
                        i++
                    ) {
                        if (i === index) {
                            if (file === null) continue
                            newFiles.push(file)
                        } else {
                            newFiles.push(currentFiles?.[i] ?? null)
                        }
                    }
                    set<any, any>(updatedData, field, newFiles)
                    setData(updatedData)
                },
                ...sharedOptions(field, { submitOnEnter: false }),
            }
        },
        [data, get, sharedOptions]
    )

    const addToList: Form["addToList"] = useCallback(
        (field, newEntry, ensureUnique = false) => {
            const updatedData = cloneDeep(data ?? {}) as any
            const value: unknown = get(field)
            if (Array.isArray(value)) {
                set<any, any>(
                    updatedData,
                    field,
                    [...value, newEntry].filter((v, i, a) => !ensureUnique || a.indexOf(v) === i)
                )
            } else {
                set<any, any>(updatedData, `${field}.[0]`, newEntry)
            }
            setData(updatedData)
        },
        [data, get]
    )

    const setFields: Form["setFields"] = useCallback(
        (entries) => {
            const updatedData = cloneDeep(data ?? {}) as any
            for (const [field, value] of entries) {
                set<any, any>(updatedData, field, value)
            }
            setData(updatedData)
        },
        [data]
    )

    const removeFromList: Form["removeFromList"] = useCallback(
        (field, filter) => {
            const updatedData = cloneDeep(data ?? {}) as any
            set<any, any>(
                updatedData,
                field,
                [...(get(field) ?? [])].filter((v, i) =>
                    "index" in filter ? i !== filter.index : v !== filter.value
                )
            )
            setData(updatedData)
        },
        [data, get]
    )

    const propsForButtonAddEntryToList: Form["propsForButtonAddEntryToList"] = useCallback(
        (field, newEntry, ensureUnique = false) => ({
            onClick: () => addToList(field, newEntry, ensureUnique),
        }),
        [addToList]
    )

    const propsForButtonAddFromList: Form["propsForButtonAddFromList"] = useCallback(
        (field, ensureUnique = false) => ({
            onAdd: (newEntry) => addToList(field, newEntry as any, ensureUnique),
            ...sharedOptions(field, { submitOnEnter: false }),
        }),
        [addToList, sharedOptions]
    )

    const propsForButtonRemoveEntryFromList: Form["propsForButtonRemoveEntryFromList"] =
        useCallback(
            (field, filter) => ({
                color: "red",
                "aria-label": t("core:delete"),
                variant: "icon",
                rightSection: <IconTrash />,
                onClick: () => removeFromList(field, filter),
            }),
            [removeFromList, t]
        )

    useDeepCompareEffect(() => {
        const dataToSet = beforeSetInitialData ? beforeSetInitialData(initialValues) : initialValues
        setData(dataToSet)
        setInitialData(dataToSet)
    }, [initialValues])

    const deleteField: Form["deleteField"] = useCallback(
        (field) => {
            const updatedData = cloneDeep(data ?? {}) as any
            unset<any, any>(updatedData, field)
            setData(updatedData)
        },
        [data]
    )

    return useMemo(
        () => ({
            data,
            deleteField,
            setFields,
            submit,
            reset,
            propsForWValueDisplay,
            propsForWDateInput,
            propsForWTextInput,
            propsForWColorPicker,
            propsForWNumberInput,
            propsForWMarkdownEditor,
            propsForWDineroInput,
            propsForWSelect,
            propsForWSelectMapped,
            propsForWMultiSelect,
            propsForWCheckbox,
            propsForWFileInput,
            propsForWMultipleFileInput,
            propsForWEmailsInput,
            propsForButtonAddEntryToList,
            propsForButtonAddFromList,
            propsForButtonRemoveEntryFromList,
            addToList,
            removeFromList,
        }),
        [
            addToList,
            data,
            deleteField,
            propsForButtonAddEntryToList,
            propsForButtonAddFromList,
            propsForButtonRemoveEntryFromList,
            propsForWCheckbox,
            propsForWColorPicker,
            propsForWDateInput,
            propsForWDineroInput,
            propsForWEmailsInput,
            propsForWFileInput,
            propsForWMarkdownEditor,
            propsForWMultiSelect,
            propsForWMultipleFileInput,
            propsForWNumberInput,
            propsForWSelect,
            propsForWSelectMapped,
            propsForWTextInput,
            propsForWValueDisplay,
            removeFromList,
            reset,
            setFields,
            submit,
        ]
    )
}

function isOmittable(v: any) {
    return (
        v === undefined ||
        v === null ||
        v === "" ||
        Number.isNaN(v) ||
        (typeof v === "object" && Object.keys(v).length === 0)
    )
}

export type WSelectMappedOption<E> = WSelectOption & { entry: E }

export class FormParseError {
    constructor(public message: LocalizedString) {}
    toString(): string {
        return `FormParseError: ${this.message.en}`
    }
}

type FieldOptions = {
    /** If true, the form will be submitted when the enter key is pressed. */
    submitOnEnter?: boolean
}

export type UseFormOptions<T> = {
    /** The initial value of the form. */
    initialValues?: DeepPartialPreservingBrand<T> | undefined
    /**
     * A function to apply before submitting the form. The result (if defined) will be
     * used as the current values of the merged data before checking the validity of the
     * merged data and calling `onSubmit.`
     *
     * @throws FormParseError - If the data is not valid.
     */
    beforeOnSubmit?: (
        value: DeepPartialPreservingBrand<T> | undefined
    ) => DeepPartialPreservingBrand<T> | undefined
    /**
     * A function to apply before setting the initial values.
     * @example - Multiply percentages by 100 to show `50%` instead of `0.5`.
     */
    beforeSetInitialData?: (
        value: DeepPartialPreservingBrand<T> | undefined
    ) => DeepPartialPreservingBrand<T> | undefined
    /** If true, no notifications will be shown when the form cannot be submitted.
     * @default false
     */
    silentFailSubmit?: boolean
    /** If true, the form will be cleared when it is submitted.
     * @default true
     */
    clearOnSubmit?: boolean
    /** If true, the form will be submitted when the enter key is pressed.
     * @default false
     */
    submitOnEnter?: boolean
}

export type UseFormReturn<S extends ZodTypeAny, T = z.infer<S>, UnbrandedS = InferUnbranded<S>> = {
    /** The current values of the form. This is a **READONLY** value. */
    data: DeepPartialPreservingBrand<T> | undefined

    /** Removes a given field from the edited data. */
    deleteField: (field: RecursiveKeyOf<T>) => void

    /** Sets the values of some fields to the given value. Does not change the values
     * of fields not specified.
     */
    setFields: (entries: DeepPartialPathValuePairs<T>) => void

    /**
     * Submits the form. Verifies the data is valid. If the data is not valid,
     * sets errors. Otherwise, calls the onSubmit function.
     */
    submit: () => void

    /** Resets the form. */
    reset: () => void

    /** Returns the props for a WValueDisplay. */
    propsForWValueDisplay: (field: RecursiveKeysOfType<UnbrandedS, string>) => {
        value: string
    }

    /** Returns the props for a WDateInput. */
    propsForWDateInput: (
        field: RecursiveKeysOfType<UnbrandedS, number>,
        options?: FieldOptions
    ) => SharedOptions & {
        value: number | undefined
        onChange: (newValue: number | undefined | null) => void
        maxDate: Date | undefined
        minDate: Date | undefined
    }

    /** Returns the props for a WTextInput. */
    propsForWTextInput: (
        field: RecursiveKeysOfType<UnbrandedS, string>,
        options?: FieldOptions
    ) => SharedOptions & {
        value: string
        onChange: (newValue: ChangeEvent<HTMLInputElement>) => void
        maxLength: number | undefined
        minLength: number | undefined
    }

    /** Returns the props for a WColorPicker. */
    propsForWColorPicker: (
        field: RecursiveKeysOfType<T, string>,
        options?: FieldOptions
    ) => SharedOptions & {
        value: string
        onChange: (value: string) => void
    }

    /** Returns the props for a WNumberInput. */
    propsForWNumberInput: (
        field: RecursiveKeysOfType<T, number>,
        options?: FieldOptions
    ) => SharedOptions & {
        value: number | undefined
        onChange: (newValue: number | string | undefined) => void
        min: number | undefined
        max: number | undefined
    }

    /** Returns the props for a WMarkdownEditor. */
    propsForWMarkdownEditor: (field: RecursiveKeysOfType<UnbrandedS, string>) => SharedOptions & {
        value: string
        setValue: (newValue: string | undefined) => void
    }

    /** Returns the props for a WDineroInput. */
    propsForWDineroInput: (
        field: RecursiveKeysOfType<UnbrandedS, DineroStorable>,
        options?: FieldOptions
    ) => SharedOptions & {
        value: DineroStorable | undefined
        setValue: (newValue: DineroStorable | undefined) => void
    }

    /** Returns the props for a WSelect. */
    propsForWSelect: (field: RecursiveKeysOfType<UnbrandedS, string>) => SharedOptions & {
        value: string
        onChange: (newValue: string | null) => void
    }

    /** Returns the props for a WSelect where the value we want to save is not a string. */
    propsForWSelectMapped: <E>(
        field: RecursiveKeysOfType<T, E>,
        /** The set of entries, where the `value` key is the value to be passed to the select object. */
        options: WSelectMappedOption<E>[],
        /**
         * Given an entry (what is to be saved), returns a value (the key of the entry
         * for the select.)
         */
        entryToValue: (options: WSelectMappedOption<E>[], entry: E) => string | null,
        /**
         * Given a value (the key of the entry for the select), returns an entry (what
         * is to be saved).
         */
        valueToEntry: (options: WSelectMappedOption<E>[], value: string | null) => E | undefined
    ) => SharedOptions & {
        value: string | null
        onChange: (newValue: string | null) => void
        options: WSelectMappedOption<E>[]
    }

    /** Returns the props for a WSelect. */
    propsForWMultiSelect: (field: RecursiveKeysOfType<UnbrandedS, string[]>) => SharedOptions & {
        value: string[] | undefined
        onChange: (newValue: string[] | null) => void
        // options: WSelectMappedOption<E>[]
    }

    /** Returns the props for a WCheckbox. */
    propsForWCheckbox: (field: RecursiveKeysOfType<UnbrandedS, boolean>) => SharedOptions & {
        checked: boolean
        onChange: (event: ChangeEvent<HTMLInputElement>) => void
    }

    /** Returns the props for a WFileInput. */
    propsForWFileInput: (
        field: RecursiveKeysOfType<
            T,
            (typeof options)["accept"] extends "application/pdf" ? FileUploadOnlyPDF : FileUpload
        >,
        options: {
            accept: WFileInputAccept
        }
    ) => SharedOptions & {
        accept: (typeof options)["accept"]
        onChange: (
            value:
                | ((typeof options)["accept"] extends "application/pdf" ? FileUploadOnlyPDF
                  :   FileUpload)
                | null
        ) => void
    }

    /** Returns the props for a WMultipleFileInput. */
    propsForWMultipleFileInput: (
        field: RecursiveKeysOfType<UnbrandedS, FileUpload[]>
    ) => SharedOptions & {
        files: DeepPartial<FileUpload>[] | undefined
        onChange: (file: FileUpload | null, index: number) => void
    }

    /** Returns the props for a WEmailsInput. */
    propsForWEmailsInput: (
        field: RecursiveKeysOfType<UnbrandedS, { address: string; name: string | undefined }[]>
    ) => SharedOptions & {
        value: { address: string; name: string | undefined }[] | undefined
        onChange: (newValue: { address: string; name: string | undefined }[] | null) => void
    }

    /** Returns the props for a button that will add a new entry to a list.
     *
     * @param field - The field of the list.
     * @param newEntry - The new entry to add to the list.
     * @param ensureUnique - Whether to ensure the entry is unique in the list. False by default.
     */
    propsForButtonAddEntryToList: <F extends RecursiveKeysOfType<T, any[]> & string>(
        field: F,
        newEntry: DeepPartialPreservingBrand<GetFieldType<T, `${F}[0]`>>,
        ensureUnique?: boolean
    ) => {
        onClick: () => void
    }

    /** Returns the props for ButtonAddFromList.
     *
     * @param field - The field of the list.
     * @param ensureUnique - Whether to ensure the entry is unique in the list. False by default.
     */
    propsForButtonAddFromList: <
        F extends RecursiveKeysOfType<T, any[]> & string,
        E extends DeepPartial<GetFieldType<T, `${F}[0]`>>,
    >(
        field: F,
        ensureUnique?: boolean
    ) => SharedOptions & { onAdd: (newEntry: E) => void }

    /** Returns the props for a button that will remove an entry at a given index from a list.
     *
     * @param field - The field of the list.
     * @param filter - The filter to find the item to remove. Either an index of the list or the value to remove.
     */
    propsForButtonRemoveEntryFromList: (
        field: RecursiveKeysOfType<T, any[]>,
        filter: { index: number } | { value: any }
    ) => {
        color: string
        "aria-label": string
        variant: "icon"
        rightSection: React.ReactNode
        onClick: () => void
    }

    /** Adds an entry to a list.
     *
     * @param field - The field of the list.
     * @param newEntry - The new entry to add to the list.
     * @param ensureUnique - Whether to ensure the entry is unique in the list. False by default.
     * @example - `addToList("list", "value")` adds the value "value" to the field "list".
     */
    addToList: <
        F extends RecursiveKeysOfType<T, any[]> & string,
        E extends DeepPartialPreservingBrand<GetFieldType<T, `${F}[0]`>>,
    >(
        field: F,
        newEntry: E,
        ensureUnique?: boolean
    ) => void

    /** Removes an entry from a list.
     *
     * @param field - The field of the list.
     * @param filter - The filter to find the item to remove. Either an index of the list or the value to remove.
     * @example - `removeFromList("list", { index: 0 })` removes the first item of the field "list".
     * @example - `removeFromList("list", { value: "value" })` removes the item with the value "value" of the field "list".
     */
    removeFromList: (
        field: RecursiveKeysOfType<T, any[]>,
        filter: { index: number } | { value: any }
    ) => void
}

type SharedOptions = {
    onKeyDown: ((e: KeyboardEvent<HTMLInputElement>) => void) | undefined
    error: string | undefined
    required: boolean
}

/** Returns the first error message from a set of `ZodIssue` for a given field. */
function findErrorMessage(
    errors: ZodIssue[],
    field: string | number | symbol,
    language: ReturnType<typeof useLanguage>
): string | undefined {
    const error = errors.find((error) => error.path.join(".").startsWith(field.toString()))?.message
    if (error && Object.values(ParsingErrors).includes(error as ParsingErrors))
        return parsingErrorLocale(error as ParsingErrors, language)
    return error
}

/** Converts a value to a string, unless if it is a null or undefined value. */
function toString(value: any): string {
    return value === undefined || value === null ? "" : String(value)
}
