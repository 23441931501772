import { z } from "zod"
import { dineroStorableSchema } from "./Common"
import { aidString, shortMarkdownString, uuidString, veryShortString } from "./BaseStrings"
import { addOpenAPI } from "./zodExtensions"

export const checkSchema = addOpenAPI(
    z.object({
        date: z.number(),
        amount: dineroStorableSchema,
        unitAID: aidString,
        uuid: uuidString,
        number: veryShortString,
        notes: shortMarkdownString.optional(),
    }),
    "Check"
)
export type Check = z.infer<typeof checkSchema>
