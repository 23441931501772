const core = {
    administrator: "Building Manager",
    file_will_be_deleted: "This file will be deleted.",
    request_email_templates: "Request Answers Templates",
    create_a_statement_for: "Create a statement for:",
    post_templates: "Post Templates",
    your_building_groups: "Your Building Groups",
    building_group: "Building Group",
    building_groups: "Building Groups",
    approval_note: "Approval Note",
    show_cancelled_transactions: "Show cancelled transactions",
    date_sent_to_otonom: "Otonom dispatch date",
    trial_balances: "Trial Balances",
    accountant_writings: "Accountant Writings",
    unit_payment: "Unit Payment",
    proxy: "Proxy",
    proxies: "Proxies",
    documentation: "Documentation",
    cancellation_reason: "Cancellation Reason",
    pre_authorized_debit: "Pre-authorized Debit",
    send_by_email: "Send by email",
    check_reversal_fee: "Check Reversal Fee",
    please_come_back_in_a_few_minutes: "Please come back in a few minutes.",
    app_under_maintenance: "AppNFlat is currently being updated.",
    new_reverse_check: "Check Returned",
    notify_owners_by_email: "Notify the co-owner by email",
    to_add_penalty_first_set_amount_in_penalties_tab:
        "Please first define the penalty for NSF checks.",
    apply_penalty: "Apply NSF Penalty",
    "new_reverse_wire-transfer": "Wire-transfer Returned",
    list: "Back to list",
    please_use_on_a_compouter_for_now:
        "Please use AppNFlat on a computer for now (if you are on a computer, please ensure the browser window is large enough).",
    coming_soon: "Coming soon",
    go_back_home: "Back to home page",
    mobile_not_yet_supported: "Mobile not yet supported",
    include_signatures: "Include Signatures",
    no_number_of_approvals_required_means_no_signatures_will_be_printed:
        "As no number of approval has been specified in the 'Administration' section, no signature will be printed.",
    printing_check: "Printing Check",
    signature_set: "Signature Set",
    number_of_approvals_required: "Number of approvals required",
    clear: "Clear",
    signature: "Signature",
    change_signature: "Change signature",
    archived_year: "Archived fiscal year",
    overpaid: "Overpayment",
    total_overpaid: "Total overpayment",
    error_loading_email: "An error occurred while loading the email.",
    attachments: "Attachments",
    add_file: "Add File",
    reply: "Reply",
    new_request_from_email: "New Request from this Email",
    assign_to_request: "Add Email to Existing Request",
    spam: "Spam",
    emails_unassigned_to_a_request: "Emails unassigned to a request",
    incoming_emails: "Incoming emails",
    inbox: "Inbox",
    trashed: "Trash",
    no_requests_to_assign_to: "No request to assign to",
    no_requests_to_merge_with: "No request to merge with",
    unit_renting: "Unit Renting Parking",
    all_units: "All Units",
    all_parkings: "All Parkings",
    all_lockers: "All Lockers",
    applies_to: "Applies to",
    no_monthly_fees: "No Monthly Fee",
    only_you_can_see_this: "Only you can see this, no one else in the building can.",
    low: "Low",
    medium: "Normal",
    high: "High",
    open: "Open",
    underway: "Ongoing",
    closed: "Closed",
    suggestions: "Suggestions",
    people: "People",
    new_request: "New Request",
    priority: "Priority",
    requestors: "Requestors",
    subject: "Subject",
    new_request_tag: "New Request Tag",
    request: "Request",
    merge: "Merge requests",
    request_emails: "Emails of the request",
    print_check: "Print Check",
    to_enable_ensure_payment_day_is_set:
        "To activate automatic payments, please enter the payment day information.",
    yourself: "Yourself",
    requests: "Requests",
    handler: "Handler",
    tags: "Tags",
    color: "Color",
    edit_tags: "Edit tags",
    request_tags: "Request tags",
    and_NUMBER_more: "+ $number more",
    "+": "+",
    "1_month": "1 Month",
    "1_week": "1 Week",
    "2_months": "2 Months",
    "2_weeks": "2 Weeks",
    "total_accounts-payable": "TOTAL Accounts Payable",
    "total_accounts-receivable": "TOTAL Accounts Receivable",
    access_billing: "AppNFlat Subscription",
    account_number: "Bank Account #",
    account_transfer: "Transfer Between Accounts",
    account: "Account",
    accounting_categories: "Accounts",
    accounting_id: "Accounting ID",
    accounting_number: "Account #",
    accounting: "Accounting",
    "accounts-payable": "Accounts Payable",
    accounts_receivable_and_payable: "Accounts Receivable & Payable",
    "accounts-receivable": "Accounts Receivable",
    active: "Active",
    actual_amount: "Actual Amount",
    add_cash: "+ Cash",
    add_check: "+ Check",
    add_default_bank_account: "Add a Default Bank Account",
    add_email: "+ Email",
    add_locker: "+ Locker",
    add_multiple_following_checks: "Add a check sequence",
    add_new: "+",
    add_parking: "+ Parking",
    add_recurring_bill: "+ Recurring Invoice",
    add_resident: "+ Tenant",
    add_user: "+ User",
    address: "Address",
    administration: "Administration",
    all_owners_and_residents_with_a_locker: "All owners and tenants with a locker",
    all_owners_and_residents_with_a_parking: "All owners and tenants with a parking",
    all_owners: "All owners",
    all_posts: "All Posts",
    all_residents: "All tenants",
    all_transactions: "All Transactions",
    all_users: "All Users",
    all: "All",
    amount_adjusts_when_selecting_invoices: "Amount adjusts when selecting invoices.",
    amount: "Amount",
    monthly_contributions: "Monthly Contributions",
    apply_new_vote_shares: "Apply New Fractions",
    AppNFlat: "AppNFlat",
    approval_status: "Approbation Status",
    approval: "Approvals",
    approvals: "Approvals",
    approve: "Approve",
    approved_and_paid_transactions: "Approved and Paid Transactions",
    approved_and_unpaid_transactions: "Approved and Unpaid Transactions",
    approved_by: "Approved By",
    approved_transactions: "Approved Transactions",
    approved: "Approved",
    archive_category: "Archive Account",
    archive_person: "Archive Person",
    archive: "Archive",
    archived: "Archived",
    as_administrator: "As a building manager",
    as_yourself: "As Yourself",
    assets: "Assets",
    author: "Author",
    automatic_communications: "Automated Emails",
    automatic_deposit: "Automatic Deposit",
    automatic_interest_rate: "Interest Rate for late payments",
    automatic_penalties: "Penalties Automatically Charged",
    back: "Back",
    balance_sheet: "Balance Sheet",
    balance: "Balance",
    balances_may_take_a_few_minutes_to_update_after_a_transaction_is_added_or_removed:
        "Balance may take a few minutes to update after a transaction is added or removed.",
    bank_account_name: "Bank Account Name",
    bank_account_number: "Account #",
    bank_account_type: "Bank Account Type",
    bank_account: "Bank Account",
    bank_accounts: "Bank Accounts",
    bank_fee: "Bank Fees",
    bank_institution_number: "Institution #",
    bank_reconciliation: "Reconciliation",
    bank_statement: "Bank Statement",
    bank_transit_number: "Transit #",
    bank: "Bank",
    banks: "Banks",
    banned_from_social: "Banned from posting",
    became_a_resident_on: "Tenant since",
    became_an_owner_on: "Owner since",
    beneficiary_bank_account: "Beneficiary Bank Account",
    bill_number: "Inv. #",
    billing_day: "Invoicing Day",
    body: "Body",
    book_entry: "Book Entry",
    budget_application_date: "Budget Application Date",
    budget_total_amount: "Total Budget",
    budget: "Budget",
    budgeted_amount: "Budgeted Amount",
    building_name: "Building Name",
    building: "Building",
    cadastre_number: "Cadastre Number",
    caller: "Payed by",
    canadian_dollar: "Canadian Dollar",
    cancel_transaction: "Cancel Transaction",
    cancel: "Cancel",
    cancelled: "Cancelled",
    capital_payed: "Capital Payment",
    capital: "Capital",
    cash_flow_statement: "Cash Flow Statement",
    cash_flow: "Cash Flow",
    cash: "Cash",
    category: "Account",
    cell: "Cell",
    change_file: "Change File",
    change_password: "Change Password",
    changing_password: "Changing password...",
    charges: "Charges",
    check_number: "Check #",
    check: "Check",
    checking_account: "Checking Account",
    checks_to_be_deposited: "Checks to be deposited",
    checks: "Checks",
    city: "City",
    close_unit_account: "Close Unit Account",
    close: "Close",
    closing_balance: "Closing Balance",
    co_owner: "Co-owner",
    co_owners: "Co-owners",
    communications: "Communications",
    condo_fee_invoicing_language: "Language for Condo Fees Invoicing",
    condo_fee_payment: "Condo Fee Payment",
    condo_fees_to_pay: "Condo Fees to Pay",
    condo_fees_to_receive: "Condo Fees to Receive",
    condo_fees: "Condo Fees",
    confirm_sending_to_otonom: "Confirm sending to Otonom Solution",
    contact_support: "Contact Support",
    contact: "Contact",
    continue: "Continue",
    contributions: "Budget Contributions",
    counterpart: "Counterpart",
    country: "Country",
    create_email_template: "New Template",
    create_statement: "Create Statement",
    credit_card: "Credit Card",
    credit_note: "Credit Note",
    credit: "Credit",
    creditor: "Creditor",
    csv: "CSV",
    currency: "Currency",
    dashboard: "Dashboard",
    data_is_encrypted_using_industry_standards_such_as_aes_256:
        "Data is encrypted using industry standards such as AES 256.",
    data_is_only_used_to_process_payments_with_otonom:
        "Data is only used to process payments via Otonom Solution.",
    date_range: "Date Range",
    date: "Date",
    debit_card: "Debit Card",
    debit: "Debit",
    debtor: "Debtor",
    default_bank_account_not_specified: "Default Bank Account Not Specified",
    delete_file: "Delete File",
    delete_locker: "Delete Locker",
    delete_parking: "Delete Parking",
    delete_this_user: "Delete this user?",
    delete_unit: "Delete Unit",
    delete_user: "Delete User",
    delete: "Delete",
    deleting_bank_account_info: "Deleting Bank Account Info...",
    deleting: "Deleting...",
    deposit_number: "Deposit #",
    deposit: "Deposit",
    deposited_amount: "Deposited Amount",
    deposits: "Deposits",
    description: "Description",
    difference: "Difference",
    do_you_wish_to_remove_locker: "Do you wish to delete the locker?",
    do_you_wish_to_remove_parking: "Do you wish to delete the parking?",
    done: "Done",
    download: "Download",
    due_to_suppliers: "Due to Suppliers",
    edit_budget: "Edit Budget",
    edit_email_template: "Edit Email Template",
    edit_owners: "Change Co-owner",
    edit_post: "Edit Post",
    edit_user: "User",
    edit: "Edit",
    email_insurance_about_to_expire_frequency: "Reminder frequency for insurance renewal",
    email_insurance_about_to_expire_start:
        "Timeline for notifying that insurance needs to be renewed",
    email_missing_data_insurance_frequency: "Reminder frequency for updating insurance data",
    email_missing_data_water_heater_frequency: "Reminder frequency for updating water heater data",
    email_templates: "Email Templates",
    email_topic: "Email Topic",
    email_water_heater_about_to_expire_frequency: "Reminder frequency for replacing water heater",
    email_water_heater_about_to_expire_start:
        "Timeline for notifying that a water heater needs to be replaced",
    email: "Email",
    emergency_contact: "Emergency Contact",
    empty_if_no_automatic_payment: "Empty if no automatic payment",
    empty_if_no_end: "Empty if no end",
    end_date: "End Date",
    end_of_warranty: "End of Warranty",
    ensure_difference_is_zero: "Please ensure difference is zero.",
    enter_a_valid_email: "Enter a valid email.",

    error: "Error",
    essential_information_missing: "Essential Information Missing",
    euro: "Euro",
    everyone_in_the_building: "All co-owners and tenants",
    excel: "Excel",
    expense_account: "Expense Account",
    expense_category: "Expense Account",
    expense: "Expense",
    expenses: "Expenses",
    export: "Export",
    fax: "Fax",
    financial_statement_section: "Financial Statement Section",
    financial_statements_parameters: "Financial Statements Parameters",
    financial_statements: "Financial Statements",
    financials: "Financials",
    first_name: "First Name",
    fiscal_year: "Fiscal Year",
    floor_NUMBER: "Floor $number",
    floor: "Floor",
    floors: "Floors",
    fraction: "Fraction",
    from: "From",
    gender: "Gender",
    generate_otonom_csv_for_units: "Collect Units' Payments via Otonom Solution",
    generate_otonom_csv: "Send payment to Otonom Solution",
    generates_a_csv_file_for_otonom_to_collect_unit_payments:
        "Sends collection request to Otonom Solution in order to receive payments from units paying via automatic wire transfer.",
    generating_otonom_csv: "Generating Otonom Solution CSV...",
    grace_period: "Grace Period",
    history: "History",
    home: "Home",
    if_no_budget_units_will_not_be_automatically_billed:
        "Without a budget, units will not be charged automatically every month.",
    if_no_default_bank_account_units_will_not_automatically_pay:
        "Without a default bank account automatic payments by units cannot be activated.",
    include: "Include:",
    income_statement: "Income Statement",
    income: "Income",
    incomplete: "Incomplete",
    informations: "Information",
    insert_title: "Insert Title",
    insert_unit_link: "Insert Unit Link",
    insert_unit_number: "Insert Unit Number",
    installation_date: "Installation Date",
    insurance_about_to_expire_reminder: "Reminder for insurance about to expire",
    insurance_about_to_expire: "Insurance is about to expire",
    insurance_certificate: "Certificate",
    insurance_incomplete_reminder: "Reminder for incomplete information on insurance",
    insurance_info_incomplete: "Insurance information is incomplete",
    insurance_provider: "Provider",
    insurance: "Insurance",
    insurance_end_date: "End Date",
    interest_rate: "Interest Rate",
    interests_payed: "Interest Payment",
    interests_received: "Interest Deposit",
    inv: "Inv.",
    invalid_value: "Invalid value",
    investment_account: "Investment Account",
    invite_bulk_users: "Invite bulk users",
    invited_as_ROLE: "Invited as $role",
    invited_users: "Invited Users",
    invited: "Invited",
    inviting_users: "Inviting users...",
    invoice: "Invoice",
    invoiced: "Invoiced",
    invoices: "Invoices",
    invoicing_threshold: "Invoicing Threshold: ",
    journal_of_transactions: "Journal of Transactions",
    keep_balance_with_old_owner: "Keep balance with old owner",
    keyboard_shortcuts: "Keyboard Shortcuts",
    kind: "Type",
    last_name: "Last Name",
    late_payment_penalty: "Late Payment Penalty",
    legal: "Legal",
    level: "Level",
    liabilities: "Liabilities",
    liability_insurance_coverage: "Liability Coverage",
    list_of_bank_accounts: "List of Bank Accounts",
    list_of_checks: "List of Checks",
    list_of_co_owners_and_residents: "List of Co-owners & Tenants",
    list_of_lockers: "List of Lockers",
    list_of_parkings: "List of Parkings",
    list_of_suppliers: "List of Suppliers",
    list_of_units: "List of Units",
    loading_and_decrypting_bank_account_info: "Loading and decrypting bank account information...",
    loading_report: "Loading Report...",
    loading: "Loading...",
    locker_k: "Locker $number",
    locker_number: "Locker #",
    locker: "Locker",
    lockers: "Lockers",
    low_balance_threshold: "Balance alert threshold",
    man: "Male",
    missing_budget: "Condo Fees Budget Missing",
    month: "Month",
    monthly_fee: "Monthly Fee",
    monthly_fees: "Monthly Fees",
    monthly: "Once a Month",
    multiple: "Multiple",
    my_posts: "My Posts",
    NAME_operating_account: "$name (operating account)",
    name: "Name",
    never: "Never",
    new_bank: "New Bank",
    new_categories: "New Accounts",
    new_charge: "New Charge",
    new_checks: "New Checks",
    new_deposit: "New Deposit",
    new_locker: "New Locker",
    new_parking: "New Parking",
    new_penalty: "New Penalty",
    new_person: "New Person",
    new_post: "New Post",
    new_supplier: "New Supplier",
    new_suppliers: "New Suppliers",
    new_transaction: "Transaction",
    new_unit: "New Unit",
    no_data: "No Data",
    no_one: "No One",
    no_time_zone_specified_please_contact_support: "No time zone. Please contact support.",
    no: "No",
    none: "None",
    notes: "Notes",
    number_of_checks: "Number of checks",
    number_of_months_where_unit_has_unpaid_balance:
        "Number of months since unit has an unpaid condo fees",
    number: "Number",
    once_budget_applied_cannot_be_changed: "Once applied, budget cannot be changed",
    other: "Other",
    otonom_id: "Otonom Solution ID",
    otonom_integration: "Otonom Activation",
    otonom_solution: "Otonom Solution",
    otonom: "Otonom Solution",
    overview: "Overview",
    owned_units: "Owned Units",
    owners_and_residents: "Owners & Tenants",
    owners_will_be_notified_by_email: "Owners will be notified by email.",
    owners: "Owners",
    page_not_found: "Page not Found",
    paid: "Paid",
    parking_k: "Parking $number",
    parking: "Parking",
    parkings: "Parkings",
    password: "Password",
    payment_day: "Payment Day",
    payment_method: "Payment Method",
    payment_status: "Payment Status",
    payment: "Payment",
    payments: "Payments",
    pdf: "PDF",
    penalties_and_fees: "Charges & Penalties",
    penalties: "Penalties",
    penalty_amount: "Penalty Amount",
    penalty: "Penalty",
    pending_approval: "Pending Approval",
    pending_transactions: "Pending Transactions",
    pending: "Pending",
    person: "Person",
    phone_number: "Phone #",
    phone_type: "Phone Type",
    phone: "Phone",
    phones: "Phones",
    please_contact_support_as_soon_as_possible_to_get_help:
        "The application will not function, please contact our support.",
    please_fill_the_form: "Please complete the form",
    please_set_language_to_be_able_to_generate_reports:
        "Please set language in order to generate reports.",
    policy_number: "Policy #",
    posted_on: "Posted on",
    preferred_language: "Preferred language",
    previously_generated_otonom_files: "Payments sent to Otonom Solution during the last 30 days",
    privacy_policy: "Privacy Policy",
    profile: "Profile",
    profit_and_loss: "Balance sheet",
    ready_to_send_to_otonom: "Ready to be sent to Otonom Solution",
    rec: "Rec.",
    recipients: "Recipients",
    reconciled: "Reconciled",
    recurring_bill: "Recurring Invoice",
    recurring_bills: "Recurring Invoices",
    recurring_end_date: "End Date",
    register_check: "Register Check",
    reject: "Reject",
    rejected_by: "Rejected By",
    rejected_transactions: "Transactions whose payment has been rejected",
    rejected: "Rejected",
    remaining_balance_processing: "Remaining Balance Processing",
    removed_amount: "Removed Amount",
    rented: "Rented",
    person_renting: "Rented by",
    report_language_not_specified: "language for condo fees invoicing not specified",
    reports: "Reports",
    nudge_approval: "Send a reminder",
    required_field_missing: "A required field is missing",
    resident: "Tenant",
    residential_units: "Residential Units",
    residents: "Tenants",
    revenue_account: "Revenue Account",
    revenues: "Revenues",
    role: "Type",
    save: "Save",
    saving_account: "Saving Account",
    saving: "Saving...",
    search: "Search",
    sections_to_include: "Sections to include",
    send_email_on_check_save: "Confirm Received Checks Via Email:",
    send_email_to_recipients: "Send via email",
    send_email_to_units_with_unpaid_balance: "Send reminder email to units with unpaid condo fees",
    send_to_otonom_for_NAME: "Pay $name",
    send_to_otonom: "Send to Otonom Solution",
    send_unit_payments_to_otonom:
        "Click on the below button, to send to Otonom Solution the payment of all your units paying via automatic wire transfer.",
    sending_to_otonom: "Sending to Otonom Solution...",
    sent_to_otonom: "Sent to Otonom Solution",
    set_language: "Set Language",
    settings: "General Settings",
    shortcuts: "Shortcuts",
    show_help: "Display Help",
    signing_out: "Signing Out...",
    start_date: "Start Date",
    start_new_fiscal_year: "Start New Fiscal Year",
    starting_balance: "Starting balance",
    state: "State",
    status: "Status",
    sterling_pound: "Sterling Pound",
    street: "Street",
    success: "Operation successfully completed",
    supplier_name: "Supplier Name",
    supplier_payment: "Suppliers payment",
    supplier_statement: "Supplier Statement",
    supplier_transactions_ready_to_send_to_otonom:
        "Supplier payments to be sent to Otonom Solution",
    supplier: "Supplier",
    suppliers: "Suppliers",
    threshold_for_automatic_billing: "Threshold for Automatic Billing",
    time_zone: "Time Zone",
    title: "Title",
    to_add_or_remove_units_and_parkings_please_contact_support:
        "To add or remove units or parkings, please contact our support.",
    to_enroll_unit_in_automatic_payment_enter_bank_account_details:
        "To activate the automatic wire transfer, please enter the bank account information of the payer.",
    to: "To",
    toggle_navigation: "Menu",
    total_assets: "TOTAL Assets",
    total_capital_and_liabilities: "TOTAL Capital & Liabilities",
    total_capital: "TOTAL Capital",
    total_expenses: "TOTAL Expenses",
    total_k: "Total: $total",
    total_liabilities: "TOTAL Liabilities",
    total_revenues: "TOTAL Revenues",
    total: "Total",
    transaction: "Transaction",
    transactions_export: "Transactions Export",
    transactions: "Transactions",
    transfer_balance_to_new_owner: "Transfer balance to new owner",
    transfer_to_new_owner_or_keep_on_old_owner:
        "Do you want to transfer the balance to the new owner?",
    transfers: "Transfers",
    unit_k: "Unit $number",
    unit_NUMBER_sold: "Unit $number (sold)",
    unit_number: "Unit #",
    unit_statement: "Unit Statement",
    unit_transactions_ready_to_send_to_otonom: "Unit payments to be sent to Otonom Solution",
    unit: "Unit",
    united_states_dollar: "American Dollar",
    units_collection: "Collection from co-owners",
    units_paying_by_automatic_wire_transfers: "Units Paying by Wire Transfer",
    units_with_unpaid_balance: "Units with unpaid condo fees",
    units_without_insurance: "Units Without Insurance",
    units: "Units",
    unknown_error: "Unknown Error",
    unknown: "Unknown",
    unpaid_balance_after_k_weeks: "Unpaid Condo Fees for $k Weeks",
    unpaid_balance_reminder: "Reminder for unpaid condo fees",
    update_bank_account: "Update Bank Account",
    update: "Update",
    upload_file: "Upload File",
    upload: "Upload",
    uploading: "Uploading...",
    users: "Users",
    vehicle_license_plate: "License Plate",
    vote_share_total_is_not_100: "The sum of voting rights is not equal to 100%.",
    vote_share: "Vote Share",
    vote_shares: "Vote Shares",
    waiting_approval: "Waiting for Approval",
    water_heater_about_to_expire_reminder: "Reminder for water heater warranty about to expire",
    water_heater_about_to_expire: "Water heater warranty about to expire",
    water_heater_incomplete_reminder: "Reminder for incomplete information on water heater",
    water_heater_info_incomplete: "Water heater information incomplete",
    water_heater_label: "Label",
    water_heater: "Water Heater",
    water_heaters_to_be_replaced: "Water Heaters to be Replaced",
    weekly: "Once a Week",
    wire_transfer: "Wire Transfer",
    wire_transfers: "Wire Transfers",
    woman: "Female",
    work: "Work",
    yearly: "Once a Year",
    yes: "Yes",
    your_buildings: "Your Buildings",
    zip_code: "Zip Code",
    file_format: "File Format",
} as const

export default core
