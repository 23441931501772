import z from "zod"
import { objectWithAttachmentsSchema } from "../Common"
import { longMarkdownString, mediumString } from "../BaseStrings"
import { MAX_ATTACHMENTS_REQUEST_EMAIL } from "../RequestEmail"
import { addOpenAPI } from "../zodExtensions"
import { ID_PREFIXES, idSchemaGenerator } from "../Id"

/** The schema for a request email template id. */
export const requestEmailTemplateIdSchema = idSchemaGenerator(ID_PREFIXES.REQUEST_EMAIL_TEMPLATE)

/** The schema for a request email template.
 *
 * Injectable values:
 * - `$buildingName`: The name of the building.
 * - `$buildingAddress`: The address of the building.
 * - `$buildingPhone`: The phone number of the building.
 * - `$buildingEmail`: The email of the building.
 * - `$buildingLink`: A link to the building's
 */
export const requestEmailTemplateSchema = addOpenAPI(
    objectWithAttachmentsSchema(MAX_ATTACHMENTS_REQUEST_EMAIL).extend({
        /** The id of the request email template. */
        id: requestEmailTemplateIdSchema,
        /** The name of the request email template. */
        name: mediumString,
        /** The subject of the request email template. */
        subject: mediumString.optional(),
        /** The body of the request email template. */
        body: longMarkdownString.optional(),
    }),
    "RequestEmailTemplate"
)
export type RequestEmailTemplate = z.infer<typeof requestEmailTemplateSchema>
