import { useCallback, useSyncExternalStore } from "react"
import { Language, Locale } from "@appnflat-types/Language"
import { LocalizedString } from "@appnflat-types/types"

/** Returns the current locale of the app.
 *
 * Note: in most cases, {@link useLanguage} should be used instead.
 */
export function useLocale(): Locale {
    return useSyncExternalStore(
        (cb) => {
            window.addEventListener("languagechange", cb)
            return () => window.removeEventListener("languagechange", cb)
        },
        () => {
            // For tests, use english locale.
            if (process.env.IS_TEST === "true") return Locale.English_CA
            const match = Object.values(Locale).find((l) => navigator.language.startsWith(l))
            return match ?? Locale.English_CA
        }
    )
}

/** Returns the current language of the app. */
export function useLanguage(): Language {
    return useSyncExternalStore(
        (cb) => {
            window.addEventListener("languagechange", cb)
            return () => window.removeEventListener("languagechange", cb)
        },
        () => {
            // For tests, use english locale.
            if (process.env.IS_TEST === "true") return Language.English
            const match = Object.values(Language).find((l) => navigator.language.startsWith(l))
            return match ?? Language.English
        }
    )
}

export function getLanguage(): Language {
    // For tests, use english locale.
    if (process.env.IS_TEST === "true") return Language.English
    const match = Object.values(Language).find((l) => navigator.language.startsWith(l))
    return match ?? Language.English
}

type T$Opts = Record<`$${string}`, string | undefined>

function internalTranslate(language: Language, tKey: LocalizedString, opts?: T$Opts) {
    if (!tKey) return ""
    let translation = tKey[language]
    const options = Object.entries(opts ?? {})
    for (let i = 0, n = options.length; i < n; i++) {
        const [option, value] = options[i] ?? []
        if (!option || !value) continue
        translation = translation.replace(new RegExp(`\\${option}`, "g"), value)
    }
    return translation
}

export function translate(tKey: LocalizedString, opts?: T$Opts) {
    const language = navigator.language.startsWith("fr") ? Language.French : Language.English
    return internalTranslate(language, tKey, opts)
}

export function useTranslate() {
    const language = useLanguage()
    return useCallback(
        (tKey: LocalizedString, opts?: T$Opts) => internalTranslate(language, tKey, opts),
        [language]
    )
}
