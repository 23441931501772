import React, { ForwardedRef, ReactNode, useEffect, useMemo } from "react"
import { useLocation, useNavigate } from "react-router"
import { MantineColor, Menu, Tooltip, UnstyledButton, Text, Indicator } from "@mantine/core"
import { DateTime } from "@shared/dates"
import {
    useAppParams,
    usePermissions,
    useAppTranslation,
    useIsMobile,
    useAppFormatter,
} from "hooks/hooks"
import { useLanguage } from "hooks/useTranslate"
import { IconAppnflatSmall } from "assets/svgs/IconAppnflatSmall"
import { Role } from "@constants/Role"
import {
    IconArchive,
    IconBuildingBank,
    IconCalendar,
    IconChartBar,
    IconCoin,
    IconDoor,
    IconHeadset,
    IconHelp,
    IconHome,
    IconLayoutDashboard,
    IconMessage,
    IconSettings,
    IconTool,
    IconUserCog,
    IconUsers,
} from "@tabler/icons-react"
import * as classes from "./NavBarApp.module.css"
import { useBuilding, useBuildingGroup, useUserProperty } from "hooks/useStore"
import { where } from "firebase/firestore"
import { useFirestoreCount } from "hooks/useFirestoreCount"
import { AppURL, useAppURL } from "hooks/useAppURL"

const additionalFilters = [where("read", "==", false)]
type BuildingOrNothing = ["building", string, number] | []

export default function NavBarApp({
    navbarOpened,
    closeNavbar,
}: {
    navbarOpened: boolean
    closeNavbar: () => void
}) {
    const t = useAppTranslation()
    const navigate = useNavigate()
    const formatter = useAppFormatter()
    const location = useLocation()
    const { buildingRef, fiscalYear } = useAppParams()
    const isMobile = useIsMobile()
    const language = useLanguage()
    const buildingGroup = useBuildingGroup()
    const buildingGroupId = buildingGroup?.id
    const buildingGroupLogo = buildingGroup?.logoUrl

    const userName = useUserProperty("name")
    const building = useBuilding()
    const fiscalYears = building?.fiscalYears
    const unarchivedFYs = building?.unarchivedFiscalYears
    const buildingName = building?.name

    const {
        hasSocialAccessRead,
        hasFinancialAccessRead,
        hasOwnerSelfFinancialAccessRead,
        hasOwnerAllFinancialAccessRead,
        canAccessUnitTransactions,
        isAdmin,
        userRole,
    } = usePermissions()
    const numberUnreadRequestEmails = useFirestoreCount({
        coll: "requestEmails",
        additionalFilters,
        permissionKey: "requests.emails",
    })

    const showSocial = !!buildingRef && hasSocialAccessRead
    const showFiscalYearPicker =
        !!buildingRef && (hasOwnerSelfFinancialAccessRead || hasFinancialAccessRead)
    const showAllFinancial = !!buildingRef && !!fiscalYear && hasFinancialAccessRead
    const showSomeFinancial =
        !!buildingRef && !!fiscalYear && (hasOwnerAllFinancialAccessRead || hasFinancialAccessRead)
    const showRequests =
        !buildingRef || isAdmin || userRole === Role.owner || userRole === Role.handler
    const showAdmin = !!buildingRef && isAdmin
    const showUnits =
        !!buildingRef && !!fiscalYear && (hasFinancialAccessRead || userRole === Role.owner)
    const inBuildingGroup = !!buildingGroupId && !buildingRef

    useEffect(closeNavbar, [location, closeNavbar])

    const fiscalYearsOptions = useMemo(() => {
        function setFiscalYearFromDropdown(year: number) {
            if (buildingRef) navigate(`/building/${buildingRef}/${year}`)
        }
        return [...(fiscalYears ?? [])]
            .sort((a, b) => b - a)
            .map((year) => (
                <Menu.Item onClick={() => setFiscalYearFromDropdown(year)} key={year}>
                    {!unarchivedFYs?.includes(year) && <IconArchive />}
                    {fiscalYear === year && <b>{formatter.dateToLocale(new DateTime(+year))}</b>}
                    {fiscalYear !== year && formatter.dateToLocale(new DateTime(+year))}
                </Menu.Item>
            ))
    }, [fiscalYears, buildingRef, navigate, unarchivedFYs, fiscalYear, formatter])

    return (
        <div className={classes.navbar} data-expanded={navbarOpened || undefined}>
            {!isMobile && (
                <div className={classes["title-bar"]}>
                    <NavButton
                        isAppLogo
                        label={buildingName ?? t("core:dashboard")}
                        path={["dashboard"]}
                        customChild={
                            buildingGroupLogo ?
                                <img
                                    src={buildingGroupLogo}
                                    alt="Building Group Logo"
                                    width={50}
                                    height={50}
                                    style={{ borderRadius: "10px" }}
                                />
                            :   <IconAppnflatSmall
                                    width="50px"
                                    height="50px"
                                    isIcon={false}
                                    style={{ borderRadius: "10px" }}
                                />
                        }
                        show
                        closeNavbar={closeNavbar}
                    />
                </div>
            )}

            {buildingRef && fiscalYear && (
                <>
                    <NavButton
                        path={["building", buildingRef, fiscalYear, "dashboard"]}
                        referencePath={["building", buildingRef, fiscalYear, "dashboard"]}
                        label={buildingName ?? t("core:dashboard")}
                        Icon={IconLayoutDashboard}
                        show={!!buildingRef}
                        closeNavbar={closeNavbar}
                    />
                    <NavButton
                        path={[
                            "building",
                            buildingRef,
                            fiscalYear,
                            "units",
                            canAccessUnitTransactions ? "transactions" : "info",
                            "",
                        ]}
                        referencePath={["building", buildingRef, fiscalYear, "units"]}
                        label={t("core:units")}
                        Icon={IconDoor}
                        show={showUnits}
                        closeNavbar={closeNavbar}
                    />
                    <NavButton
                        path={["building", buildingRef, fiscalYear, "people", "info", ""]}
                        referencePath={["building", buildingRef, fiscalYear, "people"]}
                        label={t("core:owners_and_residents")}
                        Icon={IconUsers}
                        show={!!buildingRef}
                        closeNavbar={closeNavbar}
                    />
                    <NavButton
                        path={[
                            "building",
                            buildingRef,
                            fiscalYear,
                            "suppliers",
                            "transactions",
                            "",
                        ]}
                        referencePath={["building", buildingRef, fiscalYear, "suppliers"]}
                        label={t("core:suppliers")}
                        Icon={IconTool}
                        show={showSomeFinancial}
                        closeNavbar={closeNavbar}
                    />
                    <NavButton
                        path={["building", buildingRef, fiscalYear, "payments", "checks"]}
                        referencePath={["building", buildingRef, fiscalYear, "payments"]}
                        label={t("core:payments")}
                        Icon={IconCoin}
                        show={showAllFinancial}
                        closeNavbar={closeNavbar}
                    />
                    <NavButton
                        path={["building", buildingRef, fiscalYear, "banks", "transactions", ""]}
                        referencePath={["building", buildingRef, fiscalYear, "banks"]}
                        label={t("core:banks")}
                        Icon={IconBuildingBank}
                        show={showSomeFinancial}
                        closeNavbar={closeNavbar}
                    />
                    <NavButton
                        path={[
                            "building",
                            buildingRef,
                            fiscalYear,
                            "categories",
                            "transactions",
                            "",
                        ]}
                        referencePath={["building", buildingRef, fiscalYear, "categories"]}
                        label={t("core:accounting")}
                        Icon={IconChartBar}
                        show={showSomeFinancial}
                        closeNavbar={closeNavbar}
                    />
                    <NavButton
                        path={["building", buildingRef, fiscalYear, "posts", "all"]}
                        referencePath={["building", buildingRef, fiscalYear, "posts"]}
                        label={t("core:communications")}
                        Icon={IconMessage}
                        show={showSocial}
                        closeNavbar={closeNavbar}
                    />
                    <NavButton
                        path={["building", buildingRef, fiscalYear, "requests", "all"]}
                        referencePath={["building", buildingRef, fiscalYear, "requests"]}
                        label={t("core:requests")}
                        Icon={IconHeadset}
                        show={showRequests && !!buildingRef}
                        badge={numberUnreadRequestEmails}
                        closeNavbar={closeNavbar}
                    />
                </>
            )}

            {buildingGroupId && !buildingRef && (
                <>
                    <NavButton
                        path={["building-group", buildingGroupId, "posts", "templates"]}
                        referencePath={["building-group", buildingGroupId, "posts", "templates"]}
                        label={t("core:communications")}
                        Icon={IconMessage}
                        show={inBuildingGroup}
                        closeNavbar={closeNavbar}
                    />
                    <NavButton
                        path={["building-group", buildingGroupId, "requests", "all"]}
                        referencePath={["building-group", buildingGroupId, "requests"]}
                        label={t("core:requests")}
                        Icon={IconHeadset}
                        show={inBuildingGroup}
                        // badge={numberUnreadRequestEmails}
                        closeNavbar={closeNavbar}
                    />
                </>
            )}

            <NavButton
                path={
                    buildingGroupId && [
                        ...((buildingRef && fiscalYear ?
                            ["building", buildingRef, fiscalYear]
                        :   []) satisfies BuildingOrNothing),
                        "building-group",
                        buildingGroupId,
                        "admin",
                        "building-group",
                    ]
                }
                referencePath={
                    buildingGroupId && [
                        ...((buildingRef && fiscalYear ?
                            ["building", buildingRef, fiscalYear]
                        :   []) satisfies BuildingOrNothing),
                        "building-group",
                        buildingGroupId,
                        "admin",
                    ]
                }
                label={t("core:administration")}
                Icon={IconSettings}
                show={inBuildingGroup}
                closeNavbar={closeNavbar}
            />

            {(showSocial || showSomeFinancial || showUnits) && <hr className="menu" />}

            {buildingRef && fiscalYear && (
                <NavButton
                    path={["building", buildingRef, fiscalYear, "admin", "building"]}
                    referencePath={["building", buildingRef, fiscalYear, "admin"]}
                    label={t("core:administration")}
                    Icon={IconSettings}
                    show={showAdmin}
                    closeNavbar={closeNavbar}
                />
            )}
            <NavButton
                path={
                    buildingRef && fiscalYear ? ["building", buildingRef, fiscalYear, "account"]
                    : buildingGroupId ?
                        ["building-group", buildingGroupId, "account"]
                    :   ["account"]
                }
                referencePath={
                    buildingRef && fiscalYear ? ["building", buildingRef, fiscalYear, "account"]
                    : buildingGroupId ?
                        ["building-group", buildingGroupId, "account"]
                    :   ["account"]
                }
                label={userName ?? t("core:account")}
                Icon={IconUserCog}
                show
                closeNavbar={closeNavbar}
            />
            <NavButton
                onClick={() => window.open(`https://appnflat.com/${language}/help`, "_blank")}
                label={t("core:documentation")}
                Icon={IconHelp}
                show
                closeNavbar={closeNavbar}
            />

            {showFiscalYearPicker && <hr className="menu" />}
            <Menu>
                <Menu.Target>
                    <NavButton
                        closeNavbar={closeNavbar}
                        label={t("core:fiscal_year")}
                        Icon={IconCalendar}
                        show={showFiscalYearPicker}
                    />
                </Menu.Target>
                <Menu.Dropdown>{fiscalYearsOptions}</Menu.Dropdown>
            </Menu>
            <NavButton
                closeNavbar={closeNavbar}
                label={t("messages:this_fiscal_year_is_archived_and_cannot_be_edited")}
                Icon={IconArchive}
                color="gray"
                show={showFiscalYearPicker && !!fiscalYear && !unarchivedFYs?.includes(fiscalYear)}
            />
        </div>
    )
}

type NavButtonProps = {
    label: string
    color?: MantineColor
    show: boolean
    /** Whether this is the app logo. */
    isAppLogo?: boolean
    badge?: string | number
    ref?: ForwardedRef<HTMLDivElement>
    /** The path to navigate to when the button is clicked. */
    path?: AppURL | boolean
    referencePath?: Partial<AppURL>
    /** If provided, we will ignore the path and use this click handler instead. */
    onClick?: () => void
    closeNavbar: () => void
} & (
    | {
          Icon: typeof IconHome
          customChild?: undefined
      }
    | {
          Icon?: undefined
          customChild: ReactNode
      }
)

function NavButton({
    Icon,
    customChild,
    label,
    onClick,
    color,
    show,
    isAppLogo,
    badge,
    ref,
    path,
    referencePath,
    closeNavbar,
}: NavButtonProps) {
    const url = useAppURL(typeof path === "object" ? path : undefined)
    const navigate = useNavigate()
    const location = useLocation()
    const isActive = useMemo(() => {
        if (!referencePath) return false
        return location.pathname.startsWith("/" + referencePath.join("/"))
    }, [location.pathname, referencePath])

    if (!show) return undefined
    const _onClick = () => {
        closeNavbar()
        if (onClick) onClick()
        else if (url) navigate(url)
    }
    return (
        <Tooltip label={label} position="right" transitionProps={{ duration: 0 }} ref={ref}>
            <Indicator disabled={!badge} color="red" offset={10} label={badge} size={16}>
                <UnstyledButton
                    onClick={_onClick}
                    className={classes.link}
                    data-is-logo={isAppLogo || undefined}
                    data-active={isActive || undefined}
                    data-no-onclick={(!path && !onClick) || undefined}
                >
                    {Icon ?
                        <>
                            <Icon style={{ width: 22, height: 22 }} stroke={1} color={color} />
                            <Text className={classes.label}>{label}</Text>
                        </>
                    :   customChild}
                </UnstyledButton>
            </Indicator>
        </Tooltip>
    )
}
