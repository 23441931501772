import { z } from "zod"
import { longMarkdownString, mediumString, uidString } from "./BaseStrings"
import { addOpenAPI } from "./zodExtensions"
import { requestTagIdSchema } from "./BuildingGroup/RequestTag"
import { buildingGroupIdSchema, personUUIDSchema, requestUUIDSchema, unitUUIDSchema } from "./Id"

/** The status of the request. */
export const requestStatusSchema = z.enum(["open", "closed", "underway"])
/** The status of the request. */
export type RequestStatus = z.infer<typeof requestStatusSchema>

/** The priority of the request. */
export const requestPrioritySchema = z.enum(["high", "medium", "low"])
/** The priority of the request. */
export type RequestPriority = z.infer<typeof requestPrioritySchema>

/** A request.
 *
 * Corresponds to the document `db/buildings/{buildingRef}/requests/{uuid}`.
 */
export const requestSchema = addOpenAPI(
    z.object({
        uuid: requestUUIDSchema,
        /** The uuids of the people who initiated the request. */
        requestors: z.array(personUUIDSchema).optional(),
        /** Units associated to this request. */
        unitUUIDs: z.array(unitUUIDSchema).optional(),
        /** The title of the request. */
        title: mediumString,
        /** The date at which the request was created. */
        date: z.number(),
        /** The date at which the request was closed. */
        closeDate: z.number().optional(),
        /** The tags of the request. Should be a list of ids of `RequestTag`. */
        tags: z.array(requestTagIdSchema).optional(),
        /** The status of the request. This is managed by the user. */
        status: requestStatusSchema,
        /** The `uid` of the person responsible for the request. */
        handler: uidString.optional(),
        /** How urgent this request is. */
        priority: requestPrioritySchema,
        /** Notes about the request. */
        notes: longMarkdownString.optional(),
        /** The `id` of the building group associated to the building this request is in.
         *
         * Note: this is defined to be able to show all requests of a building group in the same view.
         */
        buildingGroupId: buildingGroupIdSchema.optional(),
    }),
    "Request"
)
/**
 * A request.
 * Corresponds to the document `db/buildings/{buildingRef}/requests/{uuid}`.
 */
export type Request = z.infer<typeof requestSchema>
