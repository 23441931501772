import React, { useCallback, useEffect, useState } from "react"
import { Group, Modal } from "@mantine/core"
import { Button } from "@mantine/core"
import { DateTime } from "@shared/dates"
import { useAppTranslation, usePermissions, useAppParams, useAppFormatter } from "hooks/hooks"
import { useBuilding } from "hooks/useStore"
import { useNavigate } from "react-router"
import { z } from "zod"
import { useForm } from "hooks/useForm"
import { WDateInput } from "components/Inputs/WDateInput"
import { setLoadingState } from "logic/notifications"
import { DateFormat } from "@shared/formatter"
import { POST, requestHandler } from "server/wrappedCallers"
import { sharedConfig } from "@shared/sharedConfig"

/** Checks if the last fiscal year was created over `MAX_WEEKS_IN_FY` weeks ago and
 * if so, forces the user to create a new fiscal year.
 */
export default function PromptForceNewFY() {
    const t = useAppTranslation()
    const { buildingRef } = useAppParams()
    const building = useBuilding()
    const navigate = useNavigate()
    const { MAX_WEEKS_IN_FY } = sharedConfig
    const { hasFinancialAccessWrite } = usePermissions()
    const [startDateLocale, setStartDateLocale] = useState("")
    const [showPopup, setShowPopup] = useState(false)
    const mostRecentFY = Math.max(...(building?.fiscalYears.length ? building.fiscalYears : [0]))

    const formNewFYSchema = z.object({
        newYear: z.number().min(mostRecentFY).max(new DateTime("now").toSeconds()),
    })

    const onSubmit = useCallback(
        (data: z.infer<typeof formNewFYSchema>) => {
            if (!buildingRef) return
            setShowPopup(false)
            setLoadingState({
                tkey: "messages:creating_new_fiscal_year_please_wait_this_can_take_a_few_minutes",
                icon: "fiscal-year",
            })
            requestHandler({
                errorTitle: "error_creating_a_new_fiscal_year",
                request: POST("/buildings/new-fiscal-year", {
                    params: { query: { buildingRef } },
                    body: data,
                }),
                onFinally: () => setLoadingState(false),
                onResult: () => {
                    navigate("/app")
                },
                onError: () => {
                    setShowPopup(true)
                },
            })
        },
        [buildingRef, navigate]
    )

    const form = useForm(formNewFYSchema, onSubmit)
    const formatter = useAppFormatter()

    useEffect(() => {
        // If the user is not an admin or an accountant with write permission, do not show the prompt.
        if (!hasFinancialAccessWrite || !mostRecentFY) {
            setShowPopup(false)
        } else {
            const startDate = new DateTime(mostRecentFY)
            setStartDateLocale(formatter.dateToLocale(startDate, DateFormat.date))
            setShowPopup(new DateTime("now") >= startDate.plus({ weeks: MAX_WEEKS_IN_FY }))
        }
    }, [formatter, mostRecentFY, hasFinancialAccessWrite, MAX_WEEKS_IN_FY])

    return (
        <Modal
            opened={showPopup}
            onClose={() => void 0}
            title={t("messages:you_must_create_a_new_fiscal_year")}
            withCloseButton={false}
        >
            {t(T_STARTED_LONG_AGO, {
                $startDate: startDateLocale,
                $weeks: String(MAX_WEEKS_IN_FY),
            })}
            <WDateInput
                mt="20"
                editing
                label="core:start_date"
                {...form.propsForWDateInput("newYear")}
            />
            <Group justify="flex-end">
                <Button onClick={form.submit}>{t("core:start_new_fiscal_year")}</Button>
            </Group>
        </Modal>
    )
}

const T_STARTED_LONG_AGO = {
    en: "The current fiscal year started on the $startDate, which is more than $weeks weeks ago. Please select a date in the past to start a new fiscal year. Once this is done, you will not be able to further edit the previous fiscal year, but will still be able to view it. If you have any questions, please contact us at help@appnflat.com.",
    fr: "L'année fiscale a commencé le $startDate, soit il y a plus de $weeks semaines. Veuillez choisir une date dans le passé pour commencer une nouvelle année fiscale. Vous ne pourrez plus modifier l'année fiscale précédente, mais pourrez toujours la consulter. Si vous avez des question, veuillez nous écrire at help@appnflat.com.",
}
