import { storage } from "firebaseSetup"
import { ref, getDownloadURL } from "firebase/storage"
import { useState } from "react"
import { CoreFilePathParams, FilePathParams, pathForFile } from "@shared/pathForFile"
import { useAppParams } from "./hooks"
import { useDeepCompareEffect } from "./useDeepCompareEffect"
import { BuildingRef } from "@appnflat-types/BaseStrings"

function injectBuildingRefIntoParams(
    params: CoreFilePathParams,
    buildingRef: BuildingRef
): FilePathParams {
    if (params.type === "signature" || params.type === "unknownEmail") return params
    return { ...params, buildingRef }
}

/** Returns a file URL for a reference in Firebase storage. */
export function useFileURL(
    params: CoreFilePathParams | string | undefined | true,
    uniqueCheckID?: number | string
) {
    const [result, setResult] = useState<string | undefined>(undefined)
    const { buildingRef } = useAppParams()

    useDeepCompareEffect(() => {
        if (!params || params === true) setResult(undefined)
        else if (typeof params === "string") setResult(params)
        else if (!buildingRef) setResult(undefined)
        else {
            const path = pathForFile(injectBuildingRefIntoParams(params, buildingRef)).path
            getDownloadURL(ref(storage, path))
                .then((url) => setResult(url))
                .catch(() => setResult(undefined))
        }
    }, [params, buildingRef, uniqueCheckID])

    return result
}

async function getURLs(
    params: ({ params: CoreFilePathParams; fileName: string } | true)[],
    buildingRef: BuildingRef
) {
    return (
        await Promise.all(
            params
                .filter((p): p is Exclude<typeof p, true> => p !== true)
                .map(async ({ params, fileName }) => {
                    const path = pathForFile(injectBuildingRefIntoParams(params, buildingRef)).path
                    const url = await getDownloadURL(ref(storage, path)).catch(() => undefined)
                    if (!url) return undefined
                    return { url, fileName }
                })
        )
    ).filter((u): u is Exclude<typeof u, undefined> => u !== undefined)
}

/** Returns a list of file URLs for a reference in Firebase storage. */
export function useFileURLs(
    params: ({ params: CoreFilePathParams; fileName: string } | true)[] | true,
    uniqueCheckID?: number | string
) {
    const [results, setResults] = useState<{ url: string; fileName: string }[] | undefined>(
        undefined
    )
    const { buildingRef } = useAppParams()

    useDeepCompareEffect(() => {
        if (params === true || !buildingRef) {
            setResults(undefined)
            return
        }
        getURLs(params, buildingRef)
            .then((urls) => setResults(urls))
            .catch(() => setResults(undefined))
    }, [params, buildingRef, uniqueCheckID])

    return results
}
