import z from "zod"
import { ID_PREFIXES, idSchemaGenerator } from "./Id"
import { addOpenAPI, zodRegex } from "./zodExtensions"

export const billingAccountIdSchema = idSchemaGenerator(ID_PREFIXES.BILLING_ACCOUNT)
export const stripeSubscriptionIdSchema = zodRegex<`sub_${string}`>(/^sub_.+$/)
export const stripeCustomerIdSchema = zodRegex<`cus_${string}`>(/^cus_.+$/)

const coreBillingAccountSchema = z.object({
    /** The id of the billing account. */
    id: billingAccountIdSchema,
})

export const billingAccountSchema = addOpenAPI(
    z.union([
        coreBillingAccountSchema.extend({
            paymentMethod: z.literal("manual"),
            /** The date until which the billing account has paid. */
            paidUntil: z.number(),
        }),
        coreBillingAccountSchema.extend({
            paymentMethod: z.literal("test-account"),
        }),
        coreBillingAccountSchema.extend({
            paymentMethod: z.literal("stripe"),
            /** The stripe customer id. */
            stripeCustomerId: stripeCustomerIdSchema,
            /** A list of stripe subscription ids. */
            stripeSubscriptionIds: z.array(stripeSubscriptionIdSchema),
            /** The date until which the subscription is paid.
             *
             * The keys are the stripe subscription ids.
             */
            paidUntil: z.record(z.number()),
        }),
    ]),
    "BillingAccount"
)
export type BillingAccount = z.infer<typeof billingAccountSchema>
