import { z } from "zod"
import { Role } from "../@constants/Role"
import { uidString } from "./BaseStrings"
import { addOpenAPI } from "./zodExtensions"
/** The user details within a building. */
export const buildingUserSchema = addOpenAPI(
    z.object({
        uid: uidString,
        role: z.nativeEnum(Role),
        banned: z.boolean().optional(),
    }),
    "BuildingUser"
)
export type BuildingUser = z.infer<typeof buildingUserSchema>
