import { useEffect, useRef } from "react"

const isProd = process.env.NODE_ENV === "production"

export default isProd ? null : (
    {
        usePrevious,
        useTraceUpdate,
        useChangesOnEveryRender,
        useCountRenders,
    }
)

/** Returns the value as it was in the previous render. */
function usePrevious<T>(value: T): T | undefined {
    const ref = useRef<T | undefined>(undefined)
    useEffect(() => {
        ref.current = value
    })
    return ref.current
}

/** Debug hook to display how many times a view is rendered. */
function useCountRenders() {
    const renderCounter = useRef(0)
    renderCounter.current = renderCounter.current + 1
    return renderCounter.current
}

/** Debug hook to display what props have changed between two renders. */
function useTraceUpdate(props: object) {
    const prev = useRef(props)
    useEffect(() => {
        const changedProps = Object.entries(props).reduce((ps, [k, v]) => {
            if (prev.current[k as any as keyof typeof prev.current] !== v) {
                // @ts-ignore
                ps[k] = [prev.current[k], v]
            }
            return ps
        }, {})
        if (Object.keys(changedProps).length > 0) {
            console.debug("Changed props:", changedProps)
        }
        prev.current = props
    })
}

/** Debug hook to display what props have changed on every render. */
function useChangesOnEveryRender(props: object) {
    const numRenders = useCountRenders()
    const changedInARow = useRef(0)
    const prev = useRef(props)
    useEffect(() => {
        const changedProps = Object.entries(props).reduce((ps, [k, v]) => {
            if (prev.current[k as any as keyof typeof prev.current] !== v) {
                // @ts-ignore
                ps[k] = [prev.current[k], v]
            }
            return ps
        }, {})
        if (Object.keys(changedProps).length > 0) {
            changedInARow.current = changedInARow.current + 1
            if (changedInARow.current === numRenders && numRenders > 3) {
                console.log("Props changed on every render:", changedProps)
            }
        } else {
            changedInARow.current = 0
        }
        prev.current = props
    })
}
