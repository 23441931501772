import { createSlice } from "@reduxjs/toolkit"
import type { RootState } from "./store"
import { PathsGET, ResponseForPath } from "@appnflat-types/BackendOpenAPITypes"
import { objectEntries } from "@shared/objects"
import { FilterByPropertyType } from "@appnflat-types/helpers"

export type PathWithQuery<P extends PathsGET = PathsGET> = `${P}?${string}`

type Response200<Path extends PathsGET> =
    Path extends PathsGET ?
        FilterByPropertyType<ResponseForPath<`GET ${Path}`>, "code", 200> extends (
            { response: infer R }
        ) ?
            R extends { "application/json": infer B } ? B
            : R extends { "text/plain": infer C } ? C
            : never
        :   never
    :   never

export type QueryCacheEntry<Path extends PathsGET = PathsGET> = {
    /** The cached response. */
    response: Response200<Path>
    /** The timestamp (in seconds) at which this cache entry was lastUsed. */
    lastUsed: number
    /** The timestamp (in seconds) at which this cache entry was created. */
    lastModified: number
}
export type QueryCache = {
    [FullPath in `${PathsGET}?${string}`]: QueryCacheEntry
}

const initialState: QueryCache = {}

export function queryCacheSelector<P extends PathsGET>(path: PathWithQuery<P>) {
    return function _queryCacheForPath(state: RootState): Response200<P> | undefined {
        return state.queryCache[path]?.response as Response200<P> | undefined
    }
}

type QueryCacheEntryToAdd<
    P extends PathsGET,
    PQ extends PathWithQuery<P> = PathWithQuery<P>,
> = QueryCacheEntry<P> & {
    path: PQ
}

/** The maximum number of cached queries to save. */
const MAX_NUMBER_CACHED_QUERIES = 100

/** Limits the maximum number of entries in the cache, removing the oldest ones when the limit is reached. */
function _removeExpiredQueryCache(state: QueryCache) {
    const entries = objectEntries(state)
    if (entries.length <= MAX_NUMBER_CACHED_QUERIES) return
    const sortedEntries = entries.sort((a, b) => a[1].lastUsed - b[1].lastUsed)
    const entriesToRemove = sortedEntries.slice(0, entries.length - MAX_NUMBER_CACHED_QUERIES)
    for (const [path] of entriesToRemove) {
        delete state[path]
    }
}

export const queryCacheSlice = createSlice({
    name: "queryCache",
    initialState,
    reducers: {
        clearQueryCache: () => initialState,

        removeExpiredQueryCache: _removeExpiredQueryCache,

        setQueryCacheEntry: function _setQueryCacheEntry(
            state: QueryCache,
            action: { payload: QueryCacheEntryToAdd<PathsGET> }
        ) {
            console.log("Updating cache entry: ", action.payload)
            state[action.payload.path] = {
                response: action.payload.response,
                lastUsed: action.payload.lastUsed,
                lastModified: action.payload.lastModified,
            }
            _removeExpiredQueryCache(state)
        },
    },
})

export const { clearQueryCache, setQueryCacheEntry, removeExpiredQueryCache } =
    queryCacheSlice.actions
