/** The maximum number of emails a person can have. */
export const PERSON_MAX_NUMBER_OF_EMAILS = 10

/** The maximum number of emails a supplier can have. */
export const SUPPLIER_MAX_NUMBER_OF_EMAILS = 10

/** The id of the AppNFlat staff user. */
export const APPNFLAT_STAFF_ID = "appnflat-staff"

/** The maximum length of an email address. */
export const EMAIL_MAX_LENGTH = 100

/** The maximum length of a phone number. */
export const PHONE_MAX_LENGTH = 30
