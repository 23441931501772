import { z } from "zod"
import { fiscalYearSchema, phonesSchema } from "./Common"
import { addressSchema } from "./Address"
import { emailString, phoneString, mediumString, shortMarkdownString } from "./BaseStrings"
import { localeSchema } from "./Language"
import { addOpenAPI } from "./zodExtensions"
import { personUUIDSchema } from "./Id"
import { ParsingErrors } from "./parsingErrors"
import { PERSON_MAX_NUMBER_OF_EMAILS } from "./Constants"

export const genderSchema = z.enum(["M", "F", "other"])

export const personSchema = addOpenAPI(
    z.object({
        uuid: personUUIDSchema,
        fiscalYear: fiscalYearSchema,
        archived: z.boolean().optional(),
        /** The date of the last edit. */
        lastModified: z.number().optional(),
        emails: z
            .array(emailString)
            .max(PERSON_MAX_NUMBER_OF_EMAILS, { message: ParsingErrors.too_many_emails })
            .optional(),
        phones: phonesSchema.optional(),
        address: addressSchema.optional(),
        notes: shortMarkdownString.optional(),
        firstName: mediumString.optional(),
        lastName: mediumString.optional(),
        gender: genderSchema.optional(),
        emergencyContact: z
            .object({
                name: mediumString.optional(),
                phone: phoneString.optional(),
                email: emailString.optional(),
            })
            .optional(),
        /** The preferred locale of the person. */
        locale: localeSchema.optional(),
    }),
    "Person"
)
export type Person = z.infer<typeof personSchema>
