import React, { useCallback } from "react"
import { Button, Container, Title, Paper, PasswordInput } from "@mantine/core"
import { auth } from "../../firebaseSetup"
import { signInWithEmailAndPassword } from "firebase/auth"
import { useLocation, useNavigate } from "react-router"
import { useAppTranslation } from "hooks/hooks"
import { useForm } from "hooks/useForm"
import { z } from "zod"
import { WTextInput } from "components/Inputs/WTextInput"
import * as classes from "./SignIn.module.css"
import { setLoadingState, showErrorNotification } from "logic/notifications"

const signInFormSchema = z.object({
    email: z.string().email(),
    password: z.string(),
})

export function Component() {
    const t = useAppTranslation()
    const navigate = useNavigate()
    const path = useLocation()

    const signIn = useCallback(
        async (formResult: z.infer<typeof signInFormSchema>) => {
            setLoadingState({ tkey: T_SIGNING_IN })
            try {
                const localUser = await signInWithEmailAndPassword(
                    auth,
                    formResult.email,
                    formResult.password
                )
                setLoadingState(false)
                if (localUser && ["/", "/signin"].includes(path.pathname))
                    navigate("/dashboard", { replace: true })
            } catch (error) {
                console.error("Error signing in", error)
                setLoadingState(false)
                showErrorNotification({ title: T_ERROR }, error)
            }
        },
        [navigate, path.pathname]
    )
    const form = useForm(signInFormSchema, signIn, {
        silentFailSubmit: true,
        clearOnSubmit: false,
        submitOnEnter: true,
    })

    return (
        <Container size={420} my={40}>
            <Title ta="center" className={classes.title}>
                {t(T_WELCOME_BACK)}
            </Title>

            <Paper withBorder shadow="md" p={30} mt={30} radius="md">
                <WTextInput
                    variant="default"
                    label="core:email"
                    data-test-id="email"
                    editing
                    {...form.propsForWTextInput("email")}
                />
                <PasswordInput
                    label={t("core:password")}
                    placeholder={t("core:password")}
                    data-test-id="password"
                    mt="md"
                    {...form.propsForWTextInput("password")}
                />
                <Button fullWidth data-test-id="submit" mt="md" onClick={form.submit}>
                    {t(T_SIGN_IN)}
                </Button>
                <Button
                    variant="transparent"
                    fullWidth
                    component="button"
                    size="xs"
                    onClick={() => navigate("/reset-password")}
                    color="gray"
                >
                    {t(T_FORGOT_PASSWORD)}
                </Button>
            </Paper>
        </Container>
    )
}
Component.displayName = "SignIn"

const T_SIGNING_IN = {
    en: "Signing in...",
    fr: "Connexion...",
}
const T_ERROR = {
    en: "An error occurred while signing in.",
    fr: "Une erreur est survenue lors de la connexion.",
}
const T_SIGN_IN = {
    en: "Sign in",
    fr: "Se connecter",
}

const T_WELCOME_BACK = {
    en: "Welcome back",
    fr: "Bienvenue",
}

const T_FORGOT_PASSWORD = {
    en: "Forgot password",
    fr: "Mot de passe oublié",
}
