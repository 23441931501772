import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { Cache, initialState } from "./cacheHelpers"
import { CollectionAndObjectList } from "@appnflat-types/Collection"
import set from "lodash/set"
import cloneDeep from "lodash/cloneDeep"
import { IdForCollectionInput, idForCollection } from "@shared/idForCollection"
import { BuildingUser } from "@appnflat-types/BuildingUser"
import { Building } from "@appnflat-types/Building"
import { BuildingGroupUser } from "@appnflat-types/BuildingGroup/BuildingGroupUser"
import { BuildingGroup } from "@appnflat-types/BuildingGroup/BuildingGroup"
export {
    cachedBuildingsSelector,
    transactionsSelector,
    cachedObjectSelector,
    cachedBuildingSelector,
    cachedCollectionSelector,
    transactionPartiesSelector,
    cachedUserInBuildingSelector,
    userDocsInBuildingsSelector,
    userDocsInBuildingGroupsSelector,
} from "./cacheSelectors"

export const cacheSlice = createSlice({
    name: "cache",
    initialState,
    reducers: {
        /** Empties the cache. */
        emptyCache: () => initialState,

        /** Removes all data from the cache related to a building. */
        emptyCacheOfBuildingData(state: Cache): Cache {
            return {
                buildings: Object.fromEntries(
                    Object.entries(state.buildings).map(([buildingRef, content]) => [
                        buildingRef,
                        { ...content, collections: {} },
                    ])
                ),
                buildingGroups: cloneDeep(state.buildingGroups),
            }
        },

        setUserDocInBuildings(
            state,
            action: PayloadAction<{ buildingRef: string; user: BuildingUser }[]>
        ) {
            const userAndBuildings = Object.values(action.payload)
            for (let i = 0, n = userAndBuildings.length; i < n; i++) {
                const userAndBuilding = userAndBuildings[i]
                if (!userAndBuilding) continue
                set(state, ["buildings", userAndBuilding.buildingRef, "user"], userAndBuilding.user)
            }
        },

        setUserDocInBuildingGroups(
            state,
            action: PayloadAction<{ buildingGroupId: string; user: BuildingGroupUser }[]>
        ) {
            const userAndBuildingGroups = Object.values(action.payload)
            for (let i = 0, n = userAndBuildingGroups.length; i < n; i++) {
                const userAndBuildingGroup = userAndBuildingGroups[i]
                if (!userAndBuildingGroup) continue
                set(
                    state,
                    ["buildingGroups", userAndBuildingGroup.buildingGroupId, "user"],
                    userAndBuildingGroup.user
                )
            }
        },

        /** Sets the building details for a building. */
        setBuildingFromServer(
            state: Cache,
            action: PayloadAction<{
                /** The building itself. */
                building: Building
                /** The doc `buildings/${buildingRef}/users/${userUID}`. */
                user: BuildingUser
            }>
        ) {
            set(
                state,
                ["buildings", action.payload.building.buildingRef, "building", "original"],
                action.payload.building
            )
            set(
                state,
                ["buildings", action.payload.building.buildingRef, "building", "user"],
                action.payload.user
            )
        },

        /** Sets the building group details for a building group. */
        setBuildingGroupFromServer(
            state: Cache,
            action: PayloadAction<{
                buildingGroup: BuildingGroup
                user: BuildingGroupUser
            }>
        ) {
            set(
                state,
                ["buildingGroups", action.payload.buildingGroup.id, "buildingGroup", "original"],
                action.payload.buildingGroup
            )
            set(
                state,
                ["buildingGroups", action.payload.buildingGroup.id, "user"],
                action.payload.user
            )
        },

        /** Sets the `original` field for an object. */
        setObjectFromServer(
            state: Cache,
            action: PayloadAction<
                IdForCollectionInput & { root: "buildings" | "buildingGroups"; rootId: string }
            >
        ) {
            const id = idForCollection(action.payload)
            set(
                state,
                [
                    action.payload.root,
                    action.payload.rootId,
                    "collections",
                    action.payload.collection,
                    id,
                    "original",
                ],
                action.payload.value
            )
        },

        /** Sets a collection. Will just update the `original` field in the entries. */
        setCollectionFromServer(
            state: Cache,
            action: PayloadAction<
                CollectionAndObjectList & {
                    /** The id of the function that is setting the collection.
                     *
                     * If not provided, we will replace all values for the given collection.
                     * If provided, we will only update the values that have been set by the
                     * function with the provided id.
                     */
                    setterId?: string
                    /** The root of the collection. */
                    root: "buildings" | "buildingGroups"
                    /** The id of the root. */
                    rootId: string
                }
            >
        ) {
            if (
                process.env.NODE_ENV !== "production" &&
                (!action.payload.root || !action.payload.rootId)
            ) {
                console.warn(
                    "Setting collection in setCollectionFromServer without root or rootId is invalid",
                    action.payload
                )
            }
            const collection = action.payload.collection
            // If no setterId is provided, we will replace all values for the given collection.
            if (!action.payload.setterId) {
                set(
                    state,
                    [action.payload.root, action.payload.rootId, "collections", collection],
                    {}
                )
            } else {
                const existingValues = Object.entries(state[collection as keyof Cache] ?? {})
                // Filter the values that have not been set by the provided setterId
                // and keep only those.
                const valuesToSet = {}
                for (let i = 0, n = existingValues.length; i < n; i++) {
                    const existingValue = existingValues[i]
                    if (!existingValue) continue
                    const [id, value] = existingValue
                    if (!id || !value || value.setterId === action.payload.setterId) continue
                    // @ts-expect-error
                    valuesToSet[id] = value
                }
                set(
                    state,
                    [action.payload.root, action.payload.rootId, "collections", collection],
                    valuesToSet
                )
            }
            for (let i = 0, n = action.payload.values.length; i < n; i++) {
                const value = action.payload.values[i]
                if (!value) continue
                const id = idForCollection({ collection, value } as any)
                if (id)
                    set(
                        state,
                        [
                            action.payload.root,
                            action.payload.rootId,
                            "collections",
                            collection,
                            id,
                            "original",
                        ],
                        value
                    )
            }
        },
    },
})

export const {
    emptyCache,
    setObjectFromServer,
    setUserDocInBuildings,
    setUserDocInBuildingGroups,
    setBuildingFromServer,
    setBuildingGroupFromServer,
    setCollectionFromServer,
    emptyCacheOfBuildingData,
} = cacheSlice.actions
