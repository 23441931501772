import { LocalizedString } from "@appnflat-types/types"

const translations = {
    fr: {
        problem_edit_transaction: "Une erreur est survenue lors de l'édition de la transaction.",
        error_archiving_email: "Une erreur est survenue en archivant le courriel.",
        error_assigning_email_to_request:
            "Une erreur est survenue en assignant le courriel à la requête.",
        error_merging_requests: "Erreur lors de la fusion des requêtes",
        problem_printing_check: "Erreur lors de l'impression du chèque",
        now: "Il y a quelques instants",
        error: "Erreur",
        success: "Succès",
        unknown_error_occurred: "Une erreur inconnue est survenue",
        multiple: "Multiple",
        otonom_paid: "Otonom - Envoyé",
        otonom_cancelled: "Otonom - Annulé",
        otonom_readyToSendToOtonom: "Otonom - Prêt à envoyer",
        otonom_waitingApproval: "Otonom - Attente d'approbation",
        otonom_error: "Otonom - Erreur",
        check: "Chèque",
        "credit-card": "Carte de crédit",
        "debit-card": "Carte de débit",
        otonom: "Otonom Solution",
        "wire-transfer": "Virement",
        cash: "Cash",
        floor_NUMBER: "Étage $number",
        auth_issue_try_logging_out_and_logging_back_in_or_make_sure_you_have_adequate_permissions:
            "Problème d'autorisation. Veuillez vous déconnecter et vous reconnecter ou assurez-vous d'avoir les autorisations nécessaires auprès de votre administrateur.",
        vote_share_total_is_not_100: "La somme des droits de vote n'est pas égale à 100%.",
        institution_number_should_be_3_digits: "Le numéro d'institution devrait avoir 3 chiffres",
        transit_number_should_be_5_digits: "Le numéro de transit devrait avoir 5 chiffres",
        account_number_should_be_5_to_20_digits:
            "Le numéro de compte devrait avoir ente 5 et 20 chiffres",
        invalid_email_please_try_again: "Courriel invalide, Veuillez ré-essayer.",
        no_corresponding_user_with_this_email_found:
            "Aucun utilisateur ne correspond à ce courriel.",
        invalid_password_please_try_again: "Mot de passe invalide, veuillez ré-essayer.",
        the_entered_password_is_not_correct:
            "Le mot de passe que vous avez entré n'est pas correct.",
        too_many_requests_were_recently_made_for_this_account_please_wait_a_bit_before_trying_again:
            "Trop de requêtes ont été effectuées, merci d'attendre un peu avant de ré-essayer.",
        account_disabled: "Le compte a été désactivé",
        please_confirm_your_email_before_logging_in:
            "Veuillez confirmer votre email avant de vous connecter",
        not_connected_to_internet: "Vous n'êtes pas connecté à l'Internet",
        problem_generating_report: "Un problème est survenu lors de la génération du rapport.",
        problem_adding_users: "Une erreur s'est produite lors de l'ajout de l'utilisateur.",
        error_saving_transaction:
            "Une erreur s'est produite lors de la sauvegarde de la transaction",
        problem_deleting_doc: "Un problème est survenu lors de la suppression du document.",
        problem_updating_user_role:
            "Une erreur s'est produite lors de la mise à jour des permission utilisateur.",
        problem_removing_user: "Une erreur s'est produite lors de la suppression de l'utilisateur.",
        problem_changing_vote_shares:
            "Suite à un problème, les droits de vote n'ont pas été modifiés.",
        error_loading_bank_account_info:
            "Une erreur s'est produite lors du téléchargement des informations bancaires.",
        problem_sending_email_reminder:
            "Une erreur s'est produite lors de l'envoie du courriel de relance.",
        error_changing_owners: "Un problème est survenu lors du changement de propriétaire.",
        error_creating_a_new_fiscal_year: "Erreur lors de la création d'une nouvelle année fiscale",
        problem_sending_unit_statement:
            "Une erreur s'est produite lors de l'envoi de l'état de compte.",
        problem_updating_bank_account_details:
            "Une erreur s'est produite lors de la mise à jour des informations relatives au compte en banque.",
        error_connecting_to_billing_portal:
            "Un problème est survenu en se connectant au portail de payment.",
        error_sending_to_otonom:
            "Une erreur s'est produite lors de l'envoi du fichier à Otonom Solution.",
        problem_generating_otonom_csv:
            "Une erreur s'est produite lors de la génération du des données pour Otonom Solution.",
        problem_close_unit_account: "Impossible de clôturer ce compte.",
        unknown_error_while_setting_approval: "Erreur inconnue lors de l'approbation.",
        problem_create_transactions:
            "Une erreur s'est produite lors de la création de la transaction.",
        error_deleting_bank_account_info:
            "Une erreur est survenue lors de la suppression des coordonnées bancaires.",
        problem_creating_transaction: "Problème lors de la création de la transaction",
        problem_cancelling_transaction:
            "Un problème est survenu lors de l'annulation de la transaction.",
        problem_create_emailTemplates:
            "Une erreur s'est produite de la création du modèle de courriel.",
        problem_archive_emailTemplates:
            "Une erreur s'est produite lors de l'archivage du modèle de courriel.",
        problem_edit_emailTemplates:
            "Une erreur s'est produite lors de la modification du modèle de courriel.",
        problem_archive_checks: "Une erreur s'est produite lors de l'archivage du chèque.",
        problem_create_penalties: "Une erreur s'est produite lors de la création de la pénalité.",
        problem_create_posts: "Une erreur s'est produite lors de la création du message.",
        problem_create_budgets: "Une erreur s'est produite lors de la création du budget.",
        problem_archive_parkings: "Une erreur s'est produite lors de l'archivage du parking.",
        problem_archive_lockers: "Une erreur s'est produite lors de l'archivage du casier.",
        problem_archive_units: "Une erreur s'est produite lors de l'archivage de l'unité.",
        problem_archive_banks: "Une erreur s'est produite lors de l'archivage du compte en banque.",
        problem_archive_categories: "Une erreur s'est produite lors de l'archivage du compte.",
        problem_archive_people: "Une erreur s'est produite lors de l'archivage de la personne.",
        problem_archive_suppliers: "Une erreur s'est produite lors de l'archivage du fournisseur.",
        problem_create_suppliers: "Une erreur s'est produite lors de la création du fournisseur.",
        problem_create_units: "Une erreur s'est produite lors de la création de l'unité.",
        problem_create_parkings: "Une erreur s'est produite lors de la création du parking.",
        problem_create_lockers: "Une erreur s'est produite lors de la création du casier.",
        problem_create_requests: "Une erreur s'est produite lors de la création de la requête.",
        problem_create_requestEmails:
            "Une erreur s'est produite lors de la création de l'email de la requête.",
        problem_edit_checks: "Une erreur s'est produite lors de la modification du chèque.",
        problem_edit_penalties: "Une erreur s'est produite lors de la modification de la pénalité.",
        problem_edit_buildings: "Une erreur s'est produite lors de la modification de l'immeuble.",
        problem_edit_posts: "Une erreur s'est produite lors de la modification du message.",
        problem_edit_budgets: "Une erreur s'est produite lors de la modification du budget.",
        problem_edit_suppliers: "Une erreur s'est produite lors de la modification du fournisseur.",
        problem_edit_people: "Une erreur s'est produite lors de la modification de la personne.",
        problem_edit_banks:
            "Une erreur s'est produite lors de la modification du compte en banque.",
        problem_edit_parkings: "Une erreur s'est produite lors de la modification du parking.",
        problem_edit_lockers: "Une erreur s'est produite lors de la modification du casier.",
        problem_edit_categories: "Une erreur s'est produite lors de la modification du compte.",
        problem_edit_requests: "Une erreur s'est produite lors de la modification de la requête.",
        problem_edit_requestTags:
            "Une erreur s'est produite lors de la modification du type de la requête.",
        problem_delete_posts: "Une erreur s'est produite lors de la suppression du message.",
        problem_delete_penalties:
            "Une erreur s'est produite lors de la suppression de la pénalité.",
        problem_delete_emailTemplates:
            "Une erreur s'est produite lors de la suppression du modèle d'email.",
        problem_delete_requests: "Une erreur s'est produite lors de la suppression de la requête.",
        problem_delete_requestTags:
            "Une erreur s'est produite lors de la suppression du type de la requête.",
        problem_archive_penalties: "Une erreur s'est produite lors de l'archivage des pénalités.",
        problem_deleting_emailTemplates:
            "Une erreur s'est produite lors de la suppression du modèle de courriel.",
        problem_loading_email: "Une erreur s'est produite lors du chargement du courriel.",
        error_reconciling_transaction:
            "Une erreur s'est produite lors de la conciliation de la transaction.",
        "pre-authorized-debit": "Débit préautorisé",
    },

    en: {
        problem_edit_transaction: "An error occurred while editing the transaction.",
        "pre-authorized-debit": "Pre-authorized Debit",
        error_archiving_email: "An error occurred while archiving the email.",
        error_assigning_email_to_request:
            "An error occurred while assigning the email to the request.",
        error_merging_requests: "Error merging requests.",
        problem_printing_check: "An error occurred while printing the check",
        now: "Just now",
        error: "Error",
        success: "Success",
        unknown_error_occurred: "An unknown error occurred",
        multiple: "Multiple",
        otonom_paid: "Otonom - Sent",
        otonom_cancelled: "Otonom - Cancelled",
        otonom_readyToSendToOtonom: "Otonom - Ready to Send",
        otonom_waitingApproval: "Otonom - Waiting Approval",
        otonom_error: "Otonom - Error",
        check: "Check",
        "credit-card": "Credit Card",
        "debit-card": "Debit Card",
        otonom: "Otonom Solution",
        "wire-transfer": "Wire Transfer",
        cash: "Cash",
        floor_NUMBER: "Floor $number",
        auth_issue_try_logging_out_and_logging_back_in_or_make_sure_you_have_adequate_permissions:
            "Authorization issue. Please log out and log back in or ensure you have the adequate permissions with your administrator.",
        vote_share_total_is_not_100: "The sum of voting rights is not equal to 100%.",
        institution_number_should_be_3_digits: "Institution number should be a 3 digit number",
        transit_number_should_be_5_digits: "Transit number should be a 5 digit number",
        account_number_should_be_5_to_20_digits: "Account number should be a 5 to 20 digit number",
        invalid_email_please_try_again: "Invalid email, please try again.",
        no_corresponding_user_with_this_email_found: "No corresponding user with this email found.",
        invalid_password_please_try_again: "Invalid password, please try again.",
        the_entered_password_is_not_correct: "The entered password is not correct.",
        too_many_requests_were_recently_made_for_this_account_please_wait_a_bit_before_trying_again:
            "Too many requests were recently made for this account, please wait a bit before trying again.",
        account_disabled: "Account has been disabled",
        please_confirm_your_email_before_logging_in: "Please confirm your email before logging in",
        not_connected_to_internet: "You are not connected to the Internet",
        problem_generating_report: "A problem occurred while generating the report.",
        problem_adding_users: "An error occurred while adding user.",
        error_saving_transaction: "An error occurred while saving transaction",
        problem_deleting_doc: "A problem occurred while deleting the document.",
        problem_updating_user_role: "An error occurred while updating user role.",
        problem_removing_user: "An error occurred while removing user.",
        problem_changing_vote_shares: "There was a problem. Vote shares have not been modified.",
        error_loading_bank_account_info: "An error occurred while loading the bank account info.",
        problem_sending_email_reminder: "An error occurred while sending the reminder email.",
        error_creating_a_new_fiscal_year: "Error creating a new fiscal year",
        problem_sending_unit_statement: "An error occurred while sending the unit statement.",
        problem_updating_bank_account_details:
            "A problem occurred while updating bank account details.",
        error_connecting_to_billing_portal:
            "A problem occurred while connecting to the invoice portal.",
        error_sending_to_otonom: "An error occurred while sending the file to Otonom Solution",
        problem_generating_otonom_csv:
            "An error occurred during the generation of the Otonom Solution data.",
        problem_close_unit_account: "Impossible to close this account",
        unknown_error_while_setting_approval: "Unknown error while setting approval",
        problem_create_transactions: "An error occurred while creating the transaction.",
        error_deleting_bank_account_info:
            "An error occurred while deleting the bank account information.",
        problem_creating_transaction: "An error occurred while creating the transaction.",
        problem_cancelling_transaction: "A problem occurred while cancelling the transaction.",
        error_changing_owners: "A problem occurred while changing the owner.",
        problem_create_emailTemplates: "An error occurred while creating the email template.",
        problem_archive_emailTemplates: "An error occurred while archiving the email template",
        problem_edit_emailTemplates: "An error occurred while editing the email template",
        problem_edit_buildings: "An error occurred while editing the building.",
        problem_edit_penalties: "An error occurred while editing the penalty.",
        problem_archive_checks: "An error occurred while archiving the check.",
        problem_create_penalties: "An error occurred while creating the penalty.",
        problem_edit_posts: "An error occurred while editing the post.",
        problem_edit_budgets: "An error occurred while editing the budget.",
        problem_create_posts: "An error occurred while creating the post.",
        problem_create_budgets: "An error occurred while creating the budget.",
        problem_archive_parkings: "An error occurred while archiving the parking.",
        problem_archive_lockers: "An error occurred while archiving the locker.",
        problem_archive_units: "An error occurred while archiving the unit.",
        problem_archive_banks: "An error occurred while archiving the bank account.",
        problem_archive_categories: "An error occurred while archiving the account.",
        problem_archive_people: "An error occurred while archiving the person.",
        problem_archive_suppliers: "An error occurred while archiving the supplier.",
        problem_create_suppliers: "An error occurred while creating the supplier.",
        problem_create_units: "An error occurred while creating the unit.",
        problem_create_parkings: "An error occurred while creating the parking.",
        problem_create_lockers: "An error occurred while creating the locker.",
        problem_create_requests: "An error occurred while creating the request.",
        problem_create_requestEmails: "An error occurred while creating the request email.",
        problem_edit_checks: "An error occurred while editing the check.",
        problem_edit_suppliers: "An error occurred while editing the supplier.",
        problem_edit_people: "An error occurred while editing the person.",
        problem_edit_banks: "An error occurred while editing the bank account.",
        problem_edit_parkings: "An error occurred while editing the parking.",
        problem_edit_lockers: "An error occurred while editing the locker.",
        problem_edit_categories: "An error occurred while editing the account.",
        problem_edit_requests: "An error occurred while editing the request.",
        problem_edit_requestTags: "An error occurred while editing the request tag.",
        problem_delete_posts: "An error occurred while deleting the post.",
        problem_delete_penalties: "An error occurred while deleting the penalty.",
        problem_delete_emailTemplates: "An error occurred while deleting the email template.",
        problem_delete_requests: "An error occurred while deleting the request.",
        problem_delete_requestTags: "An error occurred while deleting the request tag.",
        problem_archive_penalties: "An error occurred while archiving penalties.",
        problem_deleting_emailTemplates: "An error occurred while deleting the email template.",
        problem_loading_email: "An error occurred while loading the email.",
        error_reconciling_transaction: "An error occurred while reconciling the transaction.",
    },
}

/** All possible translation keys. We should avoid adding keys to this, prefer TKey whenever possible. */
export type SimpleTKey = keyof (typeof translations)[keyof typeof translations] | "" | undefined

type T$Opts = Record<`$${string}`, string | undefined>
/** Typed version of i18n.t. */
export function translate(tKey: SimpleTKey | LocalizedString, opts?: T$Opts) {
    if (!tKey) return ""
    const language = navigator.language.startsWith("fr") ? "fr" : "en"
    // if (typeof tKey === "object") return tKey[language]
    const _translations = translations[language]
    let translation =
        typeof tKey === "object" ? tKey[language]
        : tKey in _translations ? (_translations[tKey] ?? tKey)
        : tKey
    const options = Object.entries(opts ?? {})
    for (let i = 0, n = options.length; i < n; i++) {
        const [option, value] = options[i] ?? []
        if (!option || !value) continue
        translation = translation.replace(new RegExp(`\\${option}`, "g"), value)
    }
    return translation
}
