import { useMemo, useEffect, useRef, useCallback, createContext, useContext } from "react"
import { useDispatch, useSelector } from "react-redux" // eslint-disable-line
import type { AppDispatch, RootState } from "store/store"
import type { TKey } from "../i18n"
import { useLocation, useParams } from "react-router" // eslint-disable-line
import { Role } from "@constants/Role"
import { deepEqual } from "fast-equals"
import { useMediaQuery } from "@mantine/hooks"
import { Formatter } from "@shared/formatter"
import fr from "../locales/fr/fr"
import en from "../locales/en/en"
import { LocalizedString } from "@appnflat-types/types"
import { FeatureFlags } from "@shared/FeatureFlags"
import { useBuilding, useCurrency, useUser } from "./useStore"
import { BuildingGroup } from "@appnflat-types/BuildingGroup/BuildingGroup"
import { cachedBuildingSelector } from "store/cacheSelectors"
import { useLanguage, useLocale } from "./useTranslate"
import { BuildingGroupUserRole } from "@appnflat-types/BuildingGroup/BuildingGroupUser"
import { BuildingRef } from "@appnflat-types/BaseStrings"
import {
    PersonUUID,
    CategoryUUID,
    LockerUUID,
    ParkingUUID,
    BankUUID,
    SupplierUUID,
    UnitUUID,
    buildingGroupIdSchema,
} from "@appnflat-types/Id"

type DispatchFunc = () => AppDispatch
export const useAppDispatch: DispatchFunc = useDispatch

export function useAppSelector<TState extends RootState, TSelected>(
    selector: (state: TState) => TSelected
): TSelected {
    return useSelector(selector, deepEqual)
}

/** Returns the most recent fiscal year of the building. */
export function useMostRecentFiscalYear(): number | undefined {
    const building = useBuilding()
    return building?.fiscalYears.length ? Math.max(...building.fiscalYears) : undefined
}

export function useAppFormatter(): Formatter {
    const locale = useLocale()
    const currency = useCurrency()
    return useMemo(() => new Formatter(locale, currency), [locale, currency])
}

/** Returns a collator for the current language. */
export function useCollator(): Intl.Collator {
    const language = useLanguage()
    return useMemo(
        () => new Intl.Collator(language, { numeric: true, sensitivity: "base" }),
        [language]
    )
}

export function useFeatureFlags(): FeatureFlags | null {
    const building = useBuilding()
    return useMemo(() => (building ? new FeatureFlags(building) : null), [building])
}

const locales = { fr, en }

type T$Opts = Record<`$${string}`, string | undefined>
/** Type of the `t` function used for translations. */
export type TFunction = (tKey: TKey | LocalizedString, opts?: T$Opts) => string

/** Returns a function to translate keys to the user's locale. */
export function useAppTranslation(): TFunction
export function useAppTranslation<T extends string>(localTranslations?: {
    [key in T]: LocalizedString
}): TFunction & ((tKey: T, opts?: T$Opts) => string)
export function useAppTranslation<T extends string>(localTranslations?: {
    [key in T]: LocalizedString
}) {
    const language = useLanguage()
    return useCallback(
        (tKey: TKey | T | LocalizedString, opts?: T$Opts) => {
            let translation: string
            if (!tKey) {
                return ""
            } else if (typeof tKey === "object") {
                translation = tKey[language] ?? ""
            } else if (tKey.includes(":")) {
                const [group, key] = tKey.split(":")
                if (!key) return tKey
                const _translations: Record<
                    string,
                    Record<string, string | undefined> | undefined
                > = locales[process.env.IS_TEST === "true" ? "en" : language]
                translation =
                    group && key && group in _translations && _translations[group] ?
                        (_translations[group]?.[key] ?? key)
                    :   key
            } else {
                if (!localTranslations) return tKey
                const _translations = localTranslations[tKey as T]
                if (!_translations) return tKey
                translation = _translations[language]
            }
            if (!translation) return tKey
            const options = Object.entries(opts ?? {})
            for (let i = 0, n = options.length; i < n; i++) {
                const [option, value] = options[i] ?? []
                if (!option || !value) continue
                translation = translation.replace(new RegExp(`\\${option}`, "g"), value)
            }
            return translation
        },
        [language, localTranslations]
    )
}

/** Returns whether the viewport is currently sized as a mobile device. */
export function useIsMobile(): boolean {
    return !!useMediaQuery("(max-width: 768px)") // 48em
}

/** The context that can be used to override the values of {@link useAppParams}. */
export const AppParamsContext = createContext<{
    buildingRef?: BuildingRef
    buildingGroupId?: BuildingGroup["id"]
    fiscalYear?: number
}>({})

/** Typed version of useParams().
 *
 * This hook will first check the context for the values, and if they are not present, it will
 * use the values from the URL params.
 * In other words, you can use the {@link AppParamsContext} to override some values.
 */
export function useAppParams(): {
    buildingRef?: BuildingRef
    /** The id of the building group.
     *
     * Will be deduced from `buildingRef` if we are in a building.
     */
    buildingGroupId?: BuildingGroup["id"]
    fiscalYear?: number
    unitUUID?: UnitUUID
    supplierUUID?: SupplierUUID
    bankUUID?: BankUUID
    parkingUUID?: ParkingUUID
    lockerUUID?: LockerUUID
    categoryUUID?: CategoryUUID
    personUUID?: PersonUUID
} {
    const params = useParams()
    const overrideContext = useContext(AppParamsContext)
    const buildingRef =
        overrideContext.buildingRef ?? (params.buildingRef as BuildingRef | undefined)
    const fiscalYearAsNumber = Number(overrideContext.fiscalYear ?? params.fiscalYear)
    const building = useAppSelector((s) => cachedBuildingSelector(s, buildingRef))
    const buildingGroupId = useMemo(() => {
        if (overrideContext.buildingGroupId) return overrideContext.buildingGroupId
        const parseResult = buildingGroupIdSchema.safeParse(params.buildingGroupId)
        if (parseResult.success) return parseResult.data
        else return building?.buildingGroupId
    }, [params.buildingGroupId, building?.buildingGroupId, overrideContext.buildingGroupId])
    return {
        ...params,
        buildingRef,
        fiscalYear: Number.isNaN(fiscalYearAsNumber) ? undefined : fiscalYearAsNumber,
        buildingGroupId,
    }
}

/** A custom hook that builds on useLocation to parse the query string for you. */
export function useQuery(): URLSearchParams {
    const { search } = useLocation()
    return useMemo(() => new URLSearchParams(search), [search])
}

/** A custom hook that returns information on the permissions of the user for the building. */
export function usePermissions() {
    const user = useUser()
    const building = useBuilding()

    return useMemo(() => {
        let userRole = user.buildingUser?.role
        const bgUserRole = user.buildingGroupUser?.role
        // If the user is a building group user, we need to convert the role to a building role.
        // Note that any role specified on the building user takes precedence over the building group user role.
        if (bgUserRole && !userRole) {
            userRole = Role[bgUserRole]
        }
        const isBanned = !!(
            userRole &&
            [Role.owner, Role.resident].includes(userRole) &&
            user.buildingUser?.banned
        )
        const isAdmin =
            (!!userRole && [Role.approverWrite, Role.approver, Role.admin].includes(userRole)) ||
            (!!bgUserRole && [BuildingGroupUserRole.admin].includes(bgUserRole))
        const isAccountant =
            (!!userRole && [Role.accountantWrite, Role.accountant].includes(userRole)) ||
            (!!bgUserRole &&
                [BuildingGroupUserRole.accountantWrite, BuildingGroupUserRole.accountant].includes(
                    bgUserRole
                ))
        const isOwnerOrResident = !!userRole && [Role.owner, Role.resident].includes(userRole)

        const canAccessUnitTransactions = !(
            userRole === Role.resident ||
            (userRole === Role.owner &&
                (!building?.ownersFinancesAccess || building?.ownersFinancesAccess === "none"))
        )

        return {
            /** Whether the user can access unit transactions. */
            canAccessUnitTransactions,
            /** The uid of the user. */
            userUID: user.globalUser?.uid,
            /** The role of the user. */
            userRole,
            /** The role of the user in the building group. */
            buildingGroupUserRole: bgUserRole,
            /** Whether the user is banned. */
            isBanned,
            /** The user has either admin or approver roles. */
            isAdmin,
            /** The user has either accountant or accountant with write roles. */
            isAccountant,
            /** The user has either owner or resident roles. */
            isOwnerOrResident,
            /** The user is an owner that can access their owned units' finances. */
            hasOwnerSelfFinancialAccessRead:
                userRole === Role.owner && building?.ownersFinancesAccess === "self",
            /** The user is an owner with limited financial read abilities (suppliers, banks, but not people, payments, etc.) */
            hasOwnerAllFinancialAccessRead:
                userRole === Role.owner && building?.ownersFinancesAccess === "all",
            /** The user can read to all financial objects (units, suppliers, budgets, people, payments etc.) */
            hasFinancialAccessRead: isAdmin || isAccountant,
            /** The user can write to all financial objects (units, suppliers, budgets, etc.) */
            hasFinancialAccessWrite: isAdmin || userRole === Role.accountantWrite,
            hasSocialAccessRead: isAdmin || isOwnerOrResident,
            hasSocialAccessWrite: isAdmin || (isOwnerOrResident && !isBanned),
        }
    }, [building?.ownersFinancesAccess, user])
}

/** Hook to dynamically set focus on an element.
 *
 * Returns:
 * - The ref to attach to the element,
 * - A function to set focus on the element to which the ref is attached,
 * - A function to remove focus from the element to which the ref is attached.
 */
export function useFocus<T extends HTMLElement = HTMLElement>(): [
    React.RefObject<T | null>,
    () => void,
    () => void,
] {
    const ref = useRef<T>(null)
    const setFocus = () => ref?.current?.focus?.()
    const unsetFocus = () => ref?.current?.blur?.()
    return [ref, setFocus, unsetFocus] as const
}

/**
 * All the valid key codes that the browser can provide (this is not an exhaustive list and
 * can be updated if need be).
 */
export type KeyboardKey =
    | " "
    | "Enter"
    | "Tab"
    | `Arrow${"Down" | "Up" | "Left" | "Right"}`
    | "Backspace"
    | "Escape"
    | "0"
    | "1"
    | "2"
    | "3"
    | "4"
    | "5"
    | "6"
    | "7"
    | "8"
    | "9"
    | "a"
    | "b"
    | "c"
    | "d"
    | "e"
    | "f"
    | "g"
    | "h"
    | "i"
    | "j"
    | "k"
    | "l"
    | "m"
    | "n"
    | "o"
    | "p"
    | "q"
    | "r"
    | "s"
    | "t"
    | "u"
    | "v"
    | "w"
    | "x"
    | "y"
    | "z"
    | "/"
    | "."
    | ","
    | "?"
    | "["
    | "]"

/** Allows capture of key entries.
 *
 * Will not capture keys when they are entered on an element other
 * than a button or the body, unless `modifier == "CmdOrCtrl" || key == "Escape"`.
 */
export function useKey(
    modifier: "CmdOrCtrl" | "Shift" | null,
    key: KeyboardKey,
    cb: (() => void) | undefined,
    /** A function that returns whether or not we should execute the callback. */
    conditionalExecution?: () => boolean
): void {
    const callback = useRef(cb)
    // Do not remove the `{}`, it will cause the callback to be called immediately.
    useEffect(() => {
        callback.current = cb
    })
    useEffect(() => {
        function handle(event: KeyboardEvent) {
            const isInput =
                !!event &&
                !!event.target &&
                "nodeName" in event.target &&
                typeof event.target.nodeName === "string" &&
                !["body", "button", "div"].includes(event.target.nodeName.toLowerCase())
            if (isInput && modifier !== "CmdOrCtrl" && key !== "Escape") return
            function executeCallBack() {
                if (conditionalExecution && !conditionalExecution()) return
                event.preventDefault()
                callback.current?.()
            }
            if (modifier && event.key === key) {
                if (modifier === "CmdOrCtrl" && (event.ctrlKey || event.metaKey)) executeCallBack()
                else if (modifier === "Shift" && event.shiftKey) executeCallBack()
            } else if (!modifier && event.key === key) {
                executeCallBack()
            }
        }
        document.addEventListener("keydown", handle)
        return () => document.removeEventListener("keydown", handle)
    }, [conditionalExecution, key, modifier])
}
