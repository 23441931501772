// Note: the following keys are safe to expose, they only serve to identify our app.
const firebaseConfig = {
    apiKey: "AIzaSyCKqoXtuuHyUQ3EzHUZqFBhW-P2iLp_9Zs",
    authDomain: "appnflat-canada.firebaseapp.com",
    projectId: "appnflat-canada",
    storageBucket: "appnflat-canada.appspot.com",
    messagingSenderId: "606804700574",
    appId: "1:606804700574:web:5542f07364e20cfda67a8f",
    measurementId: "G-JL96WES7LB",
}
import { initializeApp } from "firebase/app"
import { getStorage } from "firebase/storage"
import { browserSessionPersistence, initializeAuth } from "firebase/auth"
import { initializeFirestore } from "firebase/firestore"
import { getPerformance } from "firebase/performance"

export const app = initializeApp(firebaseConfig)
export const storage = getStorage(app)
export const db = initializeFirestore(app, {})
export const auth = initializeAuth(app, { persistence: browserSessionPersistence })
/* istanbul ignore next */
export const performance = process.env.IS_TEST === "true" ? undefined : getPerformance(app)
