import { Collection } from "../../@appnflat-types/Collection"
import { LocalizedString } from "../../@appnflat-types/types"

/** The type of object that can be described.
 *
 * - `Collection`: The object in the corresponding collection.
 * - `"otherUser"`: A user that is not the caller.
 * - `"building"`: A building.
 * - `"accounts"`: An account (i.e., units, suppliers, etc.).
 * - `"buildingGroups"`: A management company.
 */
export type ObjectType = Collection | "buildings" | "otherUser" | "accounts" | "buildingGroups"

/** A map of object types to their descriptions. */
export const objectDescriptionMap: Record<ObjectType, LocalizedString> = {
    banks: { fr: "banque", en: "bank" },
    budgets: { fr: "budget", en: "budget" },
    buildings: { fr: "bâtiment", en: "building" },
    categories: { fr: "catégorie", en: "category" },
    checks: { fr: "chèque", en: "check" },
    lockers: { fr: "casier", en: "locker" },
    otonomBatches: { fr: "lot otonom", en: "otonom batch" },
    parkings: { fr: "stationnement", en: "parking" },
    people: { fr: "personne", en: "person" },
    penalties: { fr: "pénalité", en: "penalty" },
    posts: { fr: "poste", en: "post" },
    postTemplates: { fr: "modèle de poste", en: "post template" },
    suppliers: { fr: "fournisseur", en: "supplier" },
    transactions: { fr: "transaction", en: "transaction" },
    unreconciledTransactions: { fr: "transaction non réconciliée", en: "unreconciled transaction" },
    units: { fr: "unité", en: "unit" },
    users: { fr: "utilisateur", en: "user" },
    invitedUsers: { fr: "utilisateur invité", en: "invited user" },
    requests: { fr: "requête", en: "request" },
    preparedOtonomFiles: { fr: "fichier otonom préparé", en: "prepared otonom file" },
    emailTemplates: { fr: "modèle de courriel", en: "email template" },
    logs: { fr: "journal", en: "log" },
    requestEmails: { fr: "email de requête", en: "request email" },
    requestTags: { fr: "type de requête", en: "request tag" },
    secretsBankAccounts: { fr: "détail de compte bancaire", en: "bank account details" },
    metadata: { fr: "métadonnée", en: "metadata" },
    locks: { fr: "inconnu", en: "unknown" },
    creditNotes: { fr: "note de crédit", en: "credit note" },
    otherUser: { fr: "autre utilisateur", en: "other user" },
    accounts: { fr: "compte", en: "account" },
    buildingGroups: { fr: "entreprise de gestion", en: "management company" },
    changeRequests: { fr: "demande de changement", en: "change request" },
    requestEmailTemplates: { fr: "modèle de courriel de requête", en: "request email template" },
}
