import { addOpenAPI } from "../zodExtensions"
import { ParsingErrors } from "../parsingErrors"
import { z } from "zod"
import { uidString, shortString } from "../BaseStrings"
import { ID_PREFIXES, idSchemaGenerator } from "../Id"

export const requestTagIdSchema = idSchemaGenerator(ID_PREFIXES.REQUEST_TAG)
export type RequestTagId = z.infer<typeof requestTagIdSchema>

/** A request tag.
 *
 * Corresponds to the document `db/buildings/{buildingRef}/requestTags/{id}` or
 * `db/buildingGroups/{buildingGroupId}/requestTags/{id}`.
 */
export const requestTagSchema = addOpenAPI(
    z.object({
        id: requestTagIdSchema,
        /** The name of the tag. */
        name: shortString,
        /** The color of the tag, used when displaying it as a badge. */
        color: z
            .string()
            .regex(/^#([0-9a-fA-F]{6})$/, { message: ParsingErrors.invalid_hex_color_code })
            .optional(),
        /** The default handler for this tag. */
        defaultHandler: uidString.optional(),
    }),
    "RequestTag"
)
/**
 * A request tag.
 * Corresponds to the document `db/buildings/{buildingRef}/requestTags/{uuid}`.
 */
export type RequestTag = z.infer<typeof requestTagSchema>
