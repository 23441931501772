import { EMAIL_MAX_LENGTH, PERSON_MAX_NUMBER_OF_EMAILS, PHONE_MAX_LENGTH } from "./Constants"

export enum ParsingErrors {
    too_many_attachments = "too_many_attachments",
    /** For forms where the user must agree to terms. */
    you_must_agree = "you_must_agree",
    must_be_in_the_past = "must_be_in_the_past",
    must_be_3_digits = "must_be_3_digits",
    must_be_5_digits = "must_be_5_digits",
    must_only_contain_digits = "must_only_contain_digits",
    invalid_email_address = "invalid_email_address",
    should_be_decimal_positive_number = "should_be_decimal_positive_number",
    must_contain_between_5_and_20_digits = "must_contain_between_5_and_20_digits",
    must_be_positive = "must_be_positive",
    must_be_less_than_100 = "must_be_less_than_100",
    cannot_contain_slash_or_hashtag = "cannot_contain_slash_or_hashtag",
    some_subtransaction_should_have_non_null_amount = "some_subtransaction_should_have_non_null_amount",
    subtransactions_should_balance_out_to_zero = "subtransactions_should_balance_out_to_zero",
    passwords_dont_match = "passwords_dont_match",
    unknown_error = "unknown_error",
    account_is_missing = "account_is_missing",
    invalid_hex_color_code = "invalid_hex_color_code",
    file_name_cannot_contain_slash = "file_name_cannot_contain_slash",
    too_many_emails = "too_many_emails",
    email_too_long = "email_too_long",
    phone_too_long = "phone_too_long",
    phone_number_and_type_required = "phone_number_and_type_required",
}

export const ParsingErrorsFR: { [key in ParsingErrors]: string } = {
    too_many_attachments: "Trop de fichiers joints",
    you_must_agree: "Vous devez accepter",
    must_be_in_the_past: "Doit être dans le passé",
    must_be_3_digits: "Doit contenir 3 chiffres",
    must_be_5_digits: "Doit contenir 5 chiffres",
    must_only_contain_digits: "Doit contenir uniquement des chiffres",
    invalid_email_address: "Adresse email invalide",
    should_be_decimal_positive_number: "Entrez un nombre décimal positif",
    must_contain_between_5_and_20_digits: "Doit contenir entre 5 et 20 chiffres",
    must_be_positive: "Doit être positif",
    must_be_less_than_100: "Doit être inférieur à 100%",
    cannot_contain_slash_or_hashtag: "Ne doit pas contenir de barres obliques ou de mot-dièse",
    some_subtransaction_should_have_non_null_amount:
        "Un des montants de la transaction doit être différent de zéro.",
    subtransactions_should_balance_out_to_zero:
        "La somme de tous les montants doit être égale à zéro.",
    passwords_dont_match: "Les mots de passes ne sont pas les mêmes",
    unknown_error: "Erreur inconnue",
    account_is_missing: "Compte manquant",
    invalid_hex_color_code: "Couleur invalide",
    file_name_cannot_contain_slash: "Le nom du fichier ne peut pas contenir de barre oblique",
    too_many_emails: `Le nombre de courriels ne peut pas dépasser ${PERSON_MAX_NUMBER_OF_EMAILS}.`,
    email_too_long: `L'adresse email est trop longue, le maximum est ${EMAIL_MAX_LENGTH} caractères.`,
    phone_too_long: `Le numéro de téléphone est trop long, le maximum est ${PHONE_MAX_LENGTH} caractères.`,
    phone_number_and_type_required: "Le numéro de téléphone et le type de téléphone sont requis.",
}

export const ParsingErrorsEN: { [key in ParsingErrors]: string } = {
    too_many_attachments: "Too many files attached",
    you_must_agree: "You must agree",
    must_be_in_the_past: "Must be in the past",
    must_be_3_digits: "Must be 3 digits",
    must_be_5_digits: "Must be 5 digits",
    must_only_contain_digits: "Must only contain digits",
    invalid_email_address: "Invalid email address",
    should_be_decimal_positive_number: "Should be a decimal positive number",
    must_contain_between_5_and_20_digits: "Must contain between 5 and 20 digits",
    must_be_positive: "Must be positive",
    must_be_less_than_100: "Must be less than 100%",
    cannot_contain_slash_or_hashtag: "Cannot contain slashes or pound signs",
    some_subtransaction_should_have_non_null_amount:
        "One of the transaction's amounts must not be zero.",
    subtransactions_should_balance_out_to_zero: "The sum of all amounts should equal zero.",
    passwords_dont_match: "The passwords do not match",
    unknown_error: "Unknown error",
    account_is_missing: "Account is missing",
    invalid_hex_color_code: "Invalid color",
    file_name_cannot_contain_slash: "File name cannot contain a slash",
    too_many_emails: `The number of emails cannot exceed ${PERSON_MAX_NUMBER_OF_EMAILS}.`,
    email_too_long: `The email address is too long, the maximum is ${EMAIL_MAX_LENGTH} characters.`,
    phone_too_long: `The phone number is too long, the maximum is ${PHONE_MAX_LENGTH} characters.`,
    phone_number_and_type_required: "Both the phone number and the phone type are required.",
}

function languageToParsingErrorEnum(language: string | undefined) {
    if (!language || language.startsWith("en")) return ParsingErrorsEN
    else if (language.startsWith("fr")) return ParsingErrorsFR
    else return ParsingErrorsEN
}

function isParsingErrors(key: ParsingErrors | string | undefined): key is ParsingErrors {
    return !!key && Object.keys(ParsingErrors).includes(key)
}

export function parsingErrorLocale(
    key: ParsingErrors | string | undefined,
    language: string | undefined
) {
    const enumKey = languageToParsingErrorEnum(language)
    if (!isParsingErrors(key)) return enumKey.unknown_error
    else return enumKey[key]
}
