const core = {
    administrator: "Gestionnaire d'immeuble",
    file_will_be_deleted: "Ce fichier sera supprimé.",
    request_email_templates: "Modèles de réponses aux requêtes",
    create_a_statement_for: "Créer un état de compte pour :",
    post_templates: "Modèles de communication",
    your_building_groups: "Vos groupes d'immeubles",
    building_group: "Groupe d'immeubles",
    building_groups: "Groupes d'immeubles",
    approval_note: "Note d'approbation",
    show_cancelled_transactions: "Afficher les transactions annulées",
    date_sent_to_otonom: "Date d'envoi à Otonom",
    file_format: "Format de fichier",
    trial_balances: "Bilan de vérification",
    accountant_writings: "Écritures comptables",
    unit_payment: "Paiement d'unité",
    proxy: "Mandataire",
    proxies: "Mandataires",
    documentation: "Documentation",
    cancellation_reason: "Raison de l'annulation",
    pre_authorized_debit: "Prélèvement préautorisé",
    send_by_email: "Envoyer par courriel",
    check_reversal_fee: "Frais de chèque retourné",
    please_come_back_in_a_few_minutes: "Veuillez revenir dans quelques minutes.",
    app_under_maintenance: "AppNFlat est en train d'être mis à jour.",
    new_reverse_check: "Chèque retourné",
    notify_owners_by_email: "Avertir le propriétaire par courriel",
    to_add_penalty_first_set_amount_in_penalties_tab:
        "Veuillez d'abord définir la pénalité pour chèque retourné.",
    apply_penalty: "Appliquer la pénalité",
    "new_reverse_wire-transfer": "Tranfert électronique retourné",
    list: "Retour à la liste",
    please_use_on_a_compouter_for_now:
        "Veuillez utiliser AppNFlat sur un ordinateur pour le moment (si vous êtes sur un ordinateur, veuillez vous assurer que la fenêtre de votre navigateur est suffisament large).",
    coming_soon: "Bientôt disponible",
    go_back_home: "Retour à la page d'accueil",
    mobile_not_yet_supported: "Mobile pas encore supporté",
    include_signatures: "Inclure les signatures",
    no_number_of_approvals_required_means_no_signatures_will_be_printed:
        "Comme aucun nombre d'approbation n'a été spécifié dans la section 'Administration', aucune signature ne sera imprimée.",
    printing_check: "Impression du chèque",
    signature_set: "Signatures",
    number_of_approvals_required: "Nb. d'approbations requises",
    signature: "Signature",
    clear: "Effacer",
    change_signature: "Modifier la signature",
    archived_year: "Année fiscale archivée",
    overpaid: "Trop perçu",
    total_overpaid: "Total trop perçu",
    error_loading_email: "Une erreur s'est produite lors du chargement de l'email.",
    attachments: "Pièces jointes",
    add_file: "Ajouter un fichier",
    reply: "Répondre",
    new_request_from_email: "Nouvelle requête à partir de cet email",
    assign_to_request: "Ajouter l'email à une requête existante",
    spam: "Spam",
    emails_unassigned_to_a_request: "Emails sans requête associée",
    incoming_emails: "Nouveaux Emails",
    inbox: "Boite de réception",
    trashed: "Poubelle",
    no_requests_to_assign_to: "Aucune requête à laquelle associer",
    no_requests_to_merge_with: "Aucune requête avec laquelle fusionner",
    unit_renting: "Unité louant le parking",
    all_units: "Toutes les unités",
    all_parkings: "Tous les parkings",
    all_lockers: "Tous les casiers",
    applies_to: "S'applique à",
    no_monthly_fees: "Pas de charge mensuelle",
    only_you_can_see_this: "Seul vous pouvez voir ceci, personne d'autre dans l'immeuble ne peut.",
    low: "Basse",
    medium: "Normale",
    high: "Elevée",
    open: "Ouverte",
    underway: "En cours",
    closed: "Fermée",
    suggestions: "Suggestions",
    people: "Personnes",
    new_request: "Nouvelle requête",
    priority: "Priorité",
    requestors: "Demandeurs",
    subject: "Sujet",
    new_request_tag: "Nouveau type",
    request: "Requête",
    merge: "Fusionner des requêtes",
    request_emails: "Emails associés à la requête",
    print_check: "Imprimer le chèque",
    to_enable_ensure_payment_day_is_set:
        "Afin d'active le paiement automatique assurez-vous de renseigner le jour du prélèvement.",
    yourself: "Vous",
    requests: "Requêtes",
    handler: "Responsable",
    tags: "Types de requêtes",
    color: "Couleur",
    edit_tags: "Modifier les types",
    request_tags: "Type de la requête",
    and_NUMBER_more: "+ $number autres",
    "+": "+",
    "1_month": "1 mois",
    "1_week": "1 semaine",
    "2_months": "2 mois",
    "2_weeks": "2 semaines",
    "total_accounts-payable": "TOTAL Comptes à payer",
    "total_accounts-receivable": "TOTAL Comptes à recevoir",
    access_billing: "Abonnement AppNFlat",
    account_number: "N° du compte bancaire",
    account_transfer: "Transfert entre comptes",
    account: "Compte",
    accounting_categories: "Comptes",
    accounting_id: "Identifiant comptable",
    accounting_number: "N° du compte",
    accounting: "Comptabilité",
    "accounts-payable": "À payer",
    accounts_receivable_and_payable: "Comptes à payer & à recevoir",
    "accounts-receivable": "À recevoir",
    accounts: "Comptes bancaires",
    active: "Actif",
    actual_amount: "Montant courant",
    add_cash: "+ Cash",
    add_check: "+ Chèque",
    add_default_bank_account: "Ajouter un compte en banque par défaut",
    add_email: "+ Courriel",
    add_locker: "+ Casier",
    add_multiple_following_checks: "Ajouter une séquence de chèques",
    add_new: "+",
    add_parking: "+ Parking",
    add_recurring_bill: "+ Facture récurrente",
    add_resident: "+ Locataire",
    add_user: "+ Utilisateur",
    address: "Adresse",
    administration: "Administration",
    all_owners_and_residents_with_a_locker: "Propriétaires et locataires possédant un casier",
    all_owners_and_residents_with_a_parking: "Propriétaires et locataires possédant un parking",
    all_owners: "Tous les propriétaires",
    all_posts: "Toutes les annonces",
    all_residents: "Tous les locataires",
    all_transactions: "Toutes les transactions",
    all_users: "Tous les utilisateurs",
    all: "Tous",
    amount_adjusts_when_selecting_invoices:
        "Le montant s'ajuste lorsque vous sélectionnez des factures à payer.",
    amount: "Montant",
    monthly_contributions: "Contributions mensuelles",
    apply_new_vote_shares: "Appliquer les nouveaux droits de vote",
    approval_status: "Statut de l'approbation",
    approval: "Approbations",
    approvals: "Approbations",
    approve: "Approuver",
    approved_and_paid_transactions: "Transactions approuvées et payées",
    approved_and_unpaid_transactions: "Transactions approuvées et en attente de paiement",
    approved_by: "Approuvé par",
    approved_transactions: "Transactions approuvées",
    approved: "Approuvé",
    archive_category: "Archiver le compte",
    archive_person: "Archiver la personne",
    archive: "Archiver",
    archived: "Archivé",
    as_administrator: "En tant que gestionnaire d'immeuble",
    as_yourself: "En votre nom propre",
    assets: "Actif",
    author: "Auteur",
    automatic_communications: "Emails automatiques",
    automatic_deposit: "Prélèvement automatique",
    automatic_interest_rate: "Taux d'intérêt pour retard de paiement",
    automatic_penalties: "Pénalités facturées automatiquement",
    back: "Retour",
    balance_sheet: "Bilan",
    balance: "Solde",
    balances_may_take_a_few_minutes_to_update_after_a_transaction_is_added_or_removed:
        "La mise à jour du solde peut prendre quelques minutes lorsque vous ajoutez ou supprimez une transaction.",
    bank_account_name: "Nom du comte en banque",
    bank_account_number: "N° du compte",
    bank_account_type: "Nature du compte",
    bank_account: "Compte en banque",
    bank_accounts: "Comptes bancaires",
    bank_fee: "Frais bancaires",
    bank_institution_number: "N° de l'institution",
    bank_reconciliation: "Conciliation",
    bank_statement: "Relevé bancaire",
    bank_transit_number: "N° de transit",
    bank: "Banque",
    banks: "Banques",
    banned_from_social: "Interdit de poster",
    became_a_resident_on: "Locataire depuis",
    became_an_owner_on: "Copropriétaire depuis",
    beneficiary_bank_account: "Compte bancaire bénéficiaire",
    bill_number: "N° de facture",
    billing_day: "Jour de facturation",
    body: "Texte",
    book_entry: "Écriture comptable",
    budget_application_date: "Date d'application du budget",
    budget_total_amount: "Total Budget",
    budget: "Budget",
    budgeted_amount: "Montant budgété",
    building_name: "Nom de l'immeuble",
    building: "Immeuble",
    cadastre_number: "Numéro de Cadastre",
    caller: "Payé par",
    canadian_dollar: "Dollar canadien",
    cancel_transaction: "Annuler la transaction",
    cancel: "Annuler",
    cancelled: "Annulé",
    capital_payed: "Paiement de capital",
    capital: "Capital",
    cash_flow_statement: "Trésorerie",
    cash_flow: "Trésorerie",
    cash: "Cash",
    category: "Compte",
    cell: "Cellulaire",
    change_file: "Changer de fichier",
    change_password: "Changer le mot de passe",
    changing_password: "Changement de mot de passe...",
    charges: "Frais",
    check_number: "N° de chèque",
    check: "Chèque",
    checking_account: "Compte chèque",
    checks_to_be_deposited: "Chèques en attente de dépôt",
    checks: "Chèques",
    city: "Ville",
    close_unit_account: "Clôturer le compte de l'unité",
    close: "Fermer",
    closing_balance: "Solde de clôture",
    co_owner: "Copropriétaire",
    co_owners: "Copropriétaires",
    communications: "Communications",
    condo_fee_invoicing_language: "Langue de facturation des frais de condo",
    condo_fee_payment: "Payment de frais de condo",
    condo_fees_to_pay: "Frais de condo à payer",
    condo_fees_to_receive: "Frais de condo à recevoir",
    condo_fees: "Charges communes",
    confirm_sending_to_otonom: "Confirmation de l'envoi à Otonom Solution",
    contact_support: "Contacter le support",
    contact: "Contact",
    continue: "Continuer",
    contributions: "Contributions budgétaires",
    counterpart: "Contrepartie",
    country: "Pays",
    create_email_template: "Nouveau modèle",
    create_statement: "Générer un relevé",
    credit_card: "Carte de crédit",
    credit_note: "Note de crédit",
    credit: "Crédit",
    creditor: "Créditeur",
    csv: "CSV",
    currency: "Devise",
    dashboard: "Tableau de bord",
    data_is_encrypted_using_industry_standards_such_as_aes_256:
        "Les données sont encryptées selon les standards de l'industrie tels que AES 256.",
    data_is_only_used_to_process_payments_with_otonom:
        "Les données sont utilisée uniquement afin de procéder aux paiements via le logiciel Otonom Solution.",
    date_range: "Période",
    date: "Date",
    debit_card: "Carte de débit",
    debit: "Débit",
    debtor: "Débiteur",
    default_bank_account_not_specified: "Le compte en banque par défaut n'a pas été défini",
    delete_file: "Supprimer le fichier",
    delete_locker: "Supprimer le casier",
    delete_parking: "Supprimer le Parking",
    delete_this_user: "Supprimer cet utilisateur ?",
    delete_unit: "Supprimer l'unité",
    delete_user: "Supprimer l'utilisateur",
    delete: "Supprimer",
    deleting_bank_account_info: "Suppression des informations bancaires...",
    deleting: "Suppression en cours...",
    deposit_number: "N° de dépôt",
    deposit: "Dépôt",
    deposited_amount: "Montant déposé",
    deposits: "Dépôts",
    description: "Description",
    difference: "Différence",
    do_you_wish_to_remove_locker: "Voulez-vous supprimer ce casier ?",
    do_you_wish_to_remove_parking: "Voulez-vous supprimer ce parking ?",
    done: "Complété",
    download: "Télécharger",
    due_to_suppliers: "Fournisseurs à payer",
    edit_budget: "Modifier le budget",
    edit_email_template: "Modifier le modèle de courriel",
    edit_owners: "Changer de copropriétaire",
    edit_post: "Modifier le message",
    edit_user: "Utilisateur",
    edit: "Modifier",
    email_insurance_about_to_expire_frequency:
        "Fréquence de relance pour le renouvellement d'une assurance :",
    email_insurance_about_to_expire_start:
        "Délais pour avertir qu'une assurance doit être renouvelée :",
    email_missing_data_insurance_frequency:
        "Fréquence de relance pour la mise à jour des données d'assurance :",
    email_missing_data_water_heater_frequency:
        "Fréquence de relance pour la mise à jour des données de chauffe-eau :",
    email_templates: "Courriels modèles",
    email_topic: "Sujet du courriel",
    email_water_heater_about_to_expire_frequency:
        "Fréquence de relance pour le remplacement d'un chauffe-eau :",
    email_water_heater_about_to_expire_start:
        "Délais pour avertir qu'un chauffe-eau doit être remplacé :",
    email: "Courriel",
    emergency_contact: "Contact d'urgence",
    empty_if_no_automatic_payment: "Vide si pas de paiement automatique",
    empty_if_no_end: "Vide si pas de fin",
    end_date: "Date de clôture",
    end_of_warranty: "Fin de garantie",
    ensure_difference_is_zero: "Assurez-vous que l'écart soit nul.",
    enter_a_valid_email: "Entrez un courriel valide.",
    error: "Erreur",
    essential_information_missing: "Information manquante",
    euro: "Euro",
    everyone_in_the_building: "Tous les copropriétaires et locataires",
    excel: "Excel",
    expense_account: "Compte de dépenses",
    expense_category: "Compte de dépense",
    expense: "Dépense",
    expenses: "Dépenses",
    export: "Exporter",
    fax: "Fax",
    fee_payment: "frais de condo",
    financial_statement_section: "États Financiers (section)",
    financial_statements_parameters: "Paramètres des états financiers",
    financial_statements: "États Financiers",
    financials: "Finances",
    first_name: "Prénom",
    fiscal_year: "Année fiscale",
    floor_NUMBER: "Étage $number",
    floor: "Étage",
    fraction: "Fraction",
    from: "De",
    full_name: "Nom complet",
    gender: "Sexe",
    generate_otonom_csv_for_units: "Encaisser le paiement des unités via Otonom Solution",
    generate_otonom_csv: "Envoyer le paiement à Otonom Solution",
    generates_a_csv_file_for_otonom_to_collect_unit_payments:
        "Envoie l'encaissement des unités inscrite au prélèvement automatique à Otonom Solution.",
    generating_otonom_csv: "Génération du fichier CSV pour Otonom Solution.",
    grace_period: "Période de grâce",
    history: "Historique",
    home: "Domicile",
    if_no_budget_units_will_not_be_automatically_billed:
        "Sans budget, les unités ne seront pas facturées automatiquement chaque mois.",
    if_no_default_bank_account_units_will_not_automatically_pay:
        "Tant que vous n'avez pas défini un compte en banque par défaut, le payment automatique des unités n'est pas activé.",
    include: "Inclure:",
    income_statement: "Compte de résultat",
    income: "Revenus",
    incomplete: "Incomplet",
    informations: "Informations",
    insert_title: "Insérer un titre",
    insert_unit_link: "Insérer le lien de l'unité",
    insert_unit_number: "Insérer le numéro de l'unité",
    installation_date: "Date d'installation",
    insurance_about_to_expire_reminder: "Rappel pour assurance expirant bientôt",
    insurance_about_to_expire: "Assurance expirant bientôt",
    insurance_certificate: "Certificat",
    insurance_incomplete_reminder: "Rappel pour information incomplète sur l'assurance",
    insurance_info_incomplete: "Information incomplète sur l'assurance",
    insurance_provider: "Compagnie",
    insurance: "Assurance",
    insurance_end_date: "Date de fin",
    interest_rate: "Taux d'intérêt",
    interests_payed: "Payment d'intérêts",
    interests_received: "Versement d'intérêts",
    inv: "Fact.",
    invalid_value: "Valeur invalide",
    investment_account: "Compte d'investissement",
    invite_bulk_users: "Inviter un grand nombre d'utilisateurs",
    invited_as_ROLE: "Invité en tant que $role",
    invited_users: "Utilisateurs invités",
    invited: "Invité",
    inviting_users: "Invitation des utilisateurs en cours...",
    invoice: "Facture",
    invoiced: "Facturé",
    invoices: "Factures",
    invoicing_threshold: "Seuil de facturation : ",
    journal_of_transactions: "Journal des transactions",
    keep_balance_with_old_owner: "Gardez le solde avec l'ancien propriétaire",
    keyboard_shortcuts: "Raccourcis clavier",
    kind: "Type",
    last_name: "Nom de famille",
    late_payment_penalty: "Pénalité de retard de paiement",
    legal: "Légal",
    level: "Niveau",
    liabilities: "Passif",
    liability_insurance_coverage: "Couverture responsabilité",
    list_of_bank_accounts: "Liste des comptes en banque",
    list_of_checks: "Liste des chèques",
    list_of_co_owners_and_residents: "Liste des copropriétaires et locataires",
    list_of_lockers: "Liste des casiers",
    list_of_parkings: "Liste des Parkings",
    list_of_suppliers: "Liste des fournisseurs",
    list_of_units: "Liste des unités",
    loading_and_decrypting_bank_account_info:
        "Chargement et décryptage des informations bancaires...",
    loading_report: "Téléchargement du rapport",
    loading: "Chargement...",
    locker_k: "Casier $number",
    locker_number: "N° de casier",
    locker: "Casier",
    lockers: "Casiers",
    low_balance_threshold: "Solde bancaire d'alerte",
    man: "Homme",
    missing_budget: "Les frais de condo n'ont pas été budgétés",
    month: "Mois",
    monthly_fee: "Frais mensuels",
    monthly_fees: "Frais mensuels",
    monthly: "Une fois par mois",
    multiple: "Multiple",
    my_posts: "Mes annonces",
    NAME_operating_account: "$name (compte d'opération)",
    name: "Nom",
    never: "Jamais",
    new_bank: "Nouvelle banque",
    new_categories: "Nouveaux comptes",
    new_charge: "Nouveau frais",
    new_checks: "Nouveaux chèques",
    new_deposit: "Nouveau dépôt",
    new_locker: "Nouveau casier",
    new_parking: "Nouveau parking",
    new_penalty: "Nouvelle pénalité",
    new_person: "Nouvelle personne",
    new_post: "Nouvelle annonce",
    new_supplier: "Nouveau fournisseur",
    new_suppliers: "Nouveaux fournisseurs",
    new_transaction: "transaction",
    new_unit: "Nouvelle unité",
    no_data: "Pas de données",
    no_one: "Personne",
    no_time_zone_specified_please_contact_support:
        "Aucun fuseau horaire. Veuillez contacter le support.",
    no: "Non",
    none: "Aucun",
    notes: "Notes",
    number_of_checks: "Nombre de chèques",
    number_of_months_where_unit_has_unpaid_balance:
        "Nombre de mois depuis que l'unité a un solde impayé",
    number: "N°",
    once_budget_applied_cannot_be_changed: "Une fois appliqué, le budget ne peut être modifié",
    other: "Autre",
    otonom_id: "N° Otonom Solution",
    otonom_integration: "Activation d'Otonom",
    otonom_solution: "Otonom Solution",
    otonom: "Otonom",
    overview: "Aperçu",
    owned_units: "Unités détenues",
    owners_and_residents: "Propriétaires & locataires",
    owners_will_be_notified_by_email: "Les propriétaires seront notifiés par courriel.",
    owners: "Propriétaires",
    page_not_found: "Page introuvable",
    paid: "Payé",
    parking_k: "Parking $number",
    parking: "Parking",
    parkings: "Parkings",
    password: "Mot de passe",
    payment_day: "Jour de paiement",
    payment_method: "Mode de paiement",
    payment_status: "Statut du paiement",
    payment: "Paiement",
    payments: "Paiements",
    pdf: "PDF",
    penalties_and_fees: "Frais & Pénalités",
    penalties: "Pénalités",
    penalty_amount: "Montant de la pénalité",
    penalty: "Pénalité",
    pending_approval: "Approbation en attente",
    pending_transactions: "Transactions en attente",
    pending: "En attente",
    person: "Personne",
    phone_number: "N° de tél.",
    phone_type: "Type du téléphone",
    phone: "Tél",
    phones: "Téléphones",
    please_contact_support_as_soon_as_possible_to_get_help:
        "L'application ne peut pas fonctionner, veuillez contacter le support.",
    please_fill_the_form: "Veuillez compéter le formulaire",
    please_set_language_to_be_able_to_generate_reports:
        "Veuillez définir la langue afin de générer les rapports.",
    policy_number: "N° de police",
    posted_on: "Posté le",
    preferred_language: "Langue préférée",
    previously_generated_otonom_files: "Paiements Otonom Solution envoyés ces 30 derniers jours",
    profile: "Profil",
    profit_and_loss: "Compte de résultat",
    ready_to_send_to_otonom: "Prêt à envoyer à Otonom Solution",
    rec: "Rec.",
    recipients: "Destinataires",
    reconciled: "Concilié",
    recurring_bill: "+ Facture récurrente",
    recurring_bills: "Factures récurrentes",
    recurring_end_date: "Date de fin",
    register_check: "Enregistrer un chèque",
    reject: "Rejeter",
    rejected_by: "Rejeté par",
    rejected_by2: "Rejeté par :",
    rejected_transactions: "Transactions dont le paiement a été rejeté",
    rejected: "Rejeté",
    remaining_balance_processing: "Traitement de la balance restante",
    removed_amount: "Montant retiré",
    rented: "Loué",
    person_renting: "Loué par",
    report_language_not_specified: "Langue de facturation des frais de condo indéfinie.",
    reports: "Rapports",
    nudge_approval: "Envoyer un rappel",
    required_field_missing: "Il manque un champ obligatoire",
    resident: "Locataire",
    residential_units: "Unités résidentielles",
    residents: "Locataires",
    revenue_account: "Compte de recette",
    revenues: "Revenus",
    role: "Type",
    save: "Sauvegarder",
    saving_account: "Compte d'Épargne",
    saving: "Sauvegarde en cours...",
    search: "Rechercher",
    sections_to_include: "Sections à inclure",
    send_email_on_check_save: "Confirmer par courriel la réception de chèques",
    send_email_to_recipients: "Envoyer par courriel",
    send_email_to_units_with_unpaid_balance:
        "Envoyer un courriel de rappel pour frais de condo impayés.",
    send_to_otonom_for_NAME: "Payer $name",
    send_to_otonom: "Envoyer à Otonom Solution",
    send_unit_payments_to_otonom:
        "Cliquez sur le bouton ci-dessous pour envoyer à Otonom Solution le paiement des unités utilisant le virement automatique.",
    sending_to_otonom: "Envoi en cours à Otonom Solution...",
    sent_to_otonom: "envoyé à Otonom Solution",
    set_language: "Définir la langue",
    settings: "Réglages généraux",
    shortcuts: "Raccourcis",
    show_help: "Afficher l'aide",
    signing_out: "Déconnexion...",
    start_date: "Date de début",
    start_new_fiscal_year: "Commencer une nouvelle année fiscale",
    starting_balance: "Solde au début",
    state: "Province",
    status: "Status",
    sterling_pound: "Livre Sterling",
    street: "Rue",
    success: "Opération complétée avec succès",
    supplier_name: "Nom du fournisseur",
    supplier_payment: "Payment de fournisseurs",
    supplier_statement: "Relevé d'un fournisseur",
    supplier_transactions_ready_to_send_to_otonom:
        "Paiements de fournisseurs à envoyer à Otonom Solution",
    supplier: "Fournisseur",
    suppliers: "Fournisseurs",
    threshold_for_automatic_billing: "Seuil de facturation automatique",
    time_zone: "Fuseau horaire",
    title: "Titre",
    to_add_or_remove_units_and_parkings_please_contact_support:
        "Pour ajouter ou enlever une unité ou un parking, veuillez contacter le support.",
    to_enroll_unit_in_automatic_payment_enter_bank_account_details:
        "Afin d'activer le prélèvement automatique, veuillez renseigner les coordonnées bancaires du payeur.",
    to: "À",
    toggle_navigation: "Menu",
    total_assets: "TOTAL Actif",
    total_capital_and_liabilities: "TOTAL Capital & Passif",
    total_capital: "TOTAL Capital",
    total_expenses: "TOTAL Dépenses",
    total_k: "Total : $total",
    total_liabilities: "TOTAL Passif",
    total_revenues: "TOTAL Revenus",
    total: "Total",
    transaction: "Transaction",
    transactions_export: "Export des transactions",
    transactions: "Transactions",
    transfer_balance_to_new_owner: "Transférer le solde au nouveau propriétaire",
    transfer_to_new_owner_or_keep_on_old_owner:
        "Souhaitez-vous transférer ce solde au nouveau propriétaire ?",
    transfers: "Transferts",
    unit_k: "Unité $number",
    unit_NUMBER_sold: "Unité $number (vendue)",
    unit_number: "N° d'unité",
    unit_statement: "Relevé d'une unité",
    unit_transactions_ready_to_send_to_otonom: "Payment des unités à envoyer à Otonom",
    unit: "Unité",
    united_states_dollar: "Dollar américain",
    units_collection: "Encaissement venant des copropriétaires",
    units_paying_by_automatic_wire_transfers: "Unités payant par virement",
    units_with_unpaid_balance: "Unités avec un solde impayé",
    units_without_insurance: "Unités sans assurance",
    units: "Unités",
    unknown_error: "Erreur inconnue",
    unknown: "Inconnu",
    unpaid_balance_after_k_weeks: "Frais de condo impayés depuis $k weeks",
    unpaid_balance_reminder: "Rappel pour frais de condo impayés",
    update_bank_account: "Mettre à jour le compte en banque",
    update: "Modifier",
    upload_file: "Téléverser un fichier",
    upload: "Télécharger",
    uploading: "Téléchargement en cours...",
    users: "Utilisateurs",
    vehicle_license_plate: "N° d'immatriculation",
    vote_share_total_is_not_100: "La somme des droits de vote n'est pas égale à 100%.",
    vote_share: "% de vote",
    vote_shares: "Droits de vote",
    waiting_approval: "En attente d'approbation",
    water_heater_about_to_expire_reminder: "Rappel pour garantie du chauffe-eau expirant bientôt",
    water_heater_about_to_expire: "Garantie du chauffe-eau expirant bientôt",
    water_heater_incomplete_reminder: "Rappel pour information incomplète sur le chauffe-eau",
    water_heater_info_incomplete: "Informations incomplètes sur le chauffe-eau",
    water_heater_label: "Étiquette",
    water_heater: "Chauffe-eau",
    water_heaters_to_be_replaced: "Chauffe-eau à remplacer",
    weekly: "Une fois par semaine",
    wire_transfer: "Virement",
    wire_transfers: "Virements",
    woman: "Femme",
    work: "Travail",
    yearly: "Une fois par an",
    yes: "Oui",
    your_buildings: "Vos immeubles",
    zip_code: "Code postal",
} as const

export default core
