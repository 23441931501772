import { useAppTranslation } from "hooks/hooks"
import { TKey } from "i18n"
import React, { ReactNode } from "react"
import { DineroStorable } from "@appnflat-types/Common"
import { IconHelp } from "@tabler/icons-react"
import Tip from "components/custom/Tip"
import { LocalizedString } from "@appnflat-types/types"

/** The list of variants we have for inputs. */
type WVariants =
    /** The default theme as created by Mantine. */
    | "default"
    /** The theme used for pages with a lot of data entry. */
    | "data"
    /** The theme used in pop-ups. */
    | "popup"
    /**
     * The theme for inputs when we have multiple inputs in a single row.
     * The label should only be shown for the first row, above the inputs.
     * Errors are minimal (no text, only a red outline on the box). Descriptions
     * are not shown.
     */
    | "table"
    /** Identical to `table`. Only difference is that labels are shown. */
    | "table-first-row"
    /** Theme for a long field. */
    | "long"

/** The list of props that are overridden by WProps. */
export type MantinePropsToOmit = "label" | "description" | "placeholder" | "variant"

export type WProps<
    T,
    ExcludedVariants extends WVariants | "" = "",
    AdditionalVariants extends string = "",
> = Omit<T, MantinePropsToOmit> & {
    /** The label for the input. Will also specify `aria-label` */
    label?: TKey | LocalizedString
    /** Takes precedence over `label`. Will also specify `aria-label`. */
    preTranslatedLabel?: string
    /** Takes precedence over `label` and `preTranslatedLabel`. The `aria-label` will be `label`. */
    customLabel?: ReactNode
    /** If not specified, will be the same as label. */
    placeholder?: TKey | LocalizedString
    description?: TKey | LocalizedString
    /** Takes precedence over description. */
    preTranslatedDescription?: string
    /** Whether the input is editable or not. */
    editing?: boolean
    tip?: TKey | false
    variant?: Exclude<WVariants, ExcludedVariants> | AdditionalVariants
    hidden?: boolean
    disabled?: boolean
    /** Hide the input if it is disabled (i.e., can't be edited) and empty. */
    hideIfDisabledAndEmpty?: boolean
} & (
        | {
              value: string | number | readonly string[] | DineroStorable | null | undefined
          }
        | {
              checked?: boolean | undefined
          }
    )

type WPropsReturn<T> = Omit<
    WProps<T>,
    "editing" | "hideIfDisabledAndEmpty" | "customLabel" | "tip" | "label" | "disabled"
> & {
    hidden: boolean
    label: React.JSX.Element | undefined
    description: React.JSX.Element | undefined
    "aria-label": string | undefined
    disabled: boolean
    variant: WVariants | undefined
    placeholder: string | undefined
}

/**
 * @param defaultValue The default value to return when the value is `undefined` or `null`.
 */
export function useWProps<T>(p: WProps<T>, defaultValue: any = "") {
    const t = useAppTranslation()
    const {
        editing,
        disabled: _disabled,
        hideIfDisabledAndEmpty,
        customLabel,
        preTranslatedLabel,
        tip,
        label: _label,
        hidden: _hidden,
        variant: _variant,
        placeholder,
        description: _description,
        preTranslatedDescription,
        ...otherProps
    } = p
    const disabled = !editing || !!_disabled
    const value = ("value" in p ? p.value : defaultValue) ?? defaultValue

    const tooltip =
        tip ?
            <Tip tip={tip} active>
                <IconHelp role="graphics-symbol" />
            </Tip>
        :   undefined

    const labelText = preTranslatedLabel ?? (_label ? t(_label) : undefined)
    const description =
        preTranslatedDescription ? <span>{preTranslatedDescription}</span>
        : _description ? <span>t(_description)</span>
        : undefined
    const label =
        customLabel ?
            <>
                {customLabel} {tooltip}
            </>
        : labelText ?
            <>
                {labelText} {tooltip}
            </>
        :   undefined

    const hidden =
        _hidden ||
        (hideIfDisabledAndEmpty &&
            disabled &&
            (value === "" ||
                value === undefined ||
                value === null ||
                value === 0 ||
                (typeof value === "object" && "length" in value && !value.length)))

    return {
        ...otherProps,
        value,
        placeholder: labelText ?? (placeholder ? t(placeholder) : undefined),
        hidden,
        label,
        description,
        "aria-label": labelText ?? p["aria-label" as keyof typeof p],
        disabled,
        variant: _variant ?? "data",
    } as WPropsReturn<T>
}
