import { z } from "zod"
import { Role } from "../@constants/Role"
import { emailString, mediumString } from "./BaseStrings"
import { addOpenAPI } from "./zodExtensions"

export const invitedUserSchema = addOpenAPI(
    z.object({
        name: mediumString.optional(),
        email: emailString,
        role: z.nativeEnum(Role),
    }),
    "InvitedUser"
)
export type InvitedUser = z.infer<typeof invitedUserSchema>
