import { useNavigate } from "react-router"
import { useAppParams } from "./hooks"
import { useCallback } from "react"
import { useTransactionParties } from "./useStore"
import { BuildingGroup } from "@appnflat-types/BuildingGroup/BuildingGroup"

type PBBuilding = ["building", string, number]
type _PBBuildingGroup = ["building-group", BuildingGroup["id"]]
type PBBuildingGroup = _PBBuildingGroup | [...PBBuilding, ..._PBBuildingGroup]
type PBBuildingGroupOrBuilding = PBBuilding | PBBuildingGroup

export type AppURL =
    | ["signin" | "signup"]
    | ["dashboard"]
    | [...PBBuilding, "dashboard"]
    | [...PBBuilding, number, "dashboard"]
    | [
          ...PBBuilding,
          "units" | "banks" | "suppliers" | "categories" | "people",
          "info" | "transactions",
          string,
      ]
    | [...PBBuilding, "units", "penalties"]
    | ["account"]
    | [...PBBuilding, "account"]
    | [...PBBuildingGroup, "account"]
    | [...PBBuildingGroup, "admin", "building-group" | "users"]
    | [...PBBuildingGroup, "posts", "templates"]
    | [...PBBuildingGroupOrBuilding, "requests", "all" | "templates" | "tags"]
    | [
          ...PBBuilding,
          "payments",
          "checks" | "deposits" | "wire-transfers" | "approval" | "otonom" | "reports",
      ]
    | [...PBBuilding, "posts", "all" | "admin" | "my" | "reminders" | "emails" | "templates"]
    | [...PBBuilding, "admin", "building"]

/** Returns a URL to a valid spot within the app. */
export function useAppURL(params: AppURL): string
export function useAppURL(params: undefined | true): undefined
export function useAppURL(params: AppURL | undefined | true): string | undefined
export function useAppURL(params: AppURL | undefined | true): string | undefined {
    if (!params || params === true) return undefined
    return appURL(params)
}

/** Returns a URL to a valid spot within the app. */
export function appURL(url: AppURL): string {
    return `/${url.join("/")}`
}

/** Returns a function to navigate to a valid spot within the app. */
export function useOnClickNavigate(params: AppURL | true): () => void {
    const url = useAppURL(params)
    const navigate = useNavigate()
    const onClick = useCallback(() => url && navigate(url), [navigate, url])
    return onClick
}

/** Returns a function to generate a URL to an account. */
export function useAccountURLGenerator() {
    const { buildingRef, fiscalYear } = useAppParams()
    const parties = useTransactionParties("all-including-archived")
    return useCallback(
        (aid: string | undefined | null) => {
            if (!buildingRef || !fiscalYear || !aid) return undefined
            const party = parties.find((party) => party.value === aid)
            if (!party) return undefined
            return appURL([
                "building",
                buildingRef,
                fiscalYear,
                party.type,
                "transactions",
                party.uuid,
            ])
        },
        [buildingRef, fiscalYear, parties]
    )
}
