import { useState } from "react"
import { GET, requestHandler, FetchErrorTitle } from "server/wrappedCallers"
import { useDeepCompareEffect } from "./useDeepCompareEffect"
import { paths } from "openapi/backend"
import type { FetchResponse, MaybeOptionalInit } from "openapi-fetch"
import { LoadingStatus } from "./loading"
import { PathsGET } from "@appnflat-types/BackendOpenAPITypes"

type UseQueryMethod = <
    Path extends PathsGET,
    Init extends MaybeOptionalInit<paths[Path], "get">,
    Response extends Required<FetchResponse<paths[Path]["get"], Init, `${string}/${string}`>>,
    Options extends { errorTitle: FetchErrorTitle },
>(
    url: Path,
    options: Options,
    /** If `true`, we will not fetch */
    init: true | Init
) => Response["data"] | LoadingStatus

export const useGET: UseQueryMethod = function internalUseGET(url, options, ...[init]) {
    const [result, setResult] = useState<any>(LoadingStatus.loading)

    useDeepCompareEffect(() => {
        setResult(LoadingStatus.loading)
        if (init === true) return
        requestHandler({
            errorTitle: options.errorTitle,
            request: GET(url, init as any) as any,
            onResult: (r) => setResult(r),
            onError: () => setResult(LoadingStatus.error),
        })
    }, [url, init, options])

    return result
}
