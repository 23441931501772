import { useCollator, useAppParams, useAppSelector, useAppTranslation } from "hooks/hooks"
import { useMemo } from "react"
import {
    BuildingGroupWebCollections,
    BuildingWebCollections,
    WebCacheCollections,
} from "store/cacheHelpers"
import {
    cacheAidToCollectionSelector,
    cachedBuildingGroupSelector,
    cachedBuildingsSelector,
    cachedBuildingGroupsSelector,
    cachedCollectionSelector,
    cachedObjectSelector,
    StoreArchived,
    transactionPartiesSelector,
    TransactionPartiesSelectorCollection,
    transactionsSelector,
    cachedBuildingSelector,
    userDocsInBuildingGroupsSelector,
    userDocsInBuildingsSelector,
} from "store/cacheSelectors"
import get from "lodash/get"
import { Unit } from "@appnflat-types/Unit"
import { Person } from "@appnflat-types/Person"
import personName from "@shared/personName"
import { Supplier } from "@appnflat-types/Supplier"
import {
    BuildingCollection,
    BuildingGroupCollection,
    collectionToSchema,
    CollectionToType,
    RootCollection,
    TypeByCollection,
} from "@appnflat-types/Collection"
import { RequestTag } from "@appnflat-types/BuildingGroup/RequestTag"
import { Bank } from "@appnflat-types/Bank"
import { idForCollection, IdForCollectionInput } from "@shared/idForCollection"
import { RecursiveKeyOf, GetFieldType, RecursiveKeysOfUnion } from "@appnflat-types/helpers"
import { GlobalUser } from "@appnflat-types/GlobalUser"
import { BuildingUser } from "@appnflat-types/BuildingUser"
import { BuildingGroupUser } from "@appnflat-types/BuildingGroup/BuildingGroupUser"
import { WSelectOption } from "components/Inputs/WSelect"
import { Building } from "@appnflat-types/Building"
import { BuildingGroup } from "@appnflat-types/BuildingGroup/BuildingGroup"

/** Returns the current building. */
export function useBuilding() {
    const { buildingRef } = useAppParams()
    const building = useAppSelector((s) => cachedBuildingSelector(s, buildingRef))
    return building
}

/** Returns the current building group.
 *
 * If we are in a building, the building group will be inferred from the building.
 */
export function useBuildingGroup() {
    const { buildingGroupId } = useAppParams()
    const building = useBuilding()
    const id = buildingGroupId ?? building?.buildingGroupId
    const buildingGroup = useAppSelector((s) => cachedBuildingGroupSelector(s, id))
    return buildingGroup
}

/** Returns the currency of the current building. */
export function useCurrency() {
    const building = useBuilding()
    return building?.currency ?? "CAD"
}

/** Returns the user and the user in the current building. */
export function useUser(kind: "global"): GlobalUser | undefined
export function useUser(kind: "building"): BuildingUser | undefined
export function useUser(kind: "buildingGroup"): BuildingGroupUser | undefined
export function useUser(kind?: "all"): {
    globalUser: GlobalUser | undefined
    buildingUser: BuildingUser | undefined
    buildingGroupUser: BuildingGroupUser | undefined
}
export function useUser(kind: "global" | "building" | "buildingGroup" | "all" = "all") {
    const { buildingRef, buildingGroupId } = useAppParams()
    const globalUser = useAppSelector((state) => state.app.user)
    const userDocsInBuildings = useAppSelector(userDocsInBuildingsSelector)
    const userDocsInBuildingGroups = useAppSelector(userDocsInBuildingGroupsSelector)
    const buildingUser = useMemo(() => {
        if (!buildingRef) return undefined
        return userDocsInBuildings[buildingRef]
    }, [userDocsInBuildings, buildingRef])
    const buildingGroupUser = useMemo(() => {
        if (!buildingGroupId) return undefined
        return userDocsInBuildingGroups[buildingGroupId]
    }, [userDocsInBuildingGroups, buildingGroupId])
    if (kind === "global") return globalUser
    if (kind === "building") return buildingUser
    if (kind === "buildingGroup") return buildingGroupUser
    return { globalUser, buildingUser, buildingGroupUser }
}

/** Returns a property of the user. */
export function useUserProperty<K extends keyof BuildingUser | keyof GlobalUser>(property: K) {
    const user = useUser()
    return useMemo(() => {
        return get(user.globalUser, property) ?? get(user.buildingUser, property)
    }, [user, property])
}

/** Returns a list of objects from the cache.
 *
 * @param collection - The collection to return.
 * @param includeArchived - Whether to include or exclude archived objects.
 * @param fiscalYear - If specified, object that contain a fiscalYear field and that
 * are not from the given fiscal year will be filtered out.
 * @param root - The root to use to get the collection.
 * @param id - The id of the building or building group to use to get the collection. If `null`,
 * will be infered from `useAppParams`.
 */
export function useCollection<C extends "_" | (WebCacheCollections & BuildingWebCollections)>(
    collection: C,
    includeArchived?: StoreArchived,
    filterByFiscalYear?: boolean,
    root?: "buildings",
    id?: string | null
): CollectionToType<C>[]
export function useCollection<C extends "_" | (WebCacheCollections & BuildingGroupWebCollections)>(
    collection: C,
    includeArchived: StoreArchived,
    filterByFiscalYear: boolean,
    root: "buildingGroups",
    id?: string | null
): CollectionToType<C, "buildingGroups">[]
export function useCollection<C extends WebCacheCollections | "_">(
    collection: C,
    includeArchived: StoreArchived = StoreArchived.include,
    filterByFiscalYear: boolean = true,
    root: "buildings" | "buildingGroups" = "buildings",
    id: string | null = null
) {
    const { fiscalYear, buildingRef, buildingGroupId } = useAppParams()
    const localId = id ?? (root === "buildings" ? buildingRef : buildingGroupId)
    return useAppSelector((state) =>
        cachedCollectionSelector(
            state,
            root,
            localId,
            collection as any,
            includeArchived,
            filterByFiscalYear ? fiscalYear : undefined
        )
    )
}

type IdForCollectionInputFilter<C extends IdForCollectionInput["collection"]> =
    IdForCollectionInput extends infer O ?
        O extends { collection: infer CO } ?
            C extends CO ?
                O
            :   never
        :   never
    :   never

/** Returns an object from the cache by identifier.
 *
 * @param collection - The collection in which to look for the object.
 * @param idParams - The parameters to use to get the identifier. If `true`, will return
 * `undefined`.
 * @param root - The root to use to get the object.
 * @param id - The id of the building or building group to use to get the object. If `null`,
 * will be infered from `useAppParams`.
 */
export function useObject<C extends BuildingCollection>(
    collection: C,
    idParams: IdForCollectionInputFilter<C>["value"] | true,
    root?: "buildings",
    id?: string | null
): CollectionToType<C, "buildings"> | undefined
export function useObject<C extends BuildingGroupCollection>(
    collection: C,
    idParams: IdForCollectionInputFilter<C>["value"] | true,
    root: "buildingGroups",
    id?: string | null
): CollectionToType<C, "buildingGroups"> | undefined
export function useObject<
    C extends keyof (typeof collectionToSchema)[Root] & string,
    Root extends RootCollection = "buildings",
>(
    collection: C,
    idParams: IdForCollectionInputFilter<any>["value"] | true,
    root: Root = "buildings" as Root,
    id: string | null = null
) {
    const { buildingRef, buildingGroupId } = useAppParams()
    const localId = id ?? (root === "buildings" ? buildingRef : buildingGroupId)
    const docId = idParams === true ? null : idForCollection({ collection, value: idParams } as any)
    return useAppSelector((state) => cachedObjectSelector(state, root, localId, collection, docId))
}

/** Returns a field from an object from the cache by identifier.
 *
 * @param collection - The collection in which to look for the object.
 * @param idParams - The parameters to use to get the identifier. If `true`, will return
 * `undefined`.
 * @param field - The field to return from the object.
 */
export function useObjectField<
    C extends WebCacheCollections,
    F extends RecursiveKeysOfUnion<TypeByCollection[C]> & string,
>(collection: C, idParams: IdForCollectionInputFilter<C>["value"] | true, field: F) {
    const object = useObject(collection, idParams) as any
    return useMemo(() => {
        if (!object || !field) return undefined
        return get(object, field) as GetFieldType<TypeByCollection[C], F>
    }, [object, field])
}

/** Returns a list of transaction parties from the cache.
 *
 * @param collections - The collections to include.
 *   - `all` means all unarchived accounts (all accounts for which a new transaction can be created),
 *   - `all-including-archived` means all accounts (useful to display transaction with potentially archived accounts),
 *   - `bank-accounts` means excluding investment and saving accounts.
 *   - `banks-otonom` means bank accounts that can be used in Otonom transactions (currently, only
 *   the bank account specified as `defaultBankAccountAID` in the building doc).
 */
export function useTransactionParties(collections: TransactionPartiesSelectorCollection) {
    const { buildingRef, fiscalYear } = useAppParams()
    const collator = useCollator()
    const parties = useAppSelector((state) =>
        transactionPartiesSelector(state, buildingRef, collections, fiscalYear)
    )
    return useMemo(
        () => parties.sort((a, b) => collator.compare(a.value, b.value)),
        [parties, collator]
    )
}

type UseSelectOptionsCollections = (WebCacheCollections | "_") &
    ("units" | "suppliers" | "banks" | "people" | "requestTags" | "_")

/** Returns a preformatted list of objects to be used in WSelect or WMultiSelect.
 *
 * @param collection - The collection to return.
 * @param valueField - The field to use for the value of a select option. If left empty,
 * the field used will be `uuid`.
 * @param includeArchived - Whether to include or exclude archived objects. (default: include)
 * @param root - The root collection to use. (default: buildings)
 */
export function useSelectOptions<C extends Exclude<UseSelectOptionsCollections, "_">>(
    collection: C,
    valueField: RecursiveKeyOf<CollectionToType<C>>,
    includeArchived?: StoreArchived,
    root?: "all"
): (WSelectOption & { label: string })[]
export function useSelectOptions<C extends UseSelectOptionsCollections>(
    collection: C,
    valueField: RecursiveKeyOf<CollectionToType<C>>,
    includeArchived: StoreArchived,
    root: "buildings"
): (WSelectOption & { label: string })[]
export function useSelectOptions<C extends UseSelectOptionsCollections & ("_" | "requestTags")>(
    collection: C,
    valueField: RecursiveKeyOf<CollectionToType<C, "buildingGroups">>,
    includeArchived: StoreArchived,
    root: "buildingGroups"
): (WSelectOption & { label: string })[]
export function useSelectOptions<C extends UseSelectOptionsCollections>(
    collection: C,
    valueField: RecursiveKeyOf<CollectionToType<C>>,
    includeArchived?: StoreArchived,
    root?: "all" | "buildings" | "buildingGroups"
) {
    const objectsFromBuilding = useCollection(
        collection,
        includeArchived ?? StoreArchived.include,
        true,
        "buildings"
    )
    const objectsFromBuildingGroup = useCollection(
        collection as any,
        includeArchived ?? StoreArchived.include,
        true,
        "buildingGroups"
    )
    const t = useAppTranslation()
    const collator = useCollator()
    const labelGenerator = useMemo(
        () =>
            collection === "units" ?
                (obj: Unit) =>
                    obj.soldAndNeedToSetBalanceToZero || obj.archived ?
                        t("core:unit_NUMBER_sold", { $number: obj.aid })
                    :   t("core:unit_k", { $number: obj.number ?? obj.aid })
            : collection === "banks" ? (obj: Bank) => obj.name ?? obj.aid
            : collection === "suppliers" ? (obj: Supplier) => obj.name ?? obj.aid
            : collection === "people" ?
                (obj: Person) =>
                    obj.archived ?
                        t(T_$PERSON_ARCHIVED, { $person: personName(obj) })
                    :   personName(obj)
            : collection === "requestTags" ? (obj: RequestTag) => obj.name
            : collection === "_" ?
                root === "buildings" ? (obj: Building) => obj.name
                : root === "buildingGroups" ? (obj: BuildingGroup) => obj.name
                : (obj: { id?: string; uuid?: string; buildingRef?: string }) =>
                        obj.uuid ?? obj.id ?? obj.buildingRef ?? ""
            :   (obj: { uuid?: string; id?: string }) => obj.uuid ?? obj.id ?? "",
        [collection, root, t]
    )

    return useMemo(() => {
        if (!labelGenerator) return []
        return [
            ...(!root || root === "all" || root === "buildings" ? objectsFromBuilding : []),
            ...(!root || root === "all" || root === "buildingGroups" ?
                objectsFromBuildingGroup
            :   []),
        ]
            .map(
                (obj) =>
                    ({
                        value: get(obj, valueField) as unknown as string,
                        label: labelGenerator(obj as any),
                        color:
                            (
                                "color" in obj &&
                                typeof (obj as { color: unknown }).color === "string"
                            ) ?
                                (obj as { color: string }).color
                            :   undefined,
                    }) satisfies WSelectOption & { label: string }
            )
            .sort((a, b) => collator.compare(a.label, b.label))
    }, [labelGenerator, objectsFromBuilding, objectsFromBuildingGroup, valueField, root, collator])
}

const T_$PERSON_ARCHIVED = {
    en: "$person (archived)",
    fr: "$person (archivée)",
}

export function useAccountsInfo() {
    const { buildingRef } = useAppParams()
    return useAppSelector((state) => cacheAidToCollectionSelector(state, buildingRef))
}

export function useTransactions(includeCancelled: boolean) {
    const { buildingRef, fiscalYear } = useAppParams()
    return useAppSelector((state) =>
        transactionsSelector(state, buildingRef, fiscalYear, includeCancelled)
    )
}

export function useBuildings() {
    return useAppSelector((state) => cachedBuildingsSelector(state))
}

export function useBuildingGroups() {
    return useAppSelector((state) => cachedBuildingGroupsSelector(state))
}
