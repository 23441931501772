const messages = {
    this_penalty_applied_when_check_are_rejected:
        "- This penalty can be applied when a check has been rejected by the bank.\n\n- To record a returned check, go to the 'Payments/Deposits' section and click on 'Returned Check'.\n\n- You will then have the option to apply or not the penalty for returned check by checking the appropriate check box.",
    add_or_remove_unit_to_automatic_wire_transfer_in_unit_section:
        "To add or remove a csunit to the list of wire transfer payments, please visit the *Unit* section and activate / deactivate the option 'Automatic Deposit' for the unit you want to add / remove.",
    please_confirm_your_email_before_logging_in: "Please confirm your email before logging in",
    account_disabled: "Account has been disabled",
    institution_number_should_be_3_digits: "Institution number should be a 3 digit number",
    transit_number_should_be_5_digits: "Transit number should be a 5 digit number",
    account_number_should_be_5_to_20_digits: "Account number should be a 5 to 20 digit number",
    problem_changing_vote_shares: "There was a problem. Vote shares have not been modified.",
    error_changing_owners: "A problem occurred while changing the owner.",
    problem_cancelling_transaction: "A problem occurred while cancelling the transaction.",
    adding_or_removing_a_locker_is_very_unusual: " ADDING OR REMOVING A LOCKER IS VERY UNUSUAL.",
    adding_or_removing_a_parking_is_very_unusual: " ADDING OR REMOVING A PARKING IS VERY UNUSUAL.",
    adding_or_removing_a_unit_is_very_unusual: " ADDING OR REMOVING A UNIT IS VERY UNUSUAL.",
    adding_owners_or_residents_must_not_be_done_in_this_section_but_in_the_units_one:
        "Adding owners or tenants, managing parking spots and lockers, etc. must not be done in this section, but in the Units one.",
    all_suppliers_have_been_paid: "All suppliers have been paid.",
    all_units_are_up_to_date_with_their_payments: "All units are up to date with their payments",
    archive_this_bank_account: "Archive this bank account?",
    archive_this_supplier: "Archive this supplier?",
    assigning_lockers_to_units_must_not_be_done_on_this_screen:
        "Assigning lockers to units must not be done on this screen. If you have questions, contact our support.",
    assigning_parkings_to_units_must_not_be_done_on_this_screen:
        "Assigning parkings to units must not be done on this screen.",
    be_careful_changing_vote_shares_will_also_change_monthly_fees:
        "BE VERY CAREFUL: ALL CHANGES IN VOTE RIGHTS WILL MODIFY CONDO FEES FOR ALL UNITS",
    can_create_or_delete_units_parkings_lockers_here:
        "In this section, you can rename or delete lockers. YOU MAY ERASE IMPORTANT DATA.",
    change_vote_shares_question: "Do you really want to change the vote shares?",
    charges_explanation:
        "In this section, you can define all the charges of your co-ownership (key sales, room rental, etc.). Once defined, these charges will appear in the list of transactions that you can add to a unit in the *Units / Transactions* section.",
    check_registration_explanation:
        "- You can save the checks you receive before depositing them at the bank.\n\n- Each co-owner will be notified that you have received his payment if you have activated the corresponding option in the *Communication / Automatic Communications* section.\n\n- When you will make a bank deposit (*Deposits* section), you will be able to select the checks that you have already registered.\n\n- When you register checks you can do it in sequence. This will save you from having to enter them one by one. Check the corresponding option and specify the number of checks. The application will create the relevant checks.",
    check_reminder_explanation:
        "- By activating the option below, co-owners will be notified when you receive a check from them.\n\n- Each time you register a check in the *Payments / Checks* section, the concerned co-owner will receive an email detailing the number and amount of this check.",
    complex_transaction_display_explanation:
        "A positive amount indicates a credit to the account. A negative amount indicates a debit to the account.",
    create_a_new_fiscal_year: "Create a new fiscal year?",
    create_new_fiscal_year: "Create new fiscal year",
    creating_new_fiscal_year_please_wait_this_can_take_a_few_minutes:
        "Creating new fiscal year... Please wait, this can take a few minutes.",
    edit_person_in_unit_screen_explanation:
        "You cannot change the owners' or tenants' information in this section. To do so, please visit the *Owners and Tenants* section.",
    email_templates_explanation:
        "You can customize the emails that are automatically sent to co-owners. There are 5 emails that notify a co-owner when:\n\n1. He didn't pay his condo fees;\n\n2. His insurance is going to expire;\n\n3. The warranty on his water heater is going to expire;\n\n4. His insurance information is incomplete;\n\n5. His water heater information is incomplete\n\n- Once you have personalized your emails. Go to the *Automatic Emails* section to activate reminders and specify their frequency.",
    fiscal_year_explanation:
        "- In this section, you can start a new fiscal year and thus close a past fiscal year.\n\n- When you start a new fiscal year, you must specify the start date of this new fiscal year.\n\n- The data preceding the start of the new fiscal year will be archived and you can view them by clicking on the calendar icon at the bottom of the main navigation menu.\n\n- **You can no longer modify the data of an archived fiscal year. Before closing a fiscal year, make sure your financial statements are final.**",
    if_you_have_questions_contact_our_support: "If you have questions, contact our support.",
    insurance_reminder_explanation:
        "By activating the options below, co-owners will be notified when:\n\n1. Their insurance is about to expire;\n\n2. They have not completed the information relative to their insurance.\n\n- You can customize the email they will receive in the *Email Templates* section. ",
    minimum_unit_balance_before_charging_penalties_explanation:
        "- When automatic penalties are enabled, you can set a threshold below which penalties are not applied.\n\n- Thus a person of good faith whose debt is limited will not be penalized.",
    no_bank_accounts: "No Bank Account.",
    no_time_zone_specified_please_contact_support: "No time zone. Please contact support.",
    no_transactions_to_send: "There are no transaction to send to Otonom Solution.",
    no_units_pay_by_automatic_wire_transfer: "There is no owner paying by automatic wire transfer.",
    page_requested_not_found: "The page you requested could not be found. Please check the URL.",
    penalties_explanation:
        "In this section, you can define all the penalties of your co-ownership (cannabis, lack of insurance, etc.). Once defined, these penalties will appear in the list of transactions that you can add to a unit in the *Units / Transactions* section.",
    recurring_bills_explanation:
        "- When a provider bills you an identical amount each month (e.g. internet service provider) or when you pay monthly for a subscription, you can specify a recurring billing/payment.\n\n- The invoices/payments will then be created automatically every month. This will save you from having to manually enter all the invoices/payments one by one.",
    select_the_start_date_for_the_new_fiscal_year: "Select the start date for the new fiscal year.",
    select_the_users_you_want_to_invite_2: "Select the users you want to invite:",
    this_fiscal_year_is_archived_and_cannot_be_edited:
        "This fiscal year is archived and cannot be edited.",
    this_penalty_is_applied_when_a_co_owners_pays_late:
        "If you activate this penalty, late payment fees will apply when all three conditions are met:\n\n1. The owner has not paid his condo fees in full;\n\n2. Their debt is greater than the threshold you specify;\n\n3. The delay exceeds a grace period that you have specified by indicating the number of days during which a late payment does not trigger the billing of a penalty.",
    this_unit_does_not_have_any_owner: "This unit does not have any owner.",
    unit_help_message:
        "CONDO FEES\n\n- Condo fees cannot be changed in this section. Condo fees are calculated from your budget using each unit share (*Accounting / Budget* section). Therefore condo fees cannot be adjusted individually.\n\n---\n\nUNIT NUMBER - VOTE SHARE - ACCOUNTING ID\n\n- Unit Number, Vote Share or Accounting ID cannot be modified. Please contact our support if you want to change them.\n\n---\n\nAUTOMATIC DEPOSIT\n\n- To activate the automatic wire transfer, please enter the bank account information of the payer.",
    unknown_error_occurred: "An unknown error occurred.",
    unpaid_balance_reminder_explanation:
        "- By activating the option below, co-owners will be notified at the beginning of each month when they owe money to the syndicate.\n\n- You can customize the email they will receive in the *Email Templates* section.",
    water_heater_reminder_explanation:
        "By activating the options below, co-owners will be notified when:\n\n1. Their water heater warranty is about to expire;\n\n2. They have not completed the information relative to their water heater.\n\n- You can customize the email they will receive in the *Email Templates* section. ",
    will_change_monthly_fees: "Changing vote shares will change monthly fees.",
    you_are_up_to_date_with_your_payments: "You are up to date with your payments.",
    you_may_erase_important_data: "YOU MAY ERASE IMPORTANT DATA.",
    you_must_create_a_new_fiscal_year: "You must create a new fiscal year",
} as const

export default messages
