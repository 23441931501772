import React from "react"
import { TextInput, TextInputProps } from "@mantine/core"
import { WProps, useWProps } from "./WHelpers"

type WTextInputProps = WProps<TextInputProps>

/** A wrapper around TextInput. */
export function WTextInput(props: WTextInputProps) {
    const { hidden, ...otherProps } = useWProps<WTextInputProps>(props)
    if (hidden) return undefined

    return <TextInput {...otherProps} />
}
