import { z } from "zod"
import { Account, dayOfMonthSchema, dineroStorableSchema } from "./Common"
import { aidString, mediumString } from "./BaseStrings"
import { addOpenAPI } from "./zodExtensions"
import { ID_PREFIXES, idSchemaGenerator } from "./Id"

/** The id of a recurring bill. */
export const recurringBillIdSchema = idSchemaGenerator(ID_PREFIXES.RECURRING_BILL)

export const recurringBillSchema = addOpenAPI(
    z.object({
        description: mediumString.optional(),
        amount: dineroStorableSchema,
        paymentDay: dayOfMonthSchema.optional(),
        /** The aid of the counterpart (i.e., the bank account). */
        counterpart: aidString,
        billingDay: dayOfMonthSchema,
        /** The aid of the category. */
        category: aidString,
        endDate: z.number().optional(),
        paymentMethod: z.enum(["otonom", "wire-transfer", "pre-authorized-debit"]).optional(),
        /** The id of the recurring bill. */
        id: recurringBillIdSchema,
    }),
    "RecurringBill"
)
export type RecurringBill = z.infer<typeof recurringBillSchema>

/** An account with recurring bills.
 *
 * WARNING: When using this schema, ensure you are also using `accountWithRecurringBillsValidator`
 * to validate the data.
 */
export const accountWithRecurringBillsSchema = z.object({
    recurringBills: z.array(recurringBillSchema),
    /** The days in which there are recurring bills to pay or bill. */
    recurringBillsDays: z.array(dayOfMonthSchema),
})
/** An account WITHOUT recurring bills.  */
export const accountWithoutRecurringBillsSchema = z.object({
    recurringBills: z
        .union([z.array(recurringBillSchema).length(0), z.null().optional()])
        .optional(),
    /** The days in which there are recurring bills to pay or bill. */
    recurringBillsDays: z.union([z.array(z.number()).length(0), z.null().optional()]).optional(),
})
export type AccountWithRecurringBills = z.infer<typeof accountWithRecurringBillsSchema> & Account

/** Checks that if an account has a recurringBills field, it also has a valid recurringBillsDays field. */
export function accountWithRecurringBillsValidator(account: AccountWithRecurringBills | Account) {
    return (
        !("recurringBills" in account) ||
        account.recurringBills?.every(
            (bill) =>
                account.recurringBillsDays?.includes(bill.billingDay) &&
                (!bill.paymentDay || account.recurringBillsDays.includes(bill.paymentDay))
        )
    )
}
