import { z } from "zod"
import { emailString } from "../BaseStrings"
import { BuildingGroupUserRole } from "./BuildingGroupUser"
import { addOpenAPI } from "../zodExtensions"

/** The schema for a user that has been invited to a management company. */
export const buildingGroupInvitedUserSchema = addOpenAPI(
    z.object({
        /** The email of the user. */
        email: emailString,
        /** The role of the user. */
        role: z.nativeEnum(BuildingGroupUserRole),
    }),
    "BuildingGroupInvitedUser"
)
export type BuildingGroupInvitedUser = z.infer<typeof buildingGroupInvitedUserSchema>
