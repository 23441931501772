import { z } from "zod"
import { objectWithAttachmentsSchema } from "./Common"
import { mediumString, shortMarkdownString, uidString } from "./BaseStrings"
import { addOpenAPI, zodRegex } from "./zodExtensions"
import { postUUIDSchema } from "./Id"

/** The maximum number of attachments a post can have. */
export const MAX_ATTACHMENTS_POST = 10

const postRecipientSchema = zodRegex<
    "all" | "parkings" | "lockers" | "owners" | "residents" | `floor-${string}`
>(/^(all|parkings|lockers|owners|residents|floor-.+)$/)
export type PostRecipient = z.infer<typeof postRecipientSchema>

export const postSchema = addOpenAPI(
    objectWithAttachmentsSchema(MAX_ATTACHMENTS_POST).extend({
        uuid: postUUIDSchema,
        /** The date of the last edit. */
        lastModified: z.number().optional(),
        date: z.number(),
        author: z.union([uidString, z.literal("admin")]),
        authorName: mediumString.optional(),
        title: mediumString,
        body: shortMarkdownString,
        recipients: z.array(postRecipientSchema),
        /** Whether to send an email notifications to the post recipients. Can only be activated by admins. */
        sendEmail: z.boolean().optional(),
    }),
    "Post"
)
export type Post = z.infer<typeof postSchema>
