import { z } from "zod"
import { positiveDineroStorableSchema } from "./Common"
import { ParsingErrors } from "./parsingErrors"
import { aidString, mediumString, shortString, uuidString } from "./BaseStrings"
import { FilterByPropertyType } from "./helpers"
import { addOpenAPI } from "./zodExtensions"

/** The different kinds of default penalties.
 *
 * @see penaltyKindSchema for all possible kinds of penalties.
 *
 * Possible values:
 * - `defaultLatePaymentFee`: A late payment fee that is automatically applied to units that have not paid their fees on time.
 * - `defaultInterests`: An interest rate that is automatically applied to units that have not paid their fees on time.
 * - `defaultCheckReversalFee`: A fee that can be applied to units that have a check reversed.
 */
export const penaltyKindDefaultSchema = addOpenAPI(
    z.enum(["defaultLatePaymentFee", "defaultInterests", "defaultCheckReversalFee"]),
    "PenaltyKindDefault"
)
/** Penalty that is a default one (i.e., not a `customPenalty` or `customFee`). */
export type DefaultPenaltyKind = z.infer<typeof penaltyKindDefaultSchema>

/** The different kinds of custom penalties.
 *
 * @see penaltyKindSchema for all possible kinds of penalties.
 *
 * Possible values:
 * - `customPenalty`: A custom penalty fee, created by a user. It simply serves as a way to quickly create a transaction for units.
 * - `customFee`: A custom fee, created by a user. It simply serves as a way to quickly create a transaction for units.
 */
export const penaltyKindCustomSchema = addOpenAPI(
    z.enum(["customPenalty", "customFee"]),
    "PenaltyKindCustom"
)
/** Penalty that is a custom one (i.e., a `customPenalty` or `customFee`). */
export type CustomPenaltyKind = z.infer<typeof penaltyKindCustomSchema>

/** The different kinds of penalties.
 * Possible values:
 * - `customPenalty`: A custom penalty fee, created by a user. It simply serves as a way to quickly create a transaction for units.
 * - `customFee`: A custom fee, created by a user. It simply serves as a way to quickly create a transaction for units.
 * - `defaultLatePaymentFee`: A late payment fee that is automatically applied to units that have not paid their fees on time.
 * - `defaultInterests`: An interest rate that is automatically applied to units that have not paid their fees on time.
 * - `defaultCheckReversalFee`: A fee that can be applied to units that have a check reversed.
 */
export const penaltyKindSchema = addOpenAPI(
    z.enum([...penaltyKindCustomSchema.options, ...penaltyKindDefaultSchema.options]),
    "PenaltyKind"
)
export type PenaltyKind = z.infer<typeof penaltyKindSchema>

const penaltyBasisSchema = z.object({
    /** The date of the last edit. */
    lastModified: z.number().optional(),
    name: shortString.optional(),
    description: mediumString.optional(),
    /** The number of days of grace before charging units a penalty fee. */
    gracePeriod: z.number().int().gte(0).optional(),
    /** The aid of the category to which the penalty is added. */
    category: aidString.optional(),
})

/** A custom penalty or fee that can be applied to units. */
export const penaltyCustomSchema = penaltyBasisSchema.extend({
    uuid: uuidString,
    kind: penaltyKindSchema.extract(["customPenalty", "customFee"]),
    amount: positiveDineroStorableSchema.optional(),
})

/** A default penalty with an amount. */
export const penaltyDefaultWithAmountSchema = penaltyBasisSchema.extend({
    kind: penaltyKindSchema.extract(["defaultLatePaymentFee", "defaultCheckReversalFee"]),
    amount: positiveDineroStorableSchema.optional(),
})

/** A default penalty with a rate. */
export const penaltyDefaultWithRateSchema = penaltyBasisSchema.extend({
    kind: penaltyKindSchema.extract(["defaultInterests"]),
    /** The annual interest rate. */
    rate: z
        .number()
        .min(0, { message: ParsingErrors.must_be_positive })
        .max(1, { message: ParsingErrors.must_be_less_than_100 })
        .optional()
        .optional(),
})

export const penaltySchema = addOpenAPI(
    z.discriminatedUnion("kind", [
        penaltyDefaultWithRateSchema,
        penaltyDefaultWithAmountSchema,
        penaltyCustomSchema,
    ]),
    "Penalty"
)
export type Penalty = z.infer<typeof penaltySchema>
/** Penalty that is a default one (i.e., not a `customPenalty` or `customFee`). */
export type DefaultPenalty = FilterByPropertyType<
    Penalty,
    "kind",
    Exclude<PenaltyKind, CustomPenaltyKind>
>
/** Penalty that is a custom one (i.e., a `customPenalty` or `customFee`). */
export type CustomPenalty = FilterByPropertyType<Penalty, "kind", CustomPenaltyKind>
