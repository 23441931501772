import { z } from "zod"
import { uidString } from "../BaseStrings"
import { addOpenAPI } from "../zodExtensions"

/** The role of a user in a management company. */
export enum BuildingGroupUserRole {
    /** The user is an admin of the management company. */
    admin = "admin",
    /** The user is a handler of the management company. */
    handler = "handler",
    /** The user is an accountant of the management company. */
    accountant = "accountant",
    /** The user is an accountantWrite of the management company. */
    accountantWrite = "accountantWrite",
}

/** The schema for a user in a management company. */
export const buildingGroupUserSchema = addOpenAPI(
    z.object({
        /** The uid of the user. */
        uid: uidString,
        /** The role of the user. */
        role: z.nativeEnum(BuildingGroupUserRole),
    }),
    "BuildingGroupUser"
)
export type BuildingGroupUser = z.infer<typeof buildingGroupUserSchema>
