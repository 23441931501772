import React, { useCallback, useEffect, useState } from "react"
import { LoadingOverlay, Stack, Text, AppShell, Burger, Group, UnstyledButton } from "@mantine/core"
import "css/Administration.css"
import "css/Alerts.css"
import "css/CustomComponents.css"
import "css/Dashboard.css"
import "css/MainView.css"
import "css/People.css"
import "css/Reports.css"
import "css/TransactionsTable.css"
import "css/Transactions.css"
import "css/Units.css"
import "css/Communications.css"
import * as Sentry from "@sentry/react"
import { auth, db } from "firebaseSetup"
import { doc, onSnapshot } from "firebase/firestore"
import { dataLoadingStateSelector, loadingSelector, setUser } from "store/appState"
import {
    useAppDispatch,
    useAppParams,
    useAppSelector,
    useAppTranslation,
    useIsMobile,
} from "hooks/hooks"
import { useLanguage } from "hooks/useTranslate"
import { Component as SignIn } from "../Account/SignIn"
import { Outlet, useNavigate } from "react-router"
import { useFirestore } from "hooks/useFirestore"
import { emptyCache } from "store/cache"
import KeyboardShortcutsPopup from "./KeyboardShortcutsPopup"
import NavBarApp from "../NavBar/NavBarApp"
import PromptForceNewFY from "../Other/PromptForceNewFY"
import { Notifications } from "@mantine/notifications"
import { ModalsProvider } from "@mantine/modals"
import { DatesProvider } from "@mantine/dates"
import { Component as SecondaryNavBar } from "../NavBar/SecondaryNavBar"
import { RemoteConfigProvider } from "server/RemoteConfigProvider"
import { useDisclosure } from "@mantine/hooks"
import { IconAppnflatSmall } from "assets/svgs/IconAppnflatSmall"
import * as navbarClasses from "../NavBar/NavBarApp.module.css"
import { globalUserSchema } from "@appnflat-types/GlobalUser"
import { NoMobile } from "./NoMobile"
import { LoadingIcon } from "components/Other/LoadingIcon"
import ForcePaymentScreen from "./ForcePaymentScreen"

import { useUser } from "hooks/useStore"

/** DatePicker locales. */
import "dayjs/locale/fr"
import "dayjs/locale/en"

/*
 * Styles for Mantine.
 * NB: all components whose styles are imported below can only be used in the app, not on the static pages.
 */
import "@mantine/notifications/styles.layer.css"
import "@mantine/core/styles/ActionIcon.layer.css"
import "@mantine/core/styles/Alert.layer.css"
import "@mantine/core/styles/AppShell.layer.css"
import "@mantine/core/styles/Badge.layer.css"
import "@mantine/core/styles/Burger.layer.css"
import "@mantine/core/styles/Combobox.layer.css"
import "@mantine/core/styles/Divider.layer.css"
import "@mantine/core/styles/Indicator.layer.css"
import "@mantine/core/styles/Menu.layer.css"
import "@mantine/core/styles/Modal.layer.css"
import "@mantine/core/styles/ModalBase.layer.css"
import "@mantine/core/styles/Notification.layer.css"
import "@mantine/core/styles/PasswordInput.layer.css"
import "@mantine/core/styles/SegmentedControl.layer.css"
import "@mantine/core/styles/Tooltip.layer.css"
import { EmailString, UidString } from "@appnflat-types/BaseStrings"

export default function Component(__: { children: React.JSX.Element }) {
    // TODO: implement this for @shared/dates or @shared/formatter
    // Use the same locale format for all tests.
    // if (process.env.IS_TEST === "true") {
    // Settings.defaultLocale = "en-CA"
    // }
    const t = useAppTranslation(localTranslations)
    const user = useUser("global")
    const { buildingRef } = useAppParams()
    const dispatch = useAppDispatch()
    const loading = useAppSelector(loadingSelector)
    const loadingFirestore = useAppSelector(dataLoadingStateSelector)
    const language = useLanguage()
    const [navbarOpened, { toggle: toggleNavbar, close: closeNavbar }] = useDisclosure()
    const isMobile = useIsMobile()
    const navigate = useNavigate()
    const [buildingDataLoadAllowed, setBuildingDataLoadAllowed] = useState(false)
    useFirestore(buildingDataLoadAllowed)

    const cleanAppAfterLogout = useCallback(() => {
        dispatch(setUser(undefined))
        dispatch(emptyCache())
    }, [dispatch])

    useEffect(() => {
        try {
            const unsubscribe = auth.onIdTokenChanged((userAuth) => {
                if (!userAuth) {
                    cleanAppAfterLogout()
                    return
                }
                userAuth.getIdTokenResult().then((idToken) => {
                    if (!idToken || !userAuth.email) {
                        cleanAppAfterLogout()
                        return
                    }
                    dispatch(
                        setUser({
                            uid: userAuth.uid as UidString,
                            email: userAuth.email as EmailString,
                        })
                    )
                })
            })
            return unsubscribe
        } catch (error) {
            console.error("Error while setting up onIdTokenChanged", error)
        }
    }, [t, dispatch, cleanAppAfterLogout])

    /* Fetch globalUser doc from Firestore. */
    useEffect(() => {
        try {
            if (!user?.uid) return
            const unsubscribe = onSnapshot(doc(db, `globalUsers/${user.uid}`), (doc) => {
                const data = globalUserSchema.safeParse(doc.data())
                if (!data.success || user.uid !== data.data.uid) return
                const newUser = { ...data.data, uid: user.uid, email: user.email }
                console.log("Fetched globalUser doc", newUser)
                dispatch(setUser(newUser))
            })
            return unsubscribe
        } catch (error) {
            console.error("Error while fetching globalUser doc", error)
        }
    }, [dispatch, user?.email, user?.uid])

    /* Fetch building docs from Firestore. */
    useEffect(() => {
        try {
            if (!user?.uid) dispatch(emptyCache())
        } catch (error) {
            console.error("Error while fetching building docs", error)
        }
    }, [dispatch, user?.uid])

    /* Set the currently viewed building. */
    useEffect(() => {
        Sentry.setTag("buildingRef", buildingRef ?? "ABSENT")
    }, [buildingRef])

    if (isMobile && process.env.NODE_ENV !== "development") return <NoMobile />

    const path = window.location.pathname.split("/")[1] ?? ""
    const outsideOfApp = !["building", "account", "dashboard", "building-group"].includes(path)
    const showLoadingFirestore = loadingFirestore && !outsideOfApp && !!user

    return (
        <RemoteConfigProvider>
            <ModalsProvider>
                <DatesProvider settings={{ locale: language }}>
                    <Notifications position="top-right" />
                    <LoadingOverlay
                        visible={loading.show || showLoadingFirestore}
                        zIndex={1000}
                        overlayProps={{ radius: "sm", blur: 4, pos: "fixed" }}
                        loaderProps={{
                            pos: "fixed",
                            children:
                                showLoadingFirestore ?
                                    <Stack align="center">
                                        <LoadingIcon icon="db" />
                                        <Text>{t("connectingToDB")}</Text>
                                    </Stack>
                                : loading.show ?
                                    <Stack align="center">
                                        <LoadingIcon icon={loading.icon} />
                                        <Text>{t(loading.tkey)}</Text>
                                    </Stack>
                                :   undefined,
                        }}
                    />
                    {outsideOfApp ?
                        <Outlet />
                    : user && user.uid ?
                        <AppShell
                            layout={isMobile ? "default" : "alt"}
                            header={{ height: 42, collapsed: false }}
                            navbar={{
                                width: 60,
                                breakpoint: "sm",
                                collapsed: { desktop: false, mobile: !navbarOpened },
                            }}
                            padding="md"
                        >
                            <PromptForceNewFY />
                            <ForcePaymentScreen
                                setBuildingDataLoadAllowed={setBuildingDataLoadAllowed}
                            />
                            <KeyboardShortcutsPopup />
                            <AppShell.Header p="0px" withBorder={false}>
                                {isMobile ?
                                    <Group justify="space-between">
                                        <UnstyledButton onClick={() => navigate("/dashboard")}>
                                            <IconAppnflatSmall
                                                width="50px"
                                                height="50px"
                                                isIcon={false}
                                            />
                                        </UnstyledButton>
                                        <SecondaryNavBar />
                                        <Burger
                                            opened={navbarOpened}
                                            onClick={toggleNavbar}
                                            hiddenFrom="sm"
                                            size="sm"
                                            className={navbarClasses.burger}
                                            aria-label={t("core:toggle_navigation")}
                                        />
                                    </Group>
                                :   <SecondaryNavBar />}
                            </AppShell.Header>
                            <AppShell.Navbar
                                p="0px"
                                style={{ overflowY: "scroll", backgroundColor: "var(--navbar-bg)" }}
                            >
                                <NavBarApp navbarOpened={navbarOpened} closeNavbar={closeNavbar} />
                            </AppShell.Navbar>
                            <AppShell.Main p="0px" pt={42} pl={isMobile ? 0 : 60}>
                                <Outlet />
                            </AppShell.Main>
                        </AppShell>
                    :   <SignIn />}
                </DatesProvider>
            </ModalsProvider>
        </RemoteConfigProvider>
    )
}
Component.displayName = "ProtectedRoute"

const localTranslations = {
    connectingToDB: {
        en: "Loading database...",
        fr: "Chargement de la base de données...",
    },
}
