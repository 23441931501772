import { z } from "zod"
import { fiscalYearSchema, objectWithMonthlyFeesSchema } from "./Common"
import { ParsingErrors } from "./parsingErrors"
import { shortString, veryShortString } from "./BaseStrings"
import { addOpenAPI } from "./zodExtensions"
import { parkingUUIDSchema, personUUIDSchema, unitUUIDSchema } from "./Id"

export const parkingSchema = addOpenAPI(
    objectWithMonthlyFeesSchema.extend({
        uuid: parkingUUIDSchema,
        fiscalYear: fiscalYearSchema,
        archived: z.boolean().optional(),
        /** The date of the last edit. */
        lastModified: z.number().optional(),
        number: veryShortString.optional(),
        /** Details about who is renting the parking. */
        renter: z
            .object({
                /** The uuid of the person renting the parking. */
                person: personUUIDSchema.optional(),
                /** The uuid of the unit renting the parking. */
                unit: unitUUIDSchema.optional(),
            })
            .optional(),
        voteShare: z
            .number()
            .min(0, { message: ParsingErrors.must_be_positive })
            .max(1, { message: ParsingErrors.must_be_less_than_100 })
            .optional(),
        cadastreNumber: shortString.optional(),
        licensePlate: veryShortString.optional(),
    }),
    "Parking"
)
export type Parking = z.infer<typeof parkingSchema>
