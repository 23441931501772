/***************************************************
 * A set of utility functions for arrays.
 ***************************************************/
// @ts-ignore
import { deepEqual } from "fast-equals"
import { DateTime } from "./dates"
import personName from "./personName"
import { Unit } from "../@appnflat-types/Unit"
import { Person } from "../@appnflat-types/Person"
import { calculateBalance } from "./calculateBalance"
import { Account } from "../@appnflat-types/Common"
import { Currency } from "dinero.js"
import { DeepPartial, RecursiveKeyOf } from "../@appnflat-types/helpers"
import { get } from "./objects"

/***************************************************
 * SORTING
 ***************************************************/

/** Sorts an array of objects by their `aid` property. */
export function sortByAIDGenerator(collator: Intl.Collator) {
    return function sortByAID(a: { aid: string }, b: { aid: string }) {
        return collator.compare(a.aid ?? "", b.aid ?? "")
    }
}

/** Sorts an array of objects by their `name` property. */
export function sortByNameGenerator(collator: Intl.Collator) {
    return function sortByName(a: { name?: string }, b: { name?: string }) {
        return collator.compare(a.name ?? "", b.name ?? "")
    }
}

/** Sorts an array of objects by their `date` property. */
export function sortByDate(a: { date?: number }, b: { date?: number }) {
    return (a.date ?? 0) - (b.date ?? 0)
}

type SimplePerson = Pick<Person, "firstName" | "lastName" | "uuid">
/** Sorts an array of people by their full name.
 *
 * Note: it is sorted with the format `lastName, firstName`.
 */
export function sortByPersonNameGenerator(collator: Intl.Collator) {
    return function sortByPersonName(a: SimplePerson, b: SimplePerson) {
        return collator.compare(personName(a, true), personName(b, true))
    }
}

/**
 * Sorts an array of objects by their balance.
 *
 * @param method The method used to calculate the balance (see {@link calculateBalance}).
 * @param sortOrder The sorting method. Default is `largeToSmall`.
 */
export function sortByBalance(
    currency: Currency,
    method: Parameters<typeof calculateBalance>[2],
    sortOrder: "largeToSmall" | "smallToLarge" = "largeToSmall"
) {
    const invert = sortOrder === "smallToLarge"
    return function _sortByBalance(
        a: Pick<Account, "startingBalance" | "credits" | "debits">,
        b: Pick<Account, "startingBalance" | "credits" | "debits">
    ) {
        const diff =
            calculateBalance(b, currency, method).toUnit() -
            calculateBalance(a, currency, method).toUnit()
        if (invert) return diff * -1
        else return diff
    }
}

/***************************************************
 * FILTERING
 ***************************************************/
/** Removes all undefined values from an array. */
export function filterUndefined<T>(array: T[]): Exclude<T, undefined>[] {
    return array.filter((value): value is Exclude<T, undefined> => value !== undefined)
}

/** Removes all undefined, false, or null values from an array. */
export function filterNullUndefinedFalse<T>(array: T[]): Exclude<T, undefined | false | null>[] {
    return array.filter(
        (value): value is Exclude<T, undefined | false | null> =>
            value !== undefined && value !== false && value !== null
    )
}

/** Removes all falsy values from an array. */
export function filterFalsy<T>(array: T[]): Exclude<T, undefined | false | null | "" | 0>[] {
    return array.filter((value): value is Exclude<T, undefined | false | null | "" | 0> => !!value)
}

/** Keeps only units without a valid insurance. */
export function filterUnitsWithoutInsurance(
    unit: DeepPartial<Pick<Unit, "insurance" | "archived">> | undefined
) {
    return (
        !!unit &&
        !unit.archived &&
        (!unit.insurance ||
            !unit.insurance.attachment ||
            !unit.insurance.endDate ||
            unit.insurance.endDate < new DateTime("now").toSeconds() ||
            !unit.insurance.policyNumber ||
            !unit.insurance.insuranceProviderName ||
            (!unit.insurance.civilResponsabilityAmount &&
                unit.insurance.civilResponsabilityAmount !== 0))
    )
}

/** Keeps only units without a valid water heater. */
export function filterUnitsWithoutWaterHeater(
    unit: DeepPartial<Pick<Unit, "waterHeater" | "archived">> | undefined
) {
    return (
        !!unit &&
        !unit.archived &&
        (!unit.waterHeater ||
            !unit.waterHeater.attachment ||
            !unit.waterHeater.endOfWaranty ||
            unit.waterHeater.endOfWaranty < new DateTime("now").toSeconds() ||
            !unit.waterHeater.installationDate ||
            unit.waterHeater.installationDate > new DateTime("now").toSeconds())
    )
}

/** Filters out archived objects. */
export function filterArchived(object: { archived?: boolean }) {
    return !object.archived
}

/***************************************************
 * OTHER
 ***************************************************/

/** Verifies if two arrays are different or not. */
export function arraysDifferent<T extends string | number>(
    listA: T[] | undefined,
    listB: T[] | undefined
) {
    const clonedA = [...(listA ?? [])].sort()
    const clonedB = [...(listB ?? [])].sort()
    return !deepEqual(clonedA, clonedB)
}

/**
 * Returns an array of integers starting at `Math.floor(from)` and ending with
 * (or without if `excludeLast === true`) `Math.floor(to)`.
 *
 * @param excludeLast - Whether the last value should be excluded.
 * @example
 * arrayOfNumbers(5, 10) // => [5, 6, 7, 8, 9, 10]
 * @example
 * arrayOfNumbers(5, 10, true) // => [5, 6, 7, 8, 9]
 * @example
 * arrayOfNumbers(5.1, 10) // => [5, 6, 7, 8, 9, 10]
 * @example
 * arrayOfNumbers(5.9, 10.8) // => [5, 6, 7, 8, 9, 10]
 */
export function arrayOfIntegers(from: number, to: number, excludeLast?: boolean) {
    const [min, max] = [Math.floor(from), Math.floor(to)]
    return Array.from({ length: (excludeLast ? max - 1 : max) - min + 1 }, (__, i) => min + i)
}

/**
 * Given an array, returns an array of arrays, where each of those arrays has at most `batchSize`
 * elements.
 */
export function batchArray<T>(array: T[], batchSize: number): T[][] {
    return Array.from({ length: Math.ceil(array.length / batchSize) }, (__, i) =>
        array.slice(i * batchSize, (i + 1) * batchSize)
    )
}

/** Returns an array where each element is unique according to the given property. */
export function uniqBy<T extends object>(arr: T[], field: RecursiveKeyOf<T>): T[] {
    const test = (item: T) => get(item, field)
    return arr.filter((x, i, self) => i === self.findIndex((y) => test(x) === test(y)))
}

export function uniq<T>(arr: T[] | undefined | null): T[] {
    if (!arr) return []
    return arr.filter((x, i, self) => self.findIndex((s) => deepEqual(s, x)) === i)
}

/** Returns the smallest value in an array that is greater than the given value.
 * If none is found, returns `Infinity`.
 *
 * @example
 * ```ts
 * minGreaterThan([1, 2, 3, 4, 5], 3) // => 4
 * minGreaterThan([1, 2, 3, 4, 5], 3.5) // => 4
 * ```
 */
export function minGreaterThan(arr: number[], value: number) {
    return arr.reduce((prev, curr) => (curr > value && curr < prev ? curr : prev), Infinity)
}

/** Returns the largest value in an array that is smaller than the given value.
 * If none is found, returns `-Infinity`.
 *
 * @example
 * ```ts
 * maxLessThan([1, 2, 3, 4, 5], 3) // => 2
 * maxLessThan([1, 2, 3, 4, 5], 3.5) // => 3
 * ```
 */
export function maxLessThan(arr: number[], value: number) {
    return arr.reduce((prev, curr) => (curr < value && curr > prev ? curr : prev), -Infinity)
}

/** Returns the smallest value in an array that is greater than or equal to the given value.
 * If none is found, returns `Infinity`.
 *
 * @example
 * ```ts
 * largestValueSmallerOrEqualTo([1, 2, 3, 4, 5], 3) // => 3
 * largestValueSmallerOrEqualTo([1, 2, 3, 4, 5], 3.5) // => 3
 * ```
 */
export function maxLessThanOrEqual(arr: number[], value: number) {
    return arr.reduce((prev, curr) => (curr <= value && curr > prev ? curr : prev), -Infinity)
}

/** Pads an array to the given length.
 * If the array is already longer than the given length, it is returned as is.
 *
 * @example
 * ```ts
 * pad([1, 2, 3], 5, 0) // => [1, 2, 3, 0, 0]
 * ```
 */
export function pad<T>(array: T[], length: number, fill: T) {
    return array.length >= length ?
            array
        :   [...array, ...Array.from({ length: length - array.length }, () => fill)]
}

/** Returns the most frequent value in an array. */
export function mode<T>(array: T[]): T | undefined {
    // If the array is empty, return undefined. Otherwise, the following code will throw a TypeError.
    if (array.length === 0) return undefined
    const counts = new Map<T, number>()
    array.forEach((value) => counts.set(value, (counts.get(value) ?? 0) + 1))
    return array.reduce((prev, curr) =>
        (counts.get(curr) ?? 0) > (counts.get(prev) ?? 0) ? curr : prev
    )
}

/** Returns true if two arrays have the same elements.
 *
 * __Warning:__ we do not guarantee that the elements are present in the same order
 * or the same number of times.
 */
export function haveSameElements<T>(a: T[], b: T[]) {
    return a.length === b.length && a.every((value) => b.some((v) => deepEqual(value, v)))
}
