import { z } from "zod"
import { buildingGroupIdSchema } from "../Id"

/******************************************************************************
 * Definitions of the paths.
 ******************************************************************************/
/** The URL of the public bucket. */
const PublicBucketURL = "https://public.bucket.appnflat.com"

/** Zod schema for building group logo URL */
export const s3URLBuildingGroupLogoSchema = z.tuple([
    z.literal(PublicBucketURL),
    z.literal("buildingGroups"),
    buildingGroupIdSchema,
    z.union([z.literal("logo.svg"), z.literal("logo.webp")]),
])
/** The URL of the logo of a building group. */
export type S3URLBuildingGroupLogo = z.infer<typeof s3URLBuildingGroupLogoSchema>

/******************************************************************************
 * All paths exports.
 ******************************************************************************/
/** All the paths in the S3 bucket.
 *
 * They are represented as a tuple where:
 * - The first element is the bucket URL.
 * - The last element is the file name with the extension.
 * - The other elements are the path to the file.
 *
 * @example
 * ```ts
 * const path: S3Path = ["https://public.bucket.appnflat.com", "buildingGroups", "bg_123", "logo.svg"]
 * ```
 */
export const s3PathsSchema = s3URLBuildingGroupLogoSchema

/** All the file paths in the S3 bucket.
 *
 * They are represented as a tuple where:
 * - The first element is the bucket URL.
 * - The last element is the file name with the extension.
 * - The other elements are the path to the file.
 *
 * @example
 * ```ts
 * const path: S3Path = ["https://public.bucket.appnflat.com", "buildingGroups", "bg_123", "logo.svg"]
 * ```
 */
export type S3Path = z.infer<typeof s3PathsSchema>
