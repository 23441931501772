import { z } from "zod"
import { fiscalYearSchema, objectWithMonthlyFeesSchema } from "./Common"
import { ParsingErrors } from "./parsingErrors"
import { addOpenAPI } from "./zodExtensions"
import { lockerUUIDSchema } from "./Id"

export const lockerSchema = addOpenAPI(
    objectWithMonthlyFeesSchema.extend({
        uuid: lockerUUIDSchema,
        fiscalYear: fiscalYearSchema,
        archived: z.boolean().optional(),
        /** The date of the last edit. */
        lastModified: z.number().optional(),
        number: z.string().optional(),
        voteShare: z
            .number()
            .min(0, { message: ParsingErrors.must_be_positive })
            .max(1, { message: ParsingErrors.must_be_less_than_100 })
            .optional(),
        cadastreNumber: z.string().optional(),
    }),
    "Locker"
)
export type Locker = z.infer<typeof lockerSchema>
