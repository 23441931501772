import React from "react"
import { useNavigate } from "react-router"
import {
    useAppParams,
    useAppTranslation,
    useFeatureFlags,
    useIsMobile,
    usePermissions,
} from "hooks/hooks"
import { TKey } from "i18n"
import { Button, Menu, Tabs } from "@mantine/core"
import { filterNullUndefinedFalse } from "@shared/arrays"
import * as classes from "./SecondaryNavBar.module.css"
import {
    IconBuilding,
    IconBuildingBank,
    IconCalendarTime,
    IconCash,
    IconChecklist,
    IconChevronDown,
    IconClock,
    IconCoins,
    IconDiscountCheck,
    IconDoor,
    IconFile,
    IconFileChart,
    IconFileDollar,
    IconGavel,
    IconInvoice,
    IconLocationDollar,
    IconLock,
    IconMail,
    IconMoneybag,
    IconNumbers,
    IconReportAnalytics,
    IconTag,
    IconTemplate,
    IconTool,
    IconTransfer,
    IconUser,
    IconUserCog,
    IconUsers,
    IconUsersGroup,
} from "@tabler/icons-react"
import "@mantine/core/styles/Tabs.layer.css"
import { appURL } from "hooks/useAppURL"
import { LocalizedString } from "@appnflat-types/types"

export function Component() {
    const t = useAppTranslation()
    const {
        buildingGroupId,
        buildingRef,
        fiscalYear,
        unitUUID,
        supplierUUID,
        bankUUID,
        categoryUUID,
        personUUID,
        lockerUUID,
    } = useAppParams()
    const featureFlags = useFeatureFlags()
    const { isAdmin, isAccountant, hasFinancialAccessRead, canAccessUnitTransactions } =
        usePermissions()
    const isMobile = useIsMobile()
    const navigate = useNavigate()
    const { root, pathSegment, selectedValue } = usePathSegment()

    let options: {
        label: TKey | LocalizedString
        path: string
        Icon: typeof IconBuilding
    }[] = []

    if (root === "building") {
        const segmentToBuildingPath = (...other: [string, ...(string | undefined)[]]) =>
            `/building/${filterNullUndefinedFalse([buildingRef, fiscalYear, pathSegment, ...other]).join("/")}`
        switch (pathSegment) {
            case "requests":
                options = [
                    {
                        label: "core:requests",
                        path: segmentToBuildingPath("all"),
                        Icon: IconFile,
                    },
                    {
                        label: "core:request_email_templates",
                        path: segmentToBuildingPath("templates"),
                        Icon: IconTemplate,
                    },
                ]
                break
            case "units":
                options = [
                    ...(canAccessUnitTransactions ?
                        [
                            {
                                label: "core:transactions" as const,
                                path: segmentToBuildingPath("transactions", unitUUID),
                                Icon: IconCoins,
                            },
                        ]
                    :   []),
                    {
                        label: "core:unit",
                        path: segmentToBuildingPath("info", unitUUID),
                        Icon: IconDoor,
                    },
                    ...(isAdmin || isAccountant ?
                        [
                            {
                                label: "core:penalties_and_fees" as const,
                                path: segmentToBuildingPath("penalties"),
                                Icon: IconGavel,
                            },
                            {
                                label: "core:reports" as const,
                                path: segmentToBuildingPath("reports"),
                                Icon: IconFile,
                            },
                        ]
                    :   []),
                ]
                break
            case "people":
                options = [
                    {
                        label: "core:owners_and_residents",
                        path: segmentToBuildingPath("info", personUUID),
                        Icon: IconUsers,
                    },
                    ...(isAdmin || isAccountant ?
                        [
                            {
                                label: "core:reports" as const,
                                path: segmentToBuildingPath("reports"),
                                Icon: IconFile,
                            },
                        ]
                    :   []),
                ]
                break
            case "suppliers":
                options = [
                    {
                        label: "core:transactions",
                        path: segmentToBuildingPath("transactions", supplierUUID),
                        Icon: IconCoins,
                    },
                    {
                        label: "core:suppliers",
                        path: segmentToBuildingPath("info", supplierUUID),
                        Icon: IconTool,
                    },
                    {
                        label: "core:invoices",
                        path: segmentToBuildingPath("invoices", supplierUUID),
                        Icon: IconInvoice,
                    },
                    ...(isAdmin || isAccountant ?
                        [
                            {
                                label: "core:reports" as const,
                                path: segmentToBuildingPath("reports"),
                                Icon: IconFile,
                            },
                        ]
                    :   []),
                ]
                break
            case "banks":
                options = [
                    {
                        label: "core:bank_statement",
                        path: segmentToBuildingPath("transactions", bankUUID),
                        Icon: IconFileDollar,
                    },
                    {
                        label: "core:bank_accounts",
                        path: segmentToBuildingPath("info", bankUUID),
                        Icon: IconBuildingBank,
                    },
                    ...(hasFinancialAccessRead ?
                        [
                            {
                                label: "core:bank_reconciliation" as const,
                                path: segmentToBuildingPath("reconciliation"),
                                Icon: IconChecklist,
                            },
                        ]
                    :   []),
                    ...(isAdmin || isAccountant ?
                        [
                            {
                                label: "core:reports" as const,
                                path: segmentToBuildingPath("reports"),
                                Icon: IconFile,
                            },
                        ]
                    :   []),
                ]
                break
            case "categories":
                options = [
                    {
                        label: "core:transactions",
                        path: segmentToBuildingPath("transactions", categoryUUID),
                        Icon: IconCoins,
                    },
                    {
                        label: "core:accounting_categories",
                        path: segmentToBuildingPath("info", categoryUUID),
                        Icon: IconTag,
                    },
                    {
                        label: "core:budget",
                        path: segmentToBuildingPath("budget"),
                        Icon: IconReportAnalytics,
                    },
                    ...(hasFinancialAccessRead ?
                        [
                            {
                                label: "core:fiscal_year" as const,
                                path: segmentToBuildingPath("fiscal-year"),
                                Icon: IconCalendarTime,
                            },
                        ]
                    :   []),
                    ...(isAdmin || isAccountant ?
                        [
                            {
                                label: "core:accountant_writings" as const,
                                path: segmentToBuildingPath("accountant-writings"),
                                Icon: IconNumbers,
                            },
                            {
                                label: "core:financial_statements" as const,
                                path: segmentToBuildingPath("financial-statement"),
                                Icon: IconFileChart,
                            },
                            {
                                label: "core:reports" as const,
                                path: segmentToBuildingPath("reports"),
                                Icon: IconFile,
                            },
                        ]
                    :   []),
                ]
                break
            case "admin":
                options = [
                    {
                        label: "core:building",
                        path: segmentToBuildingPath("building"),
                        Icon: IconBuilding,
                    },
                    ...(isAdmin ?
                        [
                            {
                                label: "core:users" as const,
                                path: segmentToBuildingPath("users"),
                                Icon: IconUsersGroup,
                            },
                        ]
                    :   []),
                    {
                        label: "core:lockers",
                        path: segmentToBuildingPath("lockers", lockerUUID),
                        Icon: IconLock,
                    },
                ]
                break
            case "posts":
                options = [
                    {
                        label: "core:all_posts",
                        path: segmentToBuildingPath("all"),
                        Icon: IconUsersGroup,
                    },
                    {
                        label: "core:administration",
                        path: segmentToBuildingPath("admin"),
                        Icon: IconUserCog,
                    },
                    { label: "core:my_posts", path: segmentToBuildingPath("my"), Icon: IconUser },
                    ...(isAdmin ?
                        [
                            {
                                label: "core:automatic_communications" as const,
                                path: segmentToBuildingPath("reminders"),
                                Icon: IconClock,
                            },
                            {
                                label: "core:email_templates" as const,
                                path: segmentToBuildingPath("emails"),
                                Icon: IconMail,
                            },
                        ]
                    :   []),
                    {
                        label: "core:post_templates",
                        path: segmentToBuildingPath("templates"),
                        Icon: IconTemplate,
                    },
                ]
                break
            case "payments":
                options = [
                    { label: "core:checks", path: segmentToBuildingPath("checks"), Icon: IconCash },
                    {
                        label: "core:deposits",
                        path: segmentToBuildingPath("deposits"),
                        Icon: IconMoneybag,
                    },
                    {
                        label: "core:transfers",
                        path: segmentToBuildingPath("wire-transfers"),
                        Icon: IconLocationDollar,
                    },
                    ...(isAdmin && featureFlags?.approval ?
                        [
                            {
                                label: "core:approval" as const,
                                path: segmentToBuildingPath("approval"),
                                Icon: IconDiscountCheck,
                            },
                        ]
                    :   []),
                    ...(featureFlags?.otonom ?
                        [
                            {
                                label: "core:otonom_solution" as const,
                                path: segmentToBuildingPath("otonom"),
                                Icon: IconTransfer,
                            },
                        ]
                    :   []),
                    ...(isAdmin || isAccountant ?
                        [
                            {
                                label: "core:reports" as const,
                                path: segmentToBuildingPath("reports"),
                                Icon: IconFile,
                            },
                        ]
                    :   []),
                ]
                break
            default:
                return undefined
        }
    } else if (root === "building-group") {
        if (!buildingGroupId) return undefined
        switch (pathSegment) {
            case "posts":
                options = [
                    {
                        label: "core:post_templates",
                        path: appURL(["building-group", buildingGroupId, "posts", "templates"]),
                        Icon: IconTemplate,
                    },
                ]
                break
            case "requests":
                options = [
                    {
                        label: "core:requests",
                        path: appURL(["building-group", buildingGroupId, "requests", "all"]),
                        Icon: IconFile,
                    },
                    {
                        label: "core:request_email_templates",
                        path: appURL(["building-group", buildingGroupId, "requests", "templates"]),
                        Icon: IconTemplate,
                    },
                    {
                        label: "core:request_tags",
                        path: appURL(["building-group", buildingGroupId, "requests", "tags"]),
                        Icon: IconTag,
                    },
                ]
                break
            case "admin":
                options = [
                    {
                        label: "core:building_group",
                        path: appURL([
                            "building-group",
                            buildingGroupId,
                            "admin",
                            "building-group",
                        ]),
                        Icon: IconBuilding,
                    },
                    {
                        label: "core:users",
                        path: appURL(["building-group", buildingGroupId, "admin", "users"]),
                        Icon: IconUsers,
                    },
                ]
                break
            default:
                return undefined
        }
    } else {
        return null
    }

    if (isMobile)
        return (
            <Menu>
                <Menu.Target>
                    <Button leftSection={<IconChevronDown />}>
                        {t(options.find((o) => o.path === selectedValue)?.label ?? "")}
                    </Button>
                </Menu.Target>
                <Menu.Dropdown>
                    {options.map(({ path, Icon, label }) => (
                        <Menu.Item
                            classNames={{ itemLabel: classes.itemLabel }}
                            key={path}
                            onClick={() => navigate(path)}
                            data-active={path === selectedValue || undefined}
                        >
                            <Icon width={15} height={15} />
                            {t(label)}
                        </Menu.Item>
                    ))}
                </Menu.Dropdown>
            </Menu>
        )
    else
        return (
            <Tabs
                value={selectedValue}
                onChange={(v) => navigate(v ?? "")}
                classNames={{ ...classes, root: "secondary-navbar-container" }}
                variant="outline"
            >
                <Tabs.List justify="center">
                    {options.map(({ path, Icon, label }) => (
                        <Tabs.Tab
                            leftSection={<Icon width={15} height={15} />}
                            key={path}
                            value={path}
                        >
                            {t(label)}
                        </Tabs.Tab>
                    ))}
                </Tabs.List>
            </Tabs>
        )
}
Component.displayName = "SecondaryNavBar"

function usePathSegment() {
    const innerURL = window.location.toString().match(/https?:\/\/[^/?]+(\/.+)/)?.[1]
    if (!innerURL) return {}
    const root = innerURL.match(/^\/(building|building-group)\//)?.[1] as
        | "building"
        | "building-group"
        | undefined
    const pathSegment =
        innerURL.match(/^\/building\/[^/?]+\/[^/?]+\/([^/?]*|)/)?.[1] ??
        innerURL.match(/^\/building-group(?:\/([^/?]+)){2}/)?.[1]
    const selectedValue =
        innerURL.match(/^\/building-group(?:\/([^/?]+)){3}/)?.[0] ??
        innerURL.match(/^\/building(\/[^/?]+){4}(\/[^/?]*|)/)?.[0]
    return { root, pathSegment, selectedValue }
}
