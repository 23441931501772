import { Role } from "@constants/Role"
import {
    doc,
    query,
    onSnapshot,
    collection,
    collectionGroup,
    where,
    QueryFieldFilterConstraint,
} from "firebase/firestore"
import { db } from "firebaseSetup"
import { Collection, collectionToSchema, CollectionToType } from "@appnflat-types/Collection"
import {
    emptyCacheOfBuildingData,
    setBuildingFromServer,
    setBuildingGroupFromServer,
    setCollectionFromServer,
    setUserDocInBuildingGroups,
    setUserDocInBuildings,
    userDocsInBuildingGroupsSelector,
    userDocsInBuildingsSelector,
} from "store/cache"
import { buildingUserSchema } from "@appnflat-types/BuildingUser"
import { Building, buildingSchema } from "@appnflat-types/Building"
import { useAppDispatch, useAppParams, useAppSelector, usePermissions } from "./hooks"
import { setDataLoadingState } from "store/appState"
import { useEffect, useMemo, useState } from "react"
import { useBuilding, useUserProperty } from "./useStore"
import { objectEntries } from "@shared/objects"
import {
    BuildingGroupUserRole,
    buildingGroupUserSchema,
} from "@appnflat-types/BuildingGroup/BuildingGroupUser"
import { buildingGroupSchema } from "@appnflat-types/BuildingGroup/BuildingGroup"
import { useLocation } from "react-router"
import {
    AdditionalFilter,
    AdditionalFilterBuildingCollection,
    useFirestoreCollection,
    WebFirestorePathAndSchema,
} from "./useFirestoreCollection"
import { PermissionKey } from "./useHasPermission"
import { requestEmailTemplateSchema } from "@appnflat-types/BuildingGroup/RequestEmailTemplate"
import { requestTagSchema } from "@appnflat-types/BuildingGroup/RequestTag"
import { postTemplateSchema } from "@appnflat-types/BuildingGroup/PostTemplate"
import { z } from "zod"

/** The list of collections that should be loaded for a building when the user is on that building. */
const collectionsToLoadForBuilding = [
    "units" as const,
    "people" as const,
    "suppliers" as const,
    "parkings" as const,
    "lockers" as const,
    "banks" as const,
    "penalties" as const,
    "categories" as const,
]

function collectionsToLoad(
    userRole: Role,
    ownersFinancesAccess: Building["ownersFinancesAccess"] | undefined
): (Collection & (typeof collectionsToLoadForBuilding)[number])[] {
    function filterCollectionsToLoadInternal(coll: (typeof collectionsToLoadForBuilding)[number]) {
        switch (userRole) {
            case Role.resident:
                return coll === "people"
            case Role.owner:
                return (
                    coll !== "penalties" &&
                    (ownersFinancesAccess === "all" ||
                        (coll !== "banks" && coll !== "suppliers" && coll !== "categories"))
                )
            default:
                return true
        }
    }
    return collectionsToLoadForBuilding.filter(filterCollectionsToLoadInternal)
}

/** Loads the building's collections from Firestore.
 *
 * @param buildingDataLoadAllowed Whether the building's data should be loaded or not.
 */
function useFirestoreLoadBuildingCollections(buildingDataLoadAllowed: boolean) {
    const { buildingRef, fiscalYear } = useAppParams()
    const { userRole } = usePermissions()
    const ownersFinancesAccess = useBuilding()?.ownersFinancesAccess
    const userEmail = useUserProperty("email")
    const dispatch = useAppDispatch()
    useEffect(() => {
        if (
            !fiscalYear ||
            !buildingRef ||
            !buildingDataLoadAllowed ||
            !userEmail ||
            !userRole ||
            !ownersFinancesAccess
        ) {
            dispatch(setDataLoadingState({ currentBuilding: false }))
            return () => {
                dispatch(setDataLoadingState({ currentBuilding: true }))
            }
        }
        const collections = collectionsToLoad(userRole, ownersFinancesAccess)
        const collectionsRemainingToLoad = new Set(collections)

        const unsubscribes: (() => void)[] = []

        for (let i = 0, n = collections.length; i < n; i++) {
            const coll = collections[i]
            if (!coll) continue
            const filterWithFiscalYear = coll !== "penalties" // && coll !== "users" && coll !== "invitedUsers"
            const filters = [
                filterWithFiscalYear && where("fiscalYear", "==", fiscalYear),
                userRole === Role.owner &&
                    coll === "units" &&
                    where("ownersEmails", "array-contains", userEmail),
                (userRole === Role.owner || userRole === Role.resident) &&
                    coll === "people" &&
                    where("emails", "array-contains", userEmail),
            ].filter((f): f is QueryFieldFilterConstraint => f !== false)
            console.debug(
                `Subscribing to collection ${coll} for building ${buildingRef} with filters ${JSON.stringify(
                    filters
                )}`
            )
            unsubscribes.push(
                onSnapshot(
                    query(collection(db, "buildings", buildingRef, coll), ...filters),
                    function collectionSnapshotReceivedHandler(snap) {
                        collectionsRemainingToLoad.delete(coll)
                        if (collectionsRemainingToLoad.size === 0) {
                            dispatch(setDataLoadingState({ currentBuilding: false }))
                        }
                        const schema = collectionToSchema.buildings[coll]
                        const localObjects: Record<string, any>[] = []
                        for (let i = 0, n = snap.docs.length; i < n; i++) {
                            const data = snap.docs[i]?.data()
                            const parseResult = schema.safeParse(data)
                            if (!parseResult.success) {
                                console.warn(
                                    `Error parsing ${snap.docs[i]?.id} in ${coll}: ${JSON.stringify(
                                        parseResult.error.issues
                                    )}`
                                )
                                continue
                            }
                            if (
                                filterWithFiscalYear &&
                                "fiscalYear" in parseResult.data &&
                                parseResult.data.fiscalYear !== fiscalYear
                            )
                                continue
                            localObjects.push(parseResult.data)
                        }
                        console.debug(
                            `Saving ${localObjects.length} / ${snap.docs.length} docs for ${coll} to cache`
                        )
                        if (localObjects) {
                            dispatch(
                                setCollectionFromServer({
                                    setterId: "UFLBC",
                                    collection: coll,
                                    values: localObjects as any,
                                    root: "buildings",
                                    rootId: buildingRef,
                                })
                            )
                        }
                    },
                    function collectionSnapshotErrorHandler(error) {
                        collectionsRemainingToLoad.delete(coll)
                        if (collectionsRemainingToLoad.size === 0) {
                            dispatch(setDataLoadingState({ currentBuilding: false }))
                        }
                        console.error(
                            `Error in useFirestoreLoadBuildingCollections for '${coll}'`,
                            error
                        )
                    }
                )
            )
        }
        return () => {
            unsubscribes.forEach((unsubscribe) => unsubscribe())
            dispatch(emptyCacheOfBuildingData())
            dispatch(setDataLoadingState({ currentBuilding: true }))
        }
    }, [
        buildingRef,
        fiscalYear,
        ownersFinancesAccess,
        userEmail,
        userRole,
        buildingDataLoadAllowed,
        dispatch,
    ])
}

/** Loads the building docs for all buidlings the user has access to. */
function useFirestoreLoadBuildingDocs() {
    const userDocsInBuildings = useAppSelector(userDocsInBuildingsSelector)
    const dispatch = useAppDispatch()
    useEffect(() => {
        const unsubscribes: (() => void)[] = []
        const userDocs = objectEntries(userDocsInBuildings)
        const buildingsRemainingToLoad = new Set(userDocs.map(([b]) => b))
        for (let i = 0, n = userDocs.length; i < n; i++) {
            const userDoc = userDocs[i]
            if (!userDoc) continue
            const [buildingRef, user] = userDoc
            console.debug(`Subscribing to building doc ${buildingRef}`)
            unsubscribes.push(
                onSnapshot(
                    doc(db, "buildings", buildingRef),
                    function buildingSnapshotReceivedHandler(snap) {
                        buildingsRemainingToLoad.delete(buildingRef)
                        if (buildingsRemainingToLoad.size === 0) {
                            dispatch(setDataLoadingState({ buildings: false }))
                        }
                        const data = buildingSchema.safeParse(snap.data())
                        if (!data.success) {
                            console.warn(
                                `Error parsing building doc for ${buildingRef}`,
                                data.error.issues
                            )
                            return
                        }
                        console.debug(`Received building doc for ${buildingRef}`)
                        dispatch(setBuildingFromServer({ building: data.data, user }))
                    },
                    function buildingSnapshotErrorHandler(error) {
                        buildingsRemainingToLoad.delete(buildingRef)
                        if (buildingsRemainingToLoad.size === 0) {
                            dispatch(setDataLoadingState({ buildings: false }))
                        }
                        console.error(
                            `Error in useFirestoreLoadBuildingDocs for buildingRef=${buildingRef}`,
                            error
                        )
                    }
                )
            )
        }
        return () => {
            unsubscribes.forEach((unsubscribe) => unsubscribe())
        }
    }, [userDocsInBuildings, dispatch])
}

/** Loads the building group docs for all building groups the user has access to. */
function useFirestoreLoadBuildingGroupDocs() {
    const userDocsInBuildingGroups = useAppSelector(userDocsInBuildingGroupsSelector)
    const dispatch = useAppDispatch()
    useEffect(() => {
        const unsubscribes: (() => void)[] = []
        const userDocs = objectEntries(userDocsInBuildingGroups)
        const buildingGroupsRemainingToLoad = new Set(userDocs.map(([b]) => b))
        for (let i = 0, n = userDocs.length; i < n; i++) {
            const userDoc = userDocs[i]
            if (!userDoc) continue
            const [buildingGroupId, user] = userDoc
            console.debug(`Subscribing to building group doc ${buildingGroupId}`)
            unsubscribes.push(
                onSnapshot(
                    doc(db, "buildingGroups", buildingGroupId),
                    function buildingGroupSnapshotReceivedHandler(snap) {
                        buildingGroupsRemainingToLoad.delete(buildingGroupId)
                        if (buildingGroupsRemainingToLoad.size === 0) {
                            dispatch(setDataLoadingState({ buildingGroups: false }))
                        }
                        const data = buildingGroupSchema.safeParse(snap.data())
                        if (!data.success) {
                            console.debug(
                                `Error parsing building group doc for ${buildingGroupId}`,
                                data.error
                            )
                            return
                        }
                        console.debug(`Received building group doc for ${buildingGroupId}`)
                        dispatch(setBuildingGroupFromServer({ buildingGroup: data.data, user }))
                    },
                    function buildingGroupSnapshotErrorHandler(error) {
                        buildingGroupsRemainingToLoad.delete(buildingGroupId)
                        if (buildingGroupsRemainingToLoad.size === 0) {
                            dispatch(setDataLoadingState({ buildingGroups: false }))
                        }
                        console.error(
                            `Error in useFirestoreLoadBuildingGroupDocs for ${buildingGroupId}`,
                            error
                        )
                    }
                )
            )
        }
        return () => {
            unsubscribes.forEach((unsubscribe) => unsubscribe())
        }
    }, [dispatch, userDocsInBuildingGroups])
}

/** Fetches all user documents for the current user.
 *
 * This gives us a list of the buildings they are in and their role in each building.
 * It also gives us a list of the building groups they are in and their role in each building group.
 */
function useFirestoreLoadUserDocs() {
    const userUID = useUserProperty("uid")
    const dispatch = useAppDispatch()
    useEffect(() => {
        if (!userUID) return
        return onSnapshot(
            query(collectionGroup(db, "users"), where("uid", "==", userUID)),
            function userSnapshotReceivedHandler(snap) {
                const userDocs = snap.docs
                    .map((doc) => {
                        const rootCollection = doc.ref.parent.parent?.parent?.id
                        if (rootCollection === "buildings") {
                            const buildingUserParse = buildingUserSchema.safeParse(doc.data())
                            const buildingRef = doc.ref.parent.parent?.id
                            if (!buildingUserParse.success) {
                                console.error(
                                    "Error parsing a user document:",
                                    buildingUserParse.error,
                                    doc.data()
                                )
                            } else if (buildingUserParse.success && buildingRef) {
                                console.log(`Received user document for ${buildingRef}`)
                                return {
                                    collection: "buildings" as const,
                                    user: buildingUserParse.data,
                                    buildingRef,
                                }
                            }
                        } else if (rootCollection === "buildingGroups") {
                            const buildingGroupUserParse = buildingGroupUserSchema.safeParse(
                                doc.data()
                            )
                            const buildingGroupId = doc.ref.parent.parent?.id
                            if (!buildingGroupUserParse.success) {
                                console.error(
                                    "Error parsing a building group user document:",
                                    buildingGroupUserParse.error,
                                    doc.data()
                                )
                            } else if (buildingGroupUserParse.success && buildingGroupId) {
                                console.log(
                                    `Received building group user document for ${buildingGroupId}`
                                )
                                return {
                                    collection: "buildingGroups" as const,
                                    user: buildingGroupUserParse.data,
                                    buildingGroupId,
                                }
                            }
                        }
                        // Else: probably in buildingsBackup collection
                    })
                    .filter((data): data is Exclude<typeof data, undefined> => !!data)
                const buildingUserDocs = userDocs.filter(
                    (data): data is typeof data & { collection: "buildings" } =>
                        data.collection === "buildings"
                )
                const buildingGroupUserDocs = userDocs.filter(
                    (data): data is typeof data & { collection: "buildingGroups" } =>
                        data.collection === "buildingGroups"
                )
                dispatch(setUserDocInBuildings(buildingUserDocs))
                dispatch(setUserDocInBuildingGroups(buildingGroupUserDocs))
            },
            function userSnapshotErrorHandler(error) {
                console.error(`Error in useFirestoreLoadUserDocs`, error)
            }
        )
    }, [dispatch, userUID])
}

/** A collection that can be loaded based on the location of the user in the app. */
type LocationDependentCollections = Exclude<
    ("requestEmailTemplates" | "requestTags" | "postTemplates" | "users") & Collection,
    (typeof collectionsToLoadForBuilding)[number]
>

type LocationDependentPathAndSchema =
    | Extract<WebFirestorePathAndSchema, [[any, any, LocationDependentCollections], any]>
    | undefined

type PathAndSchemas<C extends LocationDependentPathAndSchema = LocationDependentPathAndSchema> = [
    Extract<C, [["buildings", any, any], any]> | undefined,
    Extract<C, [["buildingGroups", any, any], any]> | undefined,
]

/** The part of the app the user is currently in. */
const enum Location {
    none,
    requests,
    posts,
}

/** Loads a collection that can be loaded based on the location of the user in the app.
 *
 * @param coll The collection to load.
 * @param permissionKey The permission key to use when loading the collection.
 * @param fetch Whether to fetch the collection (should be the test for the location).
 */
function useLocationDependentCollection<C extends LocationDependentPathAndSchema>(
    pathAndSchemas: PathAndSchemas<C>,
    permissionKey: PermissionKey | undefined,
    fetch: boolean,
    additionalFiltersBuilding?: AdditionalFilter<z.infer<Exclude<C, undefined>[1]>>[],
    additionalFiltersBuildingGroups?: AdditionalFilter<z.infer<Exclude<C, undefined>[1]>>[]
) {
    const { buildingRef, buildingGroupId } = useAppParams()
    const coll = pathAndSchemas[0]?.[0]?.[2]
    if (fetch) console.debug(`Using location dependent collection ${coll}`)
    useFirestoreCollection({
        fetch: fetch && !!buildingRef,
        pathAndSchema: pathAndSchemas[0],
        permissionKey,
        setterId: `UFLDCB-${coll}`,
        additionalFilters: additionalFiltersBuilding as any,
    })
    useFirestoreCollection({
        fetch: fetch && !!buildingGroupId,
        pathAndSchema: pathAndSchemas[1],
        permissionKey,
        setterId: `UFLDCBG-${coll}`,
        additionalFilters: additionalFiltersBuildingGroups as any,
    })
}

const additionalFiltersHandlersBuilding = [
    ["where", "role", "in", [Role.handler, Role.admin, Role.approverWrite]],
] satisfies AdditionalFilterBuildingCollection<"users">[]

const additionalFiltersHandlersBuildingGroup = [
    ["where", "role", "in", [BuildingGroupUserRole.handler, BuildingGroupUserRole.admin]],
] satisfies AdditionalFilter<CollectionToType<"users", "buildingGroups">>[]

/** Loads data in a building or building group collection that should only be loaded
 * when the user is in a specific part of the app.
 */
function useLocationDependentCollections() {
    const { buildingRef, buildingGroupId } = useAppParams()
    const url = useLocation().pathname
    const [location, setLocation] = useState<Location>(Location.none)

    useEffect(() => {
        if (url.includes("/requests/")) {
            setLocation(Location.requests)
        } else if (url.includes("/posts/")) {
            setLocation(Location.posts)
        } else {
            setLocation(Location.none)
        }
    }, [url])

    /* Load data for Location = requests */
    const psRequestEmailTemplates = useMemo(
        () =>
            [
                (buildingRef ?
                    [
                        ["buildings", buildingRef, "requestEmailTemplates"],
                        requestEmailTemplateSchema,
                    ]
                :   undefined) satisfies WebFirestorePathAndSchema | undefined,
                (buildingGroupId ?
                    [
                        ["buildingGroups", buildingGroupId, "requestEmailTemplates"],
                        requestEmailTemplateSchema,
                    ]
                :   undefined) satisfies WebFirestorePathAndSchema | undefined,
            ] satisfies PathAndSchemas,
        [buildingRef, buildingGroupId]
    )
    useLocationDependentCollection(
        psRequestEmailTemplates as any,
        "requests.handler",
        location === Location.requests
    )
    const psRequestTags = useMemo(
        () => [
            (buildingRef ?
                [["buildings", buildingRef, "requestTags"], requestTagSchema]
            :   undefined) satisfies WebFirestorePathAndSchema | undefined,
            (buildingGroupId ?
                [["buildingGroups", buildingGroupId, "requestTags"], requestTagSchema]
            :   undefined) satisfies WebFirestorePathAndSchema | undefined,
        ],
        [buildingRef, buildingGroupId]
    )
    useLocationDependentCollection(psRequestTags as any, undefined, location === Location.requests)
    const psHandlers = useMemo(
        () => [
            (buildingRef ?
                [["buildings", buildingRef, "users"], buildingUserSchema]
            :   undefined) satisfies WebFirestorePathAndSchema | undefined,
            (buildingGroupId ?
                [["buildingGroups", buildingGroupId, "users"], buildingGroupUserSchema]
            :   undefined) satisfies WebFirestorePathAndSchema | undefined,
        ],
        [buildingRef, buildingGroupId]
    )
    useLocationDependentCollection(
        psHandlers as any,
        "requests.handler",
        location === Location.requests,
        additionalFiltersHandlersBuilding as any,
        additionalFiltersHandlersBuildingGroup as any
    )

    /* Load data for Location = posts */
    const psPostTemplates = useMemo(
        () => [
            (buildingRef ?
                [["buildings", buildingRef, "postTemplates"], postTemplateSchema]
            :   undefined) satisfies WebFirestorePathAndSchema | undefined,
            (buildingGroupId ?
                [["buildingGroups", buildingGroupId, "postTemplates"], postTemplateSchema]
            :   undefined) satisfies WebFirestorePathAndSchema | undefined,
        ],
        [buildingRef, buildingGroupId]
    )
    useLocationDependentCollection(
        psPostTemplates as any,
        "posts.templates",
        location === Location.posts
    )
}

/** Subscribes to the data the app needs in Firestore. */
export function useFirestore(buildingDataLoadAllowed: boolean) {
    useFirestoreLoadUserDocs()
    useFirestoreLoadBuildingDocs()
    useFirestoreLoadBuildingGroupDocs()
    useFirestoreLoadBuildingCollections(buildingDataLoadAllowed)
    useLocationDependentCollections()
}
