const messages = {
    this_penalty_applied_when_check_are_rejected:
        "- Cette pénalité peut être appliquée lorsqu'un chèque a été refusé par la banque.\n\n- Pour enregistrer le retour d'un chèque, rendez-vous dans la section 'Paiements/Dépôts' et cliquez sur 'Chèque retourné'.\n\n- Vous aurez alors l'option d'appliquer ou non la pénalité pour chèque retourné en cochant la boîte de controle appropriée.",
    account_not_found: "Le compte n'a pas été trouvé",
    add_or_remove_unit_to_automatic_wire_transfer_in_unit_section:
        "Pour ajouter ou enlever une unité de la liste des paiements par virement automatiques, veuillez visiter la section *Unités* et activez / désactivez l'option *Prélèvement automatique* de l'unité que vous souhaitez rajouter / enlever.",
    please_confirm_your_email_before_logging_in:
        "Veuillez confirmer votre email avant de vous connecter",
    account_disabled: "Le compte a été désactivé",
    institution_number_should_be_3_digits: "Le numéro d'institution devrait avoir 3 chiffres",
    transit_number_should_be_5_digits: "Le numéro de transit devrait avoir 5 chiffres",
    account_number_should_be_5_to_20_digits:
        "Le numéro de compte devrait avoir ente 5 et 20 chiffres",
    problem_changing_vote_shares: "Suite à un problème, les droits de vote n'ont pas été modifiés.",
    error_changing_owners: "Un problème est survenu lors du changement de propriétaire.",
    problem_cancelling_transaction:
        "Un problème est survenu lors de l'annulation de la transaction.",
    adding_or_removing_a_locker_is_very_unusual:
        " AJOUTER OU ENLEVER UN CASIER EST TRÈS INHABITUEL.",
    adding_or_removing_a_parking_is_very_unusual:
        " AJOUTER OU ENLEVER UN PARKING EST TRÈS INHABITUEL.",
    adding_or_removing_a_unit_is_very_unusual: " AJOUTER OU ENLEVER UNE UNITÉ EST TRÈS INHABITUEL.",
    adding_owners_or_residents_must_not_be_done_in_this_section_but_in_the_units_one:
        "Ajouter des propriétaires ou des locataires, gérer les parkings et les casiers, etc. ne peut être effectué dans cette section, mais dans celle des unités.",
    all_suppliers_have_been_paid: "Tous les fournisseurs ont été payés",
    all_units_are_up_to_date_with_their_payments: "Toutes les unités sont à jour de leur paiement.",
    archive_current_fiscal_year_q: "Archiver l'année fiscale en cours ?",
    archive_current_fiscal_year: "Archiver l'année fiscale en cours",
    archive_currently_selected_fiscal_year_q: "Archiver l'année fiscale sélectionnée ?",
    archive_currently_selected_fiscal_year: "Archiver L'année fiscale en cours",
    archive_this_bank_account: "Archiver ce compte en banque ?",
    archive_this_supplier: "Archiver ce fournisseur ?",
    archiving_building_please_wait_this_can_take_a_few_minutes:
        "Archivage de l'immeuble... Veuillez patienter, l'opération peut prendre quelques minutes.",
    assigning_lockers_to_units_must_not_be_done_on_this_screen:
        "Assigner un casier à une unité ne doit pas être effectué dans cette section, mais dans celle des unités. Pour toute question, veuillez contacter notre support.",
    assigning_parkings_to_units_must_not_be_done_on_this_screen:
        "Assigner un parking à une unité ne doit pas être effectué dans cette section, mais dans celle des unités.",
    be_careful_changing_vote_shares_will_also_change_monthly_fees:
        "ATTENTION : CHANGER DES DROITS DE VOTE MODIFIERA LES CHARGES DE COPROPRIÉTÉ DES UNITÉS",
    can_create_or_delete_units_parkings_lockers_here:
        "Dans cette section, vous pouvez renommer ou supprimer des casiers. VOUS RISQUEZ D'EFFACER DES DONNÉES IMPORTANTES.",
    change_date_of_fiscal_year_closure: "Changer la date de clôture fiscale.",
    change_end_date_of_fiscal_year_to_be_in_the_future:
        "Modifier la date de clôture de l'année fiscale",
    change_the_end_of_the_current_fiscal_year:
        "Changer la date de clôture de l'année fiscale en cours.",
    change_vote_shares_question: "Voulez-vous vraiment changer les droits de vote ?",
    charges_explanation:
        "Dans cette section, vous pouvez définir toutes les charges qui sont en cours au sein de votre copropriété (Vente de clé, location de salle, etc.). Une fois définies, ces charges apparaîtront dans la lite des transactions que vous pouvez ajouter à une unité dans la section *Unités / Transactions*.",
    check_registration_explanation:
        "- Vous pouvez enregistrer les chèques que vous recevez avant de les déposer à la banque.\n\n- Les copropriétaires seront prévenus que vous avez réceptionné leur paiement si vous avez activé l'option correspondante dans la section *Communication / Communications Automatiques*.\n\n- Lorsque vous souhaiterez faire un dépôt en banque (section *Dépôts*), vous pourrez sélectionner les chèques que vous avez déjà enregistrés.\n\n- Enfin lorsque vous enregistrez des chèques vous pouvez le faire en séquence. Cela vous évitera de les saisir un par un. Cochez l'option correspondante et spécifiez le nombre de chèques. L'application créera les chèques automatiquement.",
    check_reminder_explanation:
        "- En activant l'option ci-dessous, les copropriétaires seront avertis que vous avez bien reçu leur(s) chèques(s).\n\n- Chaque fois que vous enregistrerez un chèque dans la section *Paiements / Chèques*, le copropriétaire concerné recevra un email détaillant le numéro et le montant de ce chèque.",
    complex_transaction_display_explanation:
        "Un montant négatif indique un débit au compte. Un montant positif indique un crédit au compte.",
    confirm_password: "Confirmez votre mot de passe",
    create_a_new_fiscal_year: "Démarrer une nouvelle année fiscale ?",
    create_new_fiscal_year: "Démarrer une nouvelle année fiscale",
    creating_new_fiscal_year_please_wait_this_can_take_a_few_minutes:
        "Création d'une nouvelle année fiscale... Veuillez patienter, l'opération peut prendre quelques minutes.",
    edit_person_in_unit_screen_explanation:
        "Vous ne pouvez pas modifier les informations d'un copropriétaire ou d'un locataire dans cette section. Pour ce faire, veuillez visiter la section *Propriétaires et locataires*.",
    email_templates_explanation:
        "- Vous pouvez personnaliser les emails qui sont envoyés automatiquement aux copropriétaires.\n\n- Un copropriétaire peut être averti lorsque :\n\n1. Il n'a pas payé ses frais de condo ;\n\n2. Son assurance va expirer ;\n\n3. La garantie de son chauffe-eau va expirer ;\n\n4. Les informations à propos de son assurance sont incomplètes ;\n\n5. Les informations à propos de son chauffe-eau sont incomplètes.\n\n- Une fois que vous avez personnalisé vos emails, rendez-vous dans la section *Communications Automatiques* afin d'activer les relances et de spécifier leur fréquence.",
    fiscal_year_explanation:
        "- Dans cette section, vous pouvez démarrer une nouvelle année fiscale et ainsi clôturer une année fiscale passée.\n\n- Lorsque vous démarrez une nouvelle année fiscale, vous devez préciser la date de début de cette nouvelle année fiscale.\n\n- Les données précédant le début de la nouvelle année fiscale seront archivées et vous pourrez les consulter en cliquant sur l'icône de calendrier au bas du menu de navigation principal.\n\n- **Vous ne pouvez plus modifier les données d'une année fiscale archivée. Assurez vous donc, avant de clôturer, que vos états financiers sont définitifs.**",
    if_you_have_questions_contact_our_support:
        "Pour toute question, veuillez contacter notre support.",
    insurance_reminder_explanation:
        "- En activant les options ci-dessous, les copropriétaires seront avertis lorsque :\n\n1. Leur assurance arrive à terme ;\n\n2. Ils n'ont pas complété les informations relatives à leur assurance.\n\n- Vous pouvez personnaliser l'email qu'ils recevront dans la section *Courriels Modèles*.",
    minimum_unit_balance_before_charging_penalties_explanation:
        "- Lorsque la facturation automatique est activée, vous pouvez définir un seuil en deçà duquel les pénalités ne sont pas appliquées.\n\n- Ainsi une unité de bonne foi dont la dette est limitée ne sera pas pénalisée.",
    no_bank_accounts: "Pas de compte en banque.",
    no_transactions_to_send: "Il n'y a aucune transaction à envoyer à Otonom Solution.",
    no_units_pay_by_automatic_wire_transfer:
        "Aucun propriétaire ne paie par transfer électronique.",
    page_requested_not_found: "La page est introuvable. Veuillez vérifier son adresse URL.",
    penalties_explanation:
        "Dans cette section, vous pouvez définir toutes les pénalités qui sont en cours au sein de votre copropriété (cannabis, défaut d'assurance, etc.). Une fois définies, ces pénalités apparaîtront dans la lite des transactions que vous pouvez ajouter à une unité dans la section *Unités / Transactions*.",
    recurring_bills_explanation:
        "- Lorsqu'un fournisseur vous facture chaque mois un montant identique (ex : fournisseur d'accès internet) ou lorsque vous payez mensuellement un abonnement, vous pouvez spécifier une facturation / paiement récurrent.\n\n- Les factures / payments seront alors créés automatiquement chaque mois et cela vous évitera de saisir manuellement toutes les factures / paiement un à un.",
    select_the_start_date_for_the_new_fiscal_year:
        "Veuillez sélectionner la date de début de la nouvelle année fiscale.",
    select_the_users_you_want_to_invite_2: "Sélectionner les utilisateurs que vous voulez inviter.",
    this_fiscal_year_is_archived_and_cannot_be_edited:
        "Cette année fiscale a été archivée et ne peut être modifiée.",
    this_penalty_is_applied_when_a_co_owners_pays_late:
        "Si vous activez cette pénalité, les pénalités dues au retard de paiement des frais de condo s'appliquent lorsque 3 conditions sont remplies :\n\n1. Le propriétaire n'a pas payé l'intégralité de ses frais de condo ;\n\n2. Sa dette est supérieur à un seuil que vous définissez ;\n\n3. Le retard dépasse un délai de grâce que vous aurez précisé en indiquant le nombre de jours pendant lesquels un retard de paiement ne déclenche pas la facturation d'une pénalité.",
    this_unit_does_not_have_any_owner: "Cette unité n'a pas de propriétaire.",
    unit_help_message:
        "CHARGES COMMUNES\n\n- Les frais de condo ne peuvent pas être modifiés dans cette section. Les frais de copropriété sont calculés à partir de votre budget en utilisant la part de chaque unité (section *Comptabilité / Budget*). Par conséquent, les frais de condo ne peuvent pas être ajustés individuellement.\n\n---\n\nN° D'UNITÉ - % DE VOTE - IDENTIFIANT COMPTABLE\n\n- Le numéro d'unité, la part de vote ou l'identifiant comptable ne peuvent pas être modifiés. Veuillez contacter le support si vous souhaitez les modifier.\n\n---\n\nPRÉLÈVEMENT AUTOMATIQUE\n\n- Pour activer le prélèvement automatique, veuillez saisir les informations du compte bancaire du payeur.",
    unknown_error_occurred: "Une erreur inconnue s'est produite.",
    unpaid_balance_reminder_explanation:
        "- En activant l'option ci-dessous, les copropriétaires seront avertis au début de chaque mois lorsqu'ils ont un solde impayé.\n\n- Vous pouvez personnaliser l'email qu'ils recevront dans la section *Courriels Modèles*.",
    water_heater_reminder_explanation:
        "- En activant les options ci-dessous, les copropriétaires seront avertis lorsque :\n\n1. La garantie de leur chauffe-eau arrive à terme ;\n\n2. Ils n'ont pas complété les informations relatives à leur chauffe-eau.\n\n- Vous pouvez personnaliser l'email qu'ils recevront dans la section *Courriels Modèles*.",
    will_change_monthly_fees: "Changer les droits de vote modifiera les frais de condo.",
    you_are_up_to_date_with_your_payments: "Vous êtes à jour de vos paiements.",
    you_may_erase_important_data: "VOUS RISQUEZ D'EFFACER DES DONNÉES IMPORTANTES.",
    you_must_create_a_new_fiscal_year: "Vous devez créer une nouvelle année fiscale",
} as const

export default messages
