/**************************************
 * CACHE HELPERS
 **************************************/

import { DeepPartial } from "@appnflat-types/helpers"
import {
    BuildingCollection,
    BuildingGroupCollection,
    CollectionToType,
    TypeByCollection,
} from "@appnflat-types/Collection"
import { BuildingUser } from "@appnflat-types/BuildingUser"
import { BuildingGroupUser } from "@appnflat-types/BuildingGroup/BuildingGroupUser"
import { BuildingGroup } from "@appnflat-types/BuildingGroup/BuildingGroup"
import { Building } from "@appnflat-types/Building"

/** The collections that are cached in the web app.
 *
 * Note: {@link BuildingWebCollections} and {@link BuildingGroupWebCollections} are more specific.
 */
export type WebCacheCollections = Exclude<keyof TypeByCollection, "secretsBankAccounts" | "locks">
/** The collections that are cached in the building. */
export type BuildingWebCollections = WebCacheCollections & BuildingCollection
/** The collections that are cached in the building group. */
export type BuildingGroupWebCollections = WebCacheCollections & BuildingGroupCollection

/** A cache entry. */
export type CacheEntry<Obj extends object> = {
    /** Whether the object should be deleted in the database. */
    delete?: boolean
    /** A set of edits that have not yet been pushed to the database. */
    edits?: DeepPartial<Obj>
    /** The id of the function that last set the object. */
    setterId?: string
} & (
    | {
          /** The original as it currently is in the database. */
          original: Obj
      }
    | {
          /** The details of an object that was created and isn't yet saved to the database. */
          created: Obj
      }
)

/** The type of the cache. */
export type Cache = {
    buildings: {
        [ref: Building["buildingRef"]]: {
            /** The building. */
            building: CacheEntry<Building>
            /** The user's document in the building. */
            user: BuildingUser
            /** The collections in the building.
             *
             * The keys for each record are as defined in `{@link idForCollection}`.
             */
            collections?: {
                [T in BuildingWebCollections]?: Record<string, CacheEntry<CollectionToType<T>>>
            }
        }
    }
    buildingGroups: {
        [ref: BuildingGroup["id"]]: {
            /** The building group. */
            buildingGroup: CacheEntry<BuildingGroup>
            /** The user's document in the building group. */
            user: BuildingGroupUser
            /** The collections in the building group.
             *
             * The keys for each record are as defined in `{@link idForCollection}`.
             */
            collections?: {
                [T in BuildingGroupWebCollections]?: Record<
                    string,
                    CacheEntry<CollectionToType<T, "buildingGroups">>
                >
            }
        }
    }
}

/** The initial state of the cache. */
export const initialState: Cache = {
    buildings: {},
    buildingGroups: {},
}

/**************************************
 * HELPERS
 **************************************/

/** Merges a cache entry, giving priority to edited fields over their original value. */
export function mergeEntry<Obj extends object>(
    entry: CacheEntry<Obj> | undefined
): Obj | undefined {
    if (!entry) return undefined
    if ("original" in entry) return { ...(entry.original ?? {}), ...(entry.edits ?? {}) }
    else return { ...(entry.created ?? {}), ...(entry.edits ?? {}) }
}
