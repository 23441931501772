import z from "zod"
import { buildingGroupIdSchema } from "../Id"
import { mediumString } from "../BaseStrings"
import { addOpenAPI } from "../zodExtensions"
import { attachmentS3Schema } from "../Common"

/** The schema for a management company. */
export const buildingGroupSchema = addOpenAPI(
    z.object({
        /** The id of the management company. */
        id: buildingGroupIdSchema,
        /** The name of the management company. */
        name: mediumString,
        /** The URL of the management company's logo.
         *
         * Points to the same file as `logo`, but is a publicly accessible URL that can be used to display the logo.
         * Points to a file in the storage bucket in either WebP or SVG format. */
        logoUrl: z.string().url().optional(),
        /** The logo of the management company.
         *
         * Points to a file in the storage bucket in either WebP or SVG format. */
        logo: attachmentS3Schema.optional(),
    }),
    "BuildingGroup"
)
export type BuildingGroup = z.infer<typeof buildingGroupSchema>

/** The subcollections of a management company. */
export const enum buildingGroupSubCollections {
    /** A collection of post templates. */
    postTemplates = "postTemplates",
    /** A collection of the users in the management company. */
    users = "users",
    /** A collection of the invited users in the management company. */
    invitedUsers = "invitedUsers",
}
