/**************************************
 * CACHE SELECTORS
 **************************************/

import { collectionToSchema, CollectionToType, RootCollection } from "@appnflat-types/Collection"
import { type RootState } from "./store"
import { translate } from "logic/textDescriptions/translate"
import { WSelectOption } from "components/Inputs/WSelect"
import { Bank, bankAccountTypeSchema } from "@appnflat-types/Bank"
import { Unit } from "@appnflat-types/Unit"
import { Supplier } from "@appnflat-types/Supplier"
import { Transaction } from "@appnflat-types/Transaction"
import { CacheEntry, mergeEntry } from "./cacheHelpers"
import { Category } from "@appnflat-types/Category"
import { Building } from "@appnflat-types/Building"
import { BuildingUser } from "@appnflat-types/BuildingUser"
import { createSelector, createSelectorCreator, weakMapMemoize } from "reselect"
import { AidString, BuildingRef } from "@appnflat-types/BaseStrings"
import { BuildingGroup } from "@appnflat-types/BuildingGroup/BuildingGroup"
import { BuildingGroupUser } from "@appnflat-types/BuildingGroup/BuildingGroupUser"
import { objectKeys } from "@shared/objects"

const createSelectorWeakMap = createSelectorCreator({
    memoize: weakMapMemoize,
    argsMemoize: weakMapMemoize,
})

export enum StoreArchived {
    include,
    exclude,
}

export const userDocsInBuildingsSelector = createSelector(
    (state: RootState) => state.cache.buildings,
    (buildings) =>
        Object.fromEntries(
            Object.entries(buildings).map(([buildingRef, { user }]) => [buildingRef, user])
        )
)

export const userDocsInBuildingGroupsSelector = createSelector(
    (state: RootState) => state.cache.buildingGroups,
    (buildingGroups) =>
        Object.fromEntries(Object.entries(buildingGroups).map(([id, { user }]) => [id, user]))
)

/** Returns the list of buildings from the cache. */
export const cachedBuildingsSelector = createSelectorWeakMap(
    (state: RootState) => state.cache.buildings,
    (buildings) => {
        const keys = objectKeys(buildings)
        const values: {
            building: Building
            /** The user's document in the building. */
            user: BuildingUser
        }[] = []
        for (let i = 0, n = keys.length; i < n; i++) {
            const key = keys[i]
            if (!key) continue
            const entry = buildings[key]
            if (!entry) continue
            const building = mergeEntry(entry.building)
            if (!building) continue
            values.push({ building, user: entry.user })
        }
        return values
    }
)

/** Returns the list of building groups from the cache. */
export const cachedBuildingGroupsSelector = createSelectorWeakMap(
    (state: RootState) => state.cache.buildingGroups,
    (buildingGroups) => {
        const keys = objectKeys(buildingGroups)
        const values: {
            buildingGroup: BuildingGroup
            /** The user's document in the building group. */
            user: BuildingGroupUser
        }[] = []
        for (let i = 0, n = keys.length; i < n; i++) {
            const key = keys[i]
            if (!key) continue
            const entry = buildingGroups[key]
            if (!entry) continue
            const buildingGroup = mergeEntry(entry.buildingGroup)
            if (!buildingGroup) continue
            values.push({ buildingGroup, user: entry.user })
        }
        return values
    }
)

type CachedCollectionSelector = <
    C extends keyof (typeof collectionToSchema)[Root],
    Root extends "buildings" | "buildingGroups",
>(
    state: RootState,
    root: Root,
    id: string | undefined,
    collection: C,
    includeArchived: StoreArchived,
    fiscalYear?: number
) => CollectionToType<C, Root>[]

/** Returns a list of objects from the cache.
 *
 * @param collection - The collection to return.
 * @param includeArchived - Whether to include or exclude archived objects.
 * @param fiscalYear - If specified, object that contain a fiscalYear field and that
 * are not from the given fiscal year will be filtered out.
 */
export const cachedCollectionSelector: CachedCollectionSelector = createSelectorWeakMap(
    [
        (__state: RootState, root: "buildings" | "buildingGroups") => root,
        (
            __state: RootState,
            __root: "buildings" | "buildingGroups",
            __id: BuildingRef | BuildingGroup["id"] | undefined,
            collection: keyof (typeof collectionToSchema)[RootCollection]
        ) => collection,
        (
            state: RootState,
            root: "buildings" | "buildingGroups",
            id: BuildingRef | BuildingGroup["id"] | undefined,
            collection: keyof (typeof collectionToSchema)[RootCollection]
        ) => {
            if (root === "buildings") {
                if (collection === "_") return state.cache.buildings
                else if (!id) return undefined
                else return state.cache.buildings[id as BuildingRef]?.collections?.[collection]
            } else if (root === "buildingGroups") {
                if (collection === "_") return state.cache.buildingGroups
                else if (!id) return undefined
                else
                    return state.cache.buildingGroups[id as BuildingGroup["id"]]?.collections?.[
                        collection
                    ]
            }
        },
        (
            __state: RootState,
            __root: "buildings" | "buildingGroups",
            __id: BuildingRef | BuildingGroup["id"] | undefined,
            __collection: keyof (typeof collectionToSchema)[RootCollection],
            includeArchived: StoreArchived
        ) => includeArchived,
        (
            __state: RootState,
            __root: "buildings" | "buildingGroups",
            __id: BuildingRef | BuildingGroup["id"] | undefined,
            __collection: keyof (typeof collectionToSchema)[RootCollection],
            __includeArchived: StoreArchived,
            fiscalYear?: number
        ) => fiscalYear,
    ],
    (
        root,
        collection,
        collectionInCache,
        includeArchived,
        fiscalYear
    ): CollectionToType<keyof (typeof collectionToSchema)[RootCollection]>[] => {
        const ia = includeArchived === StoreArchived.include
        const rootEntry = root === "buildings" ? "building" : "buildingGroup"
        const entries =
            collection === "_" ?
                Object.values(
                    (collectionInCache ?? {}) as
                        | RootState["cache"]["buildings"]
                        | RootState["cache"]["buildingGroups"]
                ).map((e) => e[rootEntry] as CacheEntry<Building | BuildingGroup> | undefined)
            :   (Object.values(collectionInCache ?? {}) as CacheEntry<object>[])
        const values: CollectionToType<keyof (typeof collectionToSchema)[RootCollection]>[] = []
        for (let i = 0, n = entries.length; i < n; i++) {
            const entry = entries[i]
            if (!entry) continue
            const value = mergeEntry(entry)
            if (
                !value ||
                (fiscalYear && "fiscalYear" in value && value.fiscalYear !== fiscalYear) ||
                (!ia && "archived" in value && value.archived)
            )
                continue
            values.push(value as any)
        }
        return values
    }
) as any

type CachedObjectSelector = <
    C extends keyof (typeof collectionToSchema)[Root],
    Root extends "buildings" | "buildingGroups",
>(
    state: RootState,
    root: Root,
    id: string | undefined,
    collection: C,
    docId: string | null
) => CollectionToType<C, Root> | undefined

/** Returns an object from the cache by identifier.
 *
 * @param collection - The collection in which to look for the object.
 * @param id - The buildingRef or buildingGroupId in which the object is located. If `null`, will return `undefined`.
 * @param docId - The identifier of the object to return. If `null`, will return `undefined`.
 */
export const cachedObjectSelector: CachedObjectSelector = createSelectorWeakMap(
    [
        (
            state: RootState,
            root: "buildings" | "buildingGroups",
            id: BuildingRef | BuildingGroup["id"] | undefined,
            collection: keyof (typeof collectionToSchema)[RootCollection],
            docId: string | null
        ) => {
            if (!id || !docId) return undefined
            if (root === "buildings") {
                if (collection === "_") return state.cache.buildings[id as BuildingRef]?.building
                else
                    return state.cache.buildings[id as BuildingRef]?.collections?.[collection]?.[
                        docId
                    ]
            } else if (root === "buildingGroups") {
                if (collection === "_")
                    return state.cache.buildingGroups[id as BuildingGroup["id"]]?.buildingGroup
                else
                    return state.cache.buildingGroups[id as BuildingGroup["id"]]?.collections?.[
                        collection
                    ]?.[docId]
            }
        },
    ],
    (obj: any) => {
        if (!obj) return undefined
        return mergeEntry(obj)
    }
) as CachedObjectSelector

/** Returns the current building. */
export const cachedBuildingSelector = createSelectorWeakMap(
    [
        (state: RootState) => state.cache.buildings,
        (__: RootState, buildingRef: BuildingRef | undefined) => buildingRef,
    ],
    (buildings, buildingRef) => {
        if (!buildingRef) return undefined
        const building = buildings[buildingRef]
        if (!building) return undefined
        return mergeEntry(building.building)
    }
)

/** Returns the current building group. */
export const cachedBuildingGroupSelector = createSelectorWeakMap(
    [
        (state: RootState) => state.cache.buildingGroups,
        (__: RootState, buildingGroupId: BuildingGroup["id"] | undefined) => buildingGroupId,
    ],
    (buildingGroups, buildingGroupId) => {
        if (!buildingGroupId) return undefined
        const buildingGroup = buildingGroups[buildingGroupId]
        if (!buildingGroup) return undefined
        return mergeEntry(buildingGroup.buildingGroup)
    }
)

/** Returns the user's document for the current building. */
export const cachedUserInBuildingSelector = createSelectorWeakMap(
    [
        (state: RootState) => state.cache.buildings,
        (__: RootState, buildingRef: BuildingRef | undefined) => buildingRef,
    ],
    (buildings, buildingRef) => {
        if (!buildingRef) return undefined
        return buildings[buildingRef]?.user
    }
)

export type TransactionPartiesSelectorCollection =
    | "all"
    | "all-including-archived"
    | (
          | "units"
          | "banks"
          | "suppliers"
          | "categories"
          | `categories-${Exclude<Category["parent"], undefined>}`
          | `banks-${Exclude<Bank["bankAccountType"], undefined>}`
          | "banks-otonom"
      )

/** Returns a list of possible transaction parties.
 *
 * Will not include archived accounts (except for `units` which have
 * `soldAndNeedToSetBalanceToZero` set to true, since new transactions can be
 * created for those, and for `all-including-archived`).
 * @param collections - The collections to include.
 *   - `all` means all unarchived accounts (all accounts for which a new transaction can be created),
 *   - `all-including-archived` means all accounts (useful to display transaction with potentially archived accounts),
 *   - `bank-accounts` means excluding investment and saving accounts.
 *   - `banks-otonom` means bank accounts that can be used in Otonom transactions (currently, only
 *   the bank account specified as `defaultBankAccountAID` in the building doc).
 */
export const transactionPartiesSelector = createSelectorWeakMap(
    [
        (
            state: RootState,
            buildingRef: BuildingRef | undefined,
            collection: TransactionPartiesSelectorCollection
        ) =>
            (
                buildingRef &&
                (collection === "all" ||
                    collection === "all-including-archived" ||
                    collection.startsWith("banks"))
            ) ?
                state.cache.buildings[buildingRef]?.collections?.banks
            :   undefined,
        (
            state: RootState,
            buildingRef: BuildingRef | undefined,
            collection: TransactionPartiesSelectorCollection
        ) =>
            (
                buildingRef &&
                (collection === "all" ||
                    collection === "all-including-archived" ||
                    collection === "suppliers")
            ) ?
                state.cache.buildings[buildingRef]?.collections?.suppliers
            :   undefined,
        (
            state: RootState,
            buildingRef: BuildingRef | undefined,
            collection: TransactionPartiesSelectorCollection
        ) =>
            (
                buildingRef &&
                (collection === "all" ||
                    collection === "all-including-archived" ||
                    collection === "units")
            ) ?
                state.cache.buildings[buildingRef]?.collections?.units
            :   undefined,
        (
            state: RootState,
            buildingRef: BuildingRef | undefined,
            collection: TransactionPartiesSelectorCollection
        ) =>
            (
                buildingRef &&
                (collection === "all" ||
                    collection === "all-including-archived" ||
                    collection.startsWith("categories"))
            ) ?
                state.cache.buildings[buildingRef]?.collections?.categories
            :   undefined,
        (state: RootState, buildingRef: BuildingRef | undefined) => {
            if (!buildingRef) return undefined
            return state.cache.buildings[buildingRef]
        },
        (
            __: RootState,
            __b: BuildingRef | undefined,
            collection: TransactionPartiesSelectorCollection
        ) => collection,
        (
            __: RootState,
            __b: BuildingRef | undefined,
            __c: TransactionPartiesSelectorCollection,
            fiscalYear: number | undefined
        ) => fiscalYear,
    ],
    (banks, suppliers, units, categories, building, collection, fiscalYear) => {
        const results: (WSelectOption<AidString> & {
            type: "banks" | "suppliers" | "units" | "categories"
            uuid: string
        })[] = []
        const includeAllCollections =
            collection === "all" || collection === "all-including-archived"
        const includeArchived = collection === "all-including-archived"
        const banksList = banks ? Object.values(banks).map((b) => mergeEntry(b)) : []
        const suppliersList = suppliers ? Object.values(suppliers) : []
        const unitsList = units ? Object.values(units) : []
        const categoriesList = categories ? Object.values(categories).map((c) => mergeEntry(c)) : []
        const mergedBuilding = building ? mergeEntry(building.building) : undefined
        function excludeAccount(account: Supplier | Unit | Bank | Category, isUnit?: boolean) {
            return (
                account.fiscalYear !== fiscalYear ||
                (!includeArchived &&
                    account.archived &&
                    (!isUnit ||
                        !("soldAndNeedToSetBalanceToZero" in account) ||
                        !account.soldAndNeedToSetBalanceToZero))
            )
        }
        if (includeAllCollections || collection.startsWith("banks")) {
            const includeType = bankAccountTypeSchema.safeParse(collection.split("-")[1]).data
            const includeAll = includeAllCollections || collection === "banks"
            const onlyOtonom = collection === "banks-otonom"
            const otonomAID = mergedBuilding?.defaultBankAccountAID
            for (let i = 0, n = banksList.length; i < n; i++) {
                const obj = banksList[i]
                if (!obj || excludeAccount(obj)) continue
                if (
                    includeAll ||
                    (onlyOtonom && obj.aid === otonomAID) ||
                    (includeType && obj.bankAccountType === includeType)
                ) {
                    results.push({
                        value: obj.aid,
                        label: obj.name ? `${obj.aid} - ${obj.name}` : obj.aid,
                        type: "banks",
                        uuid: obj.uuid,
                        group: "core:banks",
                    })
                }
            }
        }
        if (includeAllCollections || collection.startsWith("categories")) {
            const includeType =
                collection.startsWith("categories-") ? collection.split("-")[1] : undefined
            const includeAll = includeAllCollections || collection === "categories"
            for (let i = 0, n = categoriesList.length; i < n; i++) {
                const obj = categoriesList[i]
                if (!obj || excludeAccount(obj)) continue
                if (includeAll || includeType === obj.parent) {
                    results.push({
                        value: obj.aid,
                        label: obj.name ? `${obj.aid} - ${obj.name}` : obj.aid,
                        type: "categories",
                        group: "core:accounting_categories",
                        uuid: obj.uuid,
                    })
                }
            }
        }
        if (includeAllCollections || collection === "suppliers") {
            for (let i = 0, n = suppliersList.length; i < n; i++) {
                const entry = suppliersList[i]
                const obj = mergeEntry(entry)
                if (!obj) continue
                if (excludeAccount(obj)) continue
                results.push({
                    value: obj.aid,
                    label: obj.name ? `${obj.aid} - ${obj.name}` : obj.aid,
                    type: "suppliers",
                    group: "core:suppliers",
                    uuid: obj.uuid,
                })
            }
        }
        if (includeAllCollections || collection === "units") {
            for (let i = 0, n = unitsList.length; i < n; i++) {
                const entry = unitsList[i]
                const obj = mergeEntry(entry)
                if (!obj) continue
                if (excludeAccount(obj, true)) continue
                results.push({
                    value: obj.aid,
                    label:
                        obj.soldAndNeedToSetBalanceToZero || obj.archived ?
                            translate(T_UNIT_NUMBER_SOLD, { $number: obj.aid })
                        :   translate(T_UNIT_NUMBER, { $number: obj.number ?? obj.aid }),
                    type: "units",
                    group: "core:units",
                    uuid: obj.uuid,
                })
            }
        }
        return results
    }
)

const T_UNIT_NUMBER_SOLD = {
    en: "Unit $number sold",
    fr: "Unité $number vendue",
}

const T_UNIT_NUMBER = {
    en: "Unit $number",
    fr: "Unité $number",
}

/** Returns a list of transactions from the cache.
 *
 * @param fiscalYear - The fiscal year to filter the transactions by.
 * @param includeCancelled - Whether to include cancelled transactions. (default: true)
 */
export const transactionsSelector = createSelectorWeakMap(
    [
        (state: RootState, buildingRef: BuildingRef | undefined) =>
            buildingRef ? state.cache.buildings[buildingRef]?.collections?.transactions : undefined,
        (state: RootState, buildingRef: BuildingRef | undefined) =>
            buildingRef ?
                state.cache.buildings[buildingRef]?.collections?.unreconciledTransactions
            :   undefined,
        (__: RootState, __b: BuildingRef | undefined, fiscalYear: number | undefined) => fiscalYear,
        (
            __: RootState,
            __b: BuildingRef | undefined,
            __f: number | undefined,
            includeCancelled: boolean | undefined
        ) => includeCancelled,
    ],
    (transactions, unreconciledTransactions, fiscalYear, includeCancelled) => {
        const keysTransactions = Object.keys(transactions ?? {})
        const keysUnreconciledTransactions = Object.keys(unreconciledTransactions ?? {})
        const values: {
            transaction: Transaction
            collection: "transactions" | "unreconciledTransactions"
        }[] = []
        for (let i = 0, n = keysTransactions.length; i < n; i++) {
            const key = keysTransactions[i]
            if (!key) continue
            const entry = transactions?.[key]
            if (!entry) continue
            const transaction = mergeEntry(entry)
            if (
                !transaction ||
                transaction.fiscalYear !== fiscalYear ||
                (transaction.status === "cancelled" && includeCancelled === false)
            )
                continue
            values.push({ transaction, collection: "transactions" as const })
        }
        for (let i = 0, n = keysUnreconciledTransactions.length; i < n; i++) {
            const key = keysUnreconciledTransactions[i]
            if (!key) continue
            const entry = unreconciledTransactions?.[key]
            if (!entry) continue
            const transaction = mergeEntry(entry)
            if (
                !transaction ||
                transaction.fiscalYear !== fiscalYear ||
                (transaction.status === "cancelled" && includeCancelled === false)
            )
                continue
            values.push({ transaction, collection: "unreconciledTransactions" as const })
        }
        return values
    }
)

export type AIDToCollectionAndUUID = Record<
    AidString,
    { collection: "suppliers" | "units" | "banks" | "categories"; uuid: string }
>

/** Returns a map of aids to collection. */
export const cacheAidToCollectionSelector = createSelectorWeakMap(
    [
        (state: RootState, buildingRef: BuildingRef | undefined) =>
            buildingRef ? state.cache.buildings[buildingRef]?.collections?.banks : undefined,
        (state: RootState, buildingRef: BuildingRef | undefined) =>
            buildingRef ? state.cache.buildings[buildingRef]?.collections?.units : undefined,
        (state: RootState, buildingRef: BuildingRef | undefined) =>
            buildingRef ? state.cache.buildings[buildingRef]?.collections?.categories : undefined,
        (state: RootState, buildingRef: BuildingRef | undefined) =>
            buildingRef ? state.cache.buildings[buildingRef]?.collections?.suppliers : undefined,
    ],
    (banks, units, categories, suppliers): AIDToCollectionAndUUID => {
        const values: AIDToCollectionAndUUID = {}
        Object.values(banks ?? {}).forEach((b) => {
            const v = mergeEntry(b)
            if (!v) return
            values[v.aid] = { collection: "banks", uuid: v.uuid }
        })
        Object.values(categories ?? {}).forEach((c) => {
            const v = mergeEntry(c)
            if (!v) return
            values[v.aid] = { collection: "categories", uuid: v.uuid }
        })
        Object.values(suppliers ?? {}).forEach((s) => {
            const v = mergeEntry(s)
            if (!v) return
            values[v.aid] = { collection: "suppliers", uuid: v.uuid }
        })
        Object.values(units ?? {}).forEach((u) => {
            const v = mergeEntry(u)
            if (!v) return
            values[v.aid] = { collection: "units", uuid: v.uuid }
        })
        return values
    }
)
