import { useEffect, useMemo, useState } from "react"
import { db } from "../firebaseSetup"
import {
    collection,
    onSnapshot,
    query,
    where,
    limit,
    limitToLast,
    endBefore,
    startAfter,
    orderBy,
    DocumentReference,
    DocumentSnapshot,
} from "firebase/firestore"
import { useAppDispatch, useAppParams } from "hooks/hooks"
import { setCollectionFromServer } from "store/cache"
import { BuildingCollection, CollectionToType } from "@appnflat-types/Collection"
import { PermissionKey, useHasPermission } from "./useHasPermission"
import { WebCacheCollections } from "store/cacheHelpers"
import debugHooks from "hooks/debugHooks"
import { get } from "@shared/objects"
import { useDeepCompareMemoize } from "./useDeepCompareEffect"
import {
    WhereFilterWithPrefix,
    OrderByFilterWithPrefix,
} from "@appnflat-types/FirestoreInterface/Filter"
import { z } from "zod"
import { FirestoreCollectionPathAndSchema } from "@appnflat-types/FirestoreInterface/PathAndSchema"
import { Widen } from "@appnflat-types/helpers"

export type DataWithRef<T extends object> = {
    /** The data of the firestore collection, as an array of documents and references. */
    data: {
        /** The content of the document. */
        data: T
        /** The Firestore reference of the document. */
        ref: DocumentReference
    }[]
    /** Function to show the next page of the collection. If not present, there is nothing more to show. */
    next?: () => void
    /** Function to show the previous page of the collection. If not present, there is nothing more to show. */
    previous?: () => void
}

export type AdditionalFilter<T> = WhereFilterWithPrefix<T> | OrderByFilterWithPrefix<T> | []

export type AdditionalFilterBuildingCollection<T extends BuildingCollection & WebCacheCollections> =
    AdditionalFilter<CollectionToType<T>>

export type WebFirestorePathAndSchema = Extract<
    FirestoreCollectionPathAndSchema,
    [["buildings" | "buildingGroups", ...any], any]
>

/** The parameters for the `useFirestoreCollection` hook. */
export type UseFirestoreCollectionParams<
    PathAndSchema extends WebFirestorePathAndSchema = WebFirestorePathAndSchema,
> = Parameters<typeof useFirestoreCollection<PathAndSchema>>[0]

/** Hook to get a Firestore collection. */
export function useFirestoreCollection<
    PathAndSchema extends WebFirestorePathAndSchema,
    Type extends z.infer<PathAndSchema[1]> = z.infer<PathAndSchema[1]>,
>(
    params:
        | undefined
        | {
              /**
               * Whether to actually fetch the data. Useful to delay fetching until a condition is met.
               * @default true
               */
              fetch?: boolean
              /** The path of the collection to fetch. */
              pathAndSchema: PathAndSchema | undefined
              /** Filters on the collection. */
              additionalFilters?: (
                  | WhereFilterWithPrefix<Type>
                  | OrderByFilterWithPrefix<Type>
                  | []
              )[]
              /**
               * The number of documents of fetch. If unspecified, there is no pagination. Careful,
               * as this can lead to very large fetches.
               */
              docsPerPage?: number
              /**
               * The order of the documents. Should match the order of the last `orderBy` in the `additionalFilter`.
               *
               * If `none`, we do not append a `orderBy("__name__")` clause. BE CAREFUL WHEN USING THIS.
               * @default "asc"
               */
              orderByName?: "asc" | "desc" | "none"
              /**
               * Whether to load the collection to the cache.
               * @default true
               */
              loadToCache?: boolean
              /** The permission to check before executing the query. */
              permissionKey?: PermissionKey
              /** The id of the function that is setting the collection.
               *
               * If not provided, we will replace all values for the given collection.
               * If provided, we will only update the values that have been set by the
               * function with the provided id.
               */
              setterId?: string
          }
): DataWithRef<Type> {
    const {
        fetch,
        pathAndSchema,
        additionalFilters,
        docsPerPage,
        orderByName,
        loadToCache,
        permissionKey,
        setterId,
    } = params ?? {}
    const { fiscalYear } = useAppParams()
    const dispatch = useAppDispatch()
    const [objects, setObjects] = useState<DataWithRef<any>["data"]>([])
    const [firstDocSnap, setFirstDocSnap] = useState<DocumentSnapshot | undefined>(undefined)
    const [lastDocSnap, setLastDocSnap] = useState<DocumentSnapshot | undefined>(undefined)
    const [paginationMethod, setPaginationMethod] = useState<
        | undefined
        | { method: "first"; snap: DocumentSnapshot | undefined }
        | { method: "last"; snap: DocumentSnapshot | undefined }
    >(undefined)
    /** Whether there are more documents to show when pressing `next`. */
    const [isNotLastPage, setIsNotLastPage] = useState(true)
    /** Whether there are more documents to show when pressing `previous`. */
    const [isNotFirstPage, setIsNotFirstPage] = useState(true)
    const next =
        isNotLastPage ?
            () => setPaginationMethod({ method: "first", snap: lastDocSnap })
        :   undefined
    const previous =
        isNotFirstPage ?
            () => setPaginationMethod({ method: "last", snap: firstDocSnap })
        :   undefined

    const q = useFirestoreQuery({
        fetch: fetch ?? true,
        pathAndSchema,
        additionalFilters: additionalFilters as any,
        orderByName,
        permissionKey,
        docsPerPage,
        paginationMethod,
    })

    debugHooks?.useTraceUpdate({
        pathAndSchema,
        q,
        docsPerPage,
        paginationMethod,
        fiscalYear,
        loadToCache,
        dispatch,
    })

    useEffect(() => {
        if (!pathAndSchema) return
        if (!q) {
            console.debug(`No query for ${pathAndSchema[0].join("/")}`)
            return
        }
        console.debug(`Fetching ${pathAndSchema[0].join("/")} with `, q)
        try {
            const [path, schema] = pathAndSchema
            const unsubscribe = onSnapshot(
                q.query,
                (snap) => {
                    const localObjects: DataWithRef<any>["data"] = []
                    if (!path || !schema) return
                    for (let i = 0, n = snap.docs.length; i < n; i++) {
                        const doc = snap.docs[i]
                        if (!doc) continue
                        const data = schema.safeParse(doc.data())
                        if (data.success) localObjects.push({ data: data.data, ref: doc.ref })
                        else
                            console.warn(
                                `Could not convert document data to object for ${path.join("/")}:`,
                                doc.data()
                            )
                    }
                    setFirstDocSnap(snap.docs[0])
                    setLastDocSnap(snap.docs[snap.docs.length - 1])
                    setObjects(localObjects)
                    if (loadToCache ?? true) {
                        const [root, rootId, coll] = path
                        dispatch(
                            setCollectionFromServer({
                                collection: coll as any,
                                values: localObjects.map((obj) => obj.data),
                                setterId,
                                root,
                                rootId: rootId as any,
                            } satisfies Parameters<typeof setCollectionFromServer>[0])
                        )
                    }
                },
                (error) =>
                    console.error(`Error in useFirestoreCollection for ${path.join("/")}`, error)
            )
            return () => {
                console.debug(`Unsubscribing from ${path.join("/")}`)
                unsubscribe()
                setObjects([])
                setFirstDocSnap(undefined)
                setLastDocSnap(undefined)
                if (loadToCache ?? true) {
                    const [root, rootId, coll] = path
                    dispatch(
                        setCollectionFromServer({
                            collection: coll as any,
                            values: [],
                            setterId,
                            root,
                            rootId: rootId as any,
                        } satisfies Parameters<typeof setCollectionFromServer>[0])
                    )
                }
            }
        } catch (error) {
            console.error(
                `An error came up while fetching the building's ${pathAndSchema[0].join("/")}:`,
                error
            )
        }
    }, [
        q,
        docsPerPage,
        paginationMethod,
        fiscalYear,
        loadToCache,
        dispatch,
        setterId,
        pathAndSchema,
    ])

    useEffect(() => {
        const unsubscribeIsNotFirstPage =
            docsPerPage && q ?
                onSnapshot(
                    query(
                        q.collQuery,
                        ...q.coreQuery,
                        limitToLast(1),
                        ...(firstDocSnap ? [endBefore(firstDocSnap)] : [])
                    ),
                    (snap) => setIsNotFirstPage(snap.docs.length > 0)
                )
            :   () => {}
        const unsubscribeIsNotLastPage =
            docsPerPage && q ?
                onSnapshot(
                    query(
                        q.collQuery,
                        ...q.coreQuery,
                        limit(1),
                        ...(lastDocSnap ? [startAfter(lastDocSnap)] : [])
                    ),
                    (snap) => setIsNotLastPage(snap.docs.length > 0)
                )
            :   () => {}
        return () => {
            unsubscribeIsNotFirstPage()
            unsubscribeIsNotLastPage()
        }
    }, [docsPerPage, q, firstDocSnap, lastDocSnap])

    return {
        data: objects,
        next,
        previous,
    } as DataWithRef<any>
}

const collectionsWithFiscalYear = [
    "units" as const,
    "banks" as const,
    "suppliers" as const,
    "people" as const,
]

function useFirestoreQuery({
    fetch,
    pathAndSchema,
    additionalFilters,
    orderByName,
    permissionKey,
    docsPerPage,
    paginationMethod,
}: Pick<
    Exclude<UseFirestoreCollectionParams, undefined>,
    | "pathAndSchema"
    | "orderByName"
    | "permissionKey"
    | "docsPerPage"
    | "fetch"
    | "additionalFilters"
> & {
    paginationMethod:
        | { method: "first"; snap: DocumentSnapshot | undefined }
        | { method: "last"; snap: DocumentSnapshot | undefined }
        | undefined
}) {
    const { fiscalYear } = useAppParams()
    const hasPermission = useHasPermission(permissionKey)

    const fieldsToOrderBy = useMemo(
        () =>
            [
                ...((
                    additionalFilters as Widen<
                        typeof additionalFilters,
                        (WhereFilterWithPrefix<any> | OrderByFilterWithPrefix<any> | [])[]
                    >
                )?.map((filter) => {
                    if (filter[0] === "orderBy") return filter[1]
                    else return undefined
                }) ?? []),
                ...(orderByName !== "none" ? ["__name__"] : []),
            ].filter((f): f is Exclude<typeof f, undefined> => f !== undefined),
        [additionalFilters, orderByName]
    )

    const limitDocId = paginationMethod?.snap?.id
    const limitDoc = useDeepCompareMemoize(paginationMethod?.snap?.data())

    debugHooks?.useTraceUpdate({
        pathAndSchema,
        hasPermission,
        fetch,
        additionalFilters,
        orderByName,
        docsPerPage,
        limitDoc,
        paginationMethod,
        fieldsToOrderBy,
        limitDocId,
    })

    return useMemo(() => {
        if (!pathAndSchema) return undefined
        const [path0, path1, path2] = pathAndSchema[0]
        if (!path0 || !path1 || !path2 || !hasPermission || !fetch) return undefined
        const withFiscalYear = (
            collectionsWithFiscalYear as Widen<typeof collectionsWithFiscalYear, (typeof path2)[]>
        ).includes(path2)

        const coreQuery = [
            ...(withFiscalYear ? [where("fiscalYear", "==", fiscalYear)] : []),
            ...(
                additionalFilters?.map((filter) => {
                    if (filter[0] === "where") return where(filter[1], filter[2], filter[3])
                    else if (filter[0] === "orderBy") return orderBy(filter[1], filter[2])
                    else return undefined
                }) ?? []
            ).filter((f): f is Exclude<typeof f, undefined> => f !== undefined),
            // If we have no other filters, we do not append the orderBy("__name__") clause as it causes a bug.
            ...((
                orderByName !== "none" &&
                (withFiscalYear || additionalFilters?.length || docsPerPage)
            ) ?
                [orderBy("__name__", orderByName ?? "asc")]
            :   []),
        ]

        const collQuery = collection(db, path0, path1, path2)

        return {
            collQuery,
            coreQuery,
            query: query(
                collQuery,
                ...coreQuery,
                ...(docsPerPage && limitDoc && paginationMethod?.method === "first" ?
                    [
                        limit(docsPerPage),
                        startAfter(
                            ...fieldsToOrderBy.map((f) =>
                                f === "__name__" ? limitDocId : get(limitDoc, f as any)
                            )
                        ),
                    ]
                : docsPerPage && limitDoc && paginationMethod?.method === "last" ?
                    [
                        limitToLast(docsPerPage),
                        endBefore(
                            ...fieldsToOrderBy.map((f) =>
                                f === "__name__" ? limitDocId : get(limitDoc, f as any)
                            )
                        ),
                    ]
                : docsPerPage ? [limit(docsPerPage)]
                : [])
            ),
        }
    }, [
        pathAndSchema,
        hasPermission,
        fetch,
        fiscalYear,
        additionalFilters,
        orderByName,
        docsPerPage,
        limitDoc,
        paginationMethod?.method,
        fieldsToOrderBy,
        limitDocId,
    ])
}
