import z from "zod"
import { ID_PREFIXES, idSchemaGenerator } from "../Id"
import { mediumString } from "../BaseStrings"
import { postSchema } from "../Post"
import { addOpenAPI, zodRegex } from "../zodExtensions"

// TODO: add attachments to the post template

/** The schema for a post template id. */
export const postTemplateIdSchema = idSchemaGenerator(ID_PREFIXES.POST_TEMPLATE)

const postTemplateRecipientSchema = zodRegex<
    "all" | "parkings" | "lockers" | "owners" | "residents"
>(/^(all|parkings|lockers|owners|residents)$/)
export type PostTemplateRecipient = z.infer<typeof postTemplateRecipientSchema>

/** The schema for a post template.
 *
 * Injectable values:
 * - `$buildingName`: The name of the building.
 * - `$buildingAddress`: The address of the building.
 * - `$buildingPhone`: The phone number of the building.
 * - `$buildingEmail`: The email of the building.
 * - `$buildingLink`: A link to the building's
 */
export const postTemplateSchema = addOpenAPI(
    postSchema
        .pick({
            title: true,
            body: true,
            sendEmail: true,
        })
        .partial()
        .extend({
            /** The id of the post template. */
            id: postTemplateIdSchema,
            /** The name of the post template. */
            name: mediumString,
            /** The recipients of the post template. */
            recipients: z.array(postTemplateRecipientSchema).optional(),
        }),
    "PostTemplate"
)
export type PostTemplate = z.infer<typeof postTemplateSchema>
