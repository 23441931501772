import { Building } from "../@appnflat-types/Building"
import { DeepPartial } from "../@appnflat-types/helpers"
import { Transaction } from "../@appnflat-types/Transaction"
import { Role } from "../@constants/Role"
import { LocalizedString } from "../@appnflat-types/types"
import { AidString } from "../@appnflat-types/BaseStrings"

export class FeatureFlags {
    protected building: Building

    constructor(building: Building) {
        this.building = building
    }

    /** Whether the building is integrated with Otonom. */
    public get otonom(): boolean {
        return !!this.building.otonomId
    }

    /** Whether the building should automatically send transactions of type `supplierPayment`
     * and `recurringBillPayment` to Otonom. */
    public get automaticallySendToOtonom(): boolean {
        return this.otonom && !!this.building.otonomAutomaticallySend
    }

    /** Whether transactions can be approved. */
    public get approval(): boolean {
        return !!this.building.numberOfApprovalsRequired
    }

    /** Whether a transaction requires approval. */
    public transactionRequiresApproval(transaction: DeepPartial<Transaction> | undefined): boolean {
        return (
            this.approval &&
            !!transaction &&
            transaction.kind === "supplierPayment" &&
            // The approval is mandatory if the payment method requires approval.
            ((!!this.building.paymentMethodsRequiringApproval?.length &&
                !!transaction.paymentMethod &&
                this.building.paymentMethodsRequiringApproval.includes(
                    transaction.paymentMethod
                )) ||
                // The approval can be requested if a user requests it.
                !!transaction.requestedApproval)
        )
    }

    /** Returns a list of the roles in the building that receive alerts when certain
     * automated operations fail. */
    public get rolesThatReceiveAlerts(): [Role.admin] | [Role.admin, Role.approverWrite] {
        if (this.building.rolesThatReceiveAlerts?.includes(Role.approverWrite)) {
            return [Role.admin, Role.approverWrite]
        } else {
            return [Role.admin]
        }
    }

    /** Whether a check for a `supplierPayment` can be printed.
     *
     * @returns `false` if the check cannot be printed, or an object with the following properties:
     * - `withSignature`: whether the check should include the signatures of the approvers.
     */
    public checkCanBePrinted(transaction: DeepPartial<Transaction> | undefined):
        | {
              allowed: true
              /** The number of signatures to include. */
              numberOfSignatures: number
              /** The AID of the supplier being paid. */
              supplierAID: AidString
          }
        | {
              allowed: false
              reason: {
                  name: string
                  localizedMessage: LocalizedString
              }
          } {
        if (
            !transaction ||
            transaction.kind !== "supplierPayment" ||
            transaction.paymentMethod !== "check"
        )
            return {
                allowed: false,
                reason: {
                    name: "TransactionNotSupplierPaymentByCheck",
                    localizedMessage: {
                        en: "The transaction is not a supplier payment with the payment method 'check'.",
                        fr: "La transaction n'est pas un paiement de fournisseur avec le mode de paiement 'chèque'.",
                    },
                },
            }
        const transactionRequiresApproval = this.transactionRequiresApproval(transaction)
        const approved =
            !transactionRequiresApproval ||
            ("approvalStatus" in transaction && transaction.approvalStatus === "approved")
        if (!approved)
            return {
                allowed: false,
                reason: {
                    name: "TransactionNotApproved",
                    localizedMessage: {
                        en: "The transaction is not approved.",
                        fr: "La transaction n'est pas approuvée.",
                    },
                },
            }
        const paidSuppliers = new Set(transaction.paidInvoices?.map((invoice) => invoice?.supplier))
        const supplierAID = transaction.paidInvoices?.[0]?.supplier
        if (paidSuppliers.size !== 1 || !supplierAID)
            return {
                allowed: false,
                reason: {
                    name: "MultipleSuppliers",
                    localizedMessage: {
                        en: "The transaction pays multiple suppliers.",
                        fr: "La transaction paie plusieurs fournisseurs.",
                    },
                },
            }
        return {
            allowed: true,
            supplierAID,
            // If the transaction was approved when there were fewer approvers required than today,
            // we only include the number of signatures that were required at the time of approval.
            // We are certain the transaction was approved as we checked it above.
            numberOfSignatures:
                !transactionRequiresApproval ? 0 : (
                    Math.min(
                        this.building.numberOfApprovalsRequired ?? 0,
                        "approvedBy" in transaction ? (transaction.approvedBy?.length ?? 0) : 0
                    )
                ),
        }
    }
}
