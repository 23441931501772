import { z, ZodTypeAny } from "zod"
import { DeepPartial } from "./helpers"
import { buildingSchema } from "./Building"
import { bankSchema } from "./Bank"
import { categorySchema } from "./Category"
import { unitSchema } from "./Unit"
import { supplierSchema } from "./Supplier"
import { lockerSchema } from "./Locker"
import { parkingSchema } from "./Parking"
import { personSchema } from "./Person"
import { penaltySchema } from "./Penalty"
import { transactionSchema } from "./Transaction"
import { postSchema } from "./Post"
import { budgetSchema } from "./Budget"
import { checkSchema } from "./Check"
import { preparedOtonomFileSchema } from "./PreparedOtonomFile"
import { buildingUserSchema } from "./BuildingUser"
import { invitedUserSchema } from "./InvitedUser"
import { otonomBatchSchema } from "./OtonomBatch"
import { emailTemplateSchema } from "./EmailTemplate"
import { unitCountsSchema } from "./Metadata"
import { requestSchema } from "./Request"
import { requestTagSchema } from "./BuildingGroup/RequestTag"
import { encryptedBankAccountSchema } from "./EncryptedBankAccount"
import { requestEmailDocumentSchema } from "./RequestEmail"
import { logSchema } from "./Log"
import { dbLockSchema } from "./DBLock"
import { creditNoteSchema } from "./CreditNote"
import { buildingGroupSchema } from "./BuildingGroup/BuildingGroup"
import { postTemplateSchema } from "./BuildingGroup/PostTemplate"
import { changeRequestSchema } from "./Building/ChangeRequest"
import { requestEmailTemplateSchema } from "./BuildingGroup/RequestEmailTemplate"
import { buildingGroupUserSchema } from "./BuildingGroup/BuildingGroupUser"
import { buildingGroupInvitedUserSchema } from "./BuildingGroup/BuildingGroupInvitedUser"

/** The set of child collections of a building. */
export type Collection =
    | "banks"
    | "budgets"
    | "categories"
    | "checks"
    | "creditNotes"
    | "emailTemplates"
    | "invitedUsers"
    | "lockers"
    | "locks"
    | "logs"
    | "otonomBatches"
    | "parkings"
    | "people"
    | "penalties"
    | "preparedOtonomFiles"
    | "posts"
    | "postTemplates"
    | "suppliers"
    | "transactions"
    | "unreconciledTransactions"
    | "units"
    | "users"
    | "requests"
    | "requestEmails"
    | "metadata"
    | "requestTags"
    | "secretsBankAccounts"
    | "changeRequests"
    | "requestEmailTemplates"

/** An object with root collections as keys, with each child collection as a key in the object mapping to the schema of the collection.
 * The `_` key maps to the schema of the root collection.
 *
 * @example
 * ```ts
 * collectionToSchema.buildings._ // buildingSchema
 * collectionToSchema.buildings.banks // bankSchema
 * collectionToSchema.buildings.users // buildingUserSchema
 * collectionToSchema.buildingGroups.users // buildingGroupUserSchema
 * ```
 */
export const collectionToSchema = {
    buildings: {
        _: buildingSchema,
        banks: bankSchema,
        budgets: budgetSchema,
        categories: categorySchema,
        changeRequests: changeRequestSchema,
        checks: checkSchema,
        creditNotes: creditNoteSchema,
        emailTemplates: emailTemplateSchema,
        invitedUsers: invitedUserSchema,
        lockers: lockerSchema,
        locks: dbLockSchema,
        logs: logSchema,
        metadata: unitCountsSchema,
        otonomBatches: otonomBatchSchema,
        parkings: parkingSchema,
        penalties: penaltySchema,
        people: personSchema,
        preparedOtonomFiles: preparedOtonomFileSchema,
        posts: postSchema,
        postTemplates: postTemplateSchema,
        requestEmails: requestEmailDocumentSchema,
        requestEmailTemplates: requestEmailTemplateSchema,
        requestTags: requestTagSchema,
        requests: requestSchema,
        secretsBankAccounts: encryptedBankAccountSchema,
        suppliers: supplierSchema,
        transactions: transactionSchema,
        unreconciledTransactions: transactionSchema,
        units: unitSchema,
        users: buildingUserSchema,
    },
    buildingGroups: {
        _: buildingGroupSchema,
        users: buildingGroupUserSchema,
        postTemplates: postTemplateSchema,
        requestEmailTemplates: requestEmailTemplateSchema,
        requestTags: requestTagSchema,
        invitedUsers: buildingGroupInvitedUserSchema,
    },
}

/** Given a collection and root collection name, return the type of the collection.
 *
 * If no root collection is provided, we assume the collection is a building collection.
 *
 * @example
 * ```ts
 * CollectionToType<"units", "buildings"> // Unit
 * CollectionToType<"users", "buildingGroups"> // BuildingGroupUser
 * CollectionToType<"users", "buildings"> // BuildingUser
 * CollectionToType<"users"> // BuildingUser
 * ```
 */
export type CollectionToType<
    C extends keyof (typeof collectionToSchema)[Root],
    Root extends RootCollection = "buildings",
> =
    (typeof collectionToSchema)[Root][C] extends ZodTypeAny ?
        z.infer<(typeof collectionToSchema)[Root][C]>
    :   never
// export type CollectionToType<
//     C extends keyof (typeof collectionToSchema)[Root],
//     Root extends RootCollection = "buildings",
// > = (typeof collectionToSchema)[Root][C] extends z.ZodType<infer T> ? Extract<T, object> : never

// export function parse<
//     C extends keyof (typeof collectionToSchema)[Root],
//     Root extends RootCollection = "buildings",
// >(
//     data: any,
//     collection: C,
//     rootCollection: Root = "buildings" as Root
// ): CollectionToType<C, Root> | undefined {
//     const collections = collectionToSchema[rootCollection]
//     const schema = collections[collection as keyof typeof collections] as z.ZodType<
//         CollectionToType<C, Root>
//     >
//     const result = schema.safeParse(data)
//     return result.success ? result.data : undefined
// }

/** The root collections of the database. */
export type RootCollection = keyof typeof collectionToSchema
/** The collections of a building. */
export type BuildingCollection = Exclude<keyof (typeof collectionToSchema)["buildings"], "_">
/** The collections of a building group. */
export type BuildingGroupCollection = Exclude<
    keyof (typeof collectionToSchema)["buildingGroups"],
    "_"
>

/*****************************************************************************
 * DEPRECATED
 *
 * The types below are deprecated and will be removed in a future version.
 *****************************************************************************/

/**
 * Maps collection names to their corresponding types.
 *
 * @deprecated Use {@link CollectionToType} instead.
 */
export type TypeByCollection = {
    [C in Collection]: CollectionToType<C>
}

/** An object with collection names (e.g., `units` or `banks`) as keys and a list of
 * corresponding objects as values (e.g., an array of three units for the `units` key).
 *
 * @deprecated Use {@link CollectionToType} instead.
 */
export type ArrayOfTypeByCollection = {
    [C in keyof TypeByCollection]: TypeByCollection[C][]
}

/** An object containing a collection name and a partial object of that collection type. */
export type CollectionAndPartialObject = {
    [K in keyof TypeByCollection]: {
        collection: K
        value: DeepPartial<TypeByCollection[K]>
    }
}[keyof TypeByCollection]

/** An object containing a collection name and an object of that collection type. */
export type CollectionAndObject = {
    [K in keyof TypeByCollection]: {
        collection: K
        value: TypeByCollection[K]
    }
}[keyof TypeByCollection]

/**
 * An object containing a collection name and a list of objects of that collection type.
 */
export type CollectionAndObjectList = {
    [K in keyof TypeByCollection]: {
        collection: K
        values: TypeByCollection[K][]
    }
}[keyof TypeByCollection]
