import { z } from "zod"
import { dineroStorableSchema, fiscalYearSchema } from "./Common"
import { aidString } from "./BaseStrings"
import { addOpenAPI } from "./zodExtensions"
import { supplierUUIDSchema, transactionUUIDSchema } from "./Id"

export const otonomFileIdSchema = addOpenAPI(
    z.discriminatedUnion("kind", [
        z.object({
            kind: z.literal("units"),
        }),
        z.object({
            kind: z.literal("suppliers"),
            /** The UUID of the supplier. */
            supplierUUID: supplierUUIDSchema,
            /** The fiscal year of the unit or supplier. */
            fiscalYear: fiscalYearSchema,
        }),
    ]),
    "OtonomFileId"
)
export type OtonomFileId = z.infer<typeof otonomFileIdSchema>

export const preparedOtonomFileSchema = addOpenAPI(
    otonomFileIdSchema.and(
        z.object({
            rows: z.array(
                z.object({
                    aid: aidString,
                    amount: dineroStorableSchema,
                    date: z.number(),
                    /** The UUID of the transaction. */
                    uuid: transactionUUIDSchema,
                    /** The fiscal year of the transaction. */
                    fiscalYear: fiscalYearSchema,
                    /** A map from the IDs of the invoice transactions document this transaction is paying to the amount of that transaction that is being paid. */
                    invoiceTransactions: z.record(dineroStorableSchema).optional(),
                })
            ),
            /** The expiration of the document as a Firestore timestamp. */
            expirationTimestamp: z.any(),
            expirationSeconds: z.number(),
            nTransactionsWithError: z.number(),
            locked: z.boolean().optional(),
        })
    ),
    "PreparedOtonomFile"
)
export type PreparedOtonomFile = z.infer<typeof preparedOtonomFileSchema>
