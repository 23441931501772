import { useMemo } from "react"
import { usePermissions } from "./hooks"
import { Role } from "@constants/Role"
import { BuildingGroupUserRole } from "@appnflat-types/BuildingGroup/BuildingGroupUser"

const buildingFinancialStaff = [
    Role.approver,
    Role.approverWrite,
    Role.admin,
    Role.accountant,
    Role.accountantWrite,
]
const buildingFinancialWriteStaff = [Role.approverWrite, Role.admin, Role.accountantWrite]
const buildingRequestsStaff = [Role.approverWrite, Role.admin, Role.handler]
const buildingGroupRequestsStaff = [BuildingGroupUserRole.admin, BuildingGroupUserRole.handler]
const buildingMember = [Role.owner, Role.resident]
const buildingAdmin = [Role.approverWrite, Role.admin]
const buildingGroupAdmin = [BuildingGroupUserRole.admin]

const permissionsCheck = {
    "accounts.read": { building: buildingFinancialStaff },
    "transactions.read": {
        building: [
            Role.approver,
            Role.approverWrite,
            Role.admin,
            Role.accountant,
            Role.accountantWrite,
            Role.owner,
        ],
    },
    "credit-notes.read.all": { building: buildingFinancialStaff },
    "transactions.write": { building: buildingFinancialWriteStaff },
    "people.read.all": { building: buildingFinancialStaff },
    "people.read.self": { building: buildingMember },
    "social.read": {
        building: [Role.approver, Role.approverWrite, Role.admin, Role.owner, Role.resident],
    },
    "requests.emails": { building: buildingRequestsStaff },
    "requests.create.for_self": { building: buildingMember },
    "requests.handler": {
        building: buildingRequestsStaff,
        buildingGroup: buildingGroupRequestsStaff,
    },
    "requests.tags.edit": { building: buildingRequestsStaff },
    "budgets.edit": { building: buildingFinancialWriteStaff },
    "budgets.view": {
        building: [
            Role.approver,
            Role.approverWrite,
            Role.admin,
            Role.accountant,
            Role.accountantWrite,
            Role.owner,
        ],
    },
    "units.close_account": { building: buildingFinancialWriteStaff },
    "posts.templates": { building: buildingAdmin, buildingGroup: buildingGroupAdmin },
}

export type PermissionKey = keyof typeof permissionsCheck | undefined

/** A custom hook that returns whether the user has a given permission. */
export function useHasPermission(permissionKey: PermissionKey) {
    const { userRole, buildingGroupUserRole } = usePermissions()

    return useMemo(() => {
        if (!permissionKey) return true
        const check = permissionsCheck[permissionKey]
        return (
            (!!userRole && check.building.includes(userRole)) ||
            (!!buildingGroupUserRole &&
                "buildingGroup" in check &&
                Array.isArray(check.buildingGroup) &&
                check.buildingGroup.includes(buildingGroupUserRole))
        )
    }, [permissionKey, userRole, buildingGroupUserRole])
}
