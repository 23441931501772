import { z } from "zod"
import { accountSchema, fiscalYearSchema } from "./Common"
import { mediumString } from "./BaseStrings"
import { addOpenAPI } from "./zodExtensions"
import { categoryUUIDSchema } from "./Id"

export const CATEGORY_PARENTS = [
    "assets",
    "liabilities",
    "capital",
    "revenues",
    "expenses",
    "accounts-payable",
    "accounts-receivable",
] as const
/** All the possible parents for a category. */
export type CategoryParent = (typeof CATEGORY_PARENTS)[number]

export const categorySchema = addOpenAPI(
    accountSchema.extend({
        uuid: categoryUUIDSchema,
        fiscalYear: fiscalYearSchema,
        archived: z.boolean().optional(),
        /** The date of the last edit. */
        lastModified: z.number().optional(),
        name: mediumString.optional(),
        /** The kind of the parent. */
        parent: z.enum(CATEGORY_PARENTS).optional(),
    }),
    "Category"
)
export type Category = z.infer<typeof categorySchema>
