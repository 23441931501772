import { z } from "zod"
import { dineroStorableSchema } from "./Common"
import { aidString, uidString, uuidString } from "./BaseStrings"
import { addOpenAPI } from "./zodExtensions"

export const otonomBatchSchema = addOpenAPI(
    z.object({
        date: z.number(),
        uuid: uuidString,
        kind: z.enum(["units", "suppliers"]),
        totalAmount: dineroStorableSchema,
        /** The uid of the user who created this batch.
         *
         * If the batch was automatically sent, the value is `automated`.
         */
        uidOfCreator: z.union([uidString, z.literal("automated")]).optional(),
        rows: z.array(
            z.object({
                /** The aid of the subtransaction. */
                aid: aidString,
                /** The amount of the subtransaction. */
                amount: dineroStorableSchema,
                /** The date of the transaction. */
                date: z.number(),
                /** The uuid of the transaction. */
                uuid: uuidString,
            })
        ),
    }),
    "OtonomBatch"
)
export type OtonomBatch = z.infer<typeof otonomBatchSchema>
