import { z } from "zod"
import { mediumString, shortString, veryShortString } from "./BaseStrings"
import { addOpenAPI } from "./zodExtensions"

export const addressSchema = addOpenAPI(
    z.object({
        city: mediumString.optional(),
        country: shortString.optional(),
        street: mediumString.optional(),
        zip: veryShortString.optional(),
        state: shortString.optional(),
    }),
    "Address"
)
export type Address = z.infer<typeof addressSchema>
