import { z } from "zod"
import { addOpenAPI, zodRegex } from "./zodExtensions"
import { ParsingErrors } from "./parsingErrors"
import { EMAIL_MAX_LENGTH, PHONE_MAX_LENGTH } from "./Constants"

export const aidMaxLength = 10
/** A string used to represent an `aid`. */
export const aidString = addOpenAPI(
    zodRegex<`${number}` | `${number}.${number}`>(/^\d+\.\d+$|^\d+$/, {
        message: ParsingErrors.should_be_decimal_positive_number,
        max: aidMaxLength,
    }),
    "AidString"
)
export type AidString = z.infer<typeof aidString>
/** A string used to represent a user's uid. */
export const uidString = addOpenAPI(z.string().max(30).brand<"UidString">(), [
    "UidString",
    { format: "UidString" },
])
export type UidString = z.infer<typeof uidString>
/** A string used to represent a `uuid`. */
export const uuidString = addOpenAPI(z.string().max(40), "UuidString")
/** A string used to represent a `buildingRef`. */
export const buildingRefString = addOpenAPI(z.string().min(1).max(30).brand<"BuildingRef">(), [
    "BuildingRef",
    { format: "BuildingRef" },
])
export type BuildingRef = z.infer<typeof buildingRefString>

/** A string used to represent an email.
 *
 * Verifies that the string is a valid email address and that it is not too long.
 */
export const emailString = addOpenAPI(
    z
        .string()
        .email({ message: ParsingErrors.invalid_email_address })
        .max(EMAIL_MAX_LENGTH, { message: ParsingErrors.email_too_long })
        .brand<"EmailString">(),
    ["EmailString", { format: "EmailString" }]
)
export type EmailString = z.infer<typeof emailString>
/** A string used to represent a phone number. */
export const phoneString = addOpenAPI(
    z
        .string()
        .max(PHONE_MAX_LENGTH, { message: ParsingErrors.phone_too_long })
        .brand<"PhoneString">(),
    ["PhoneString", { format: "PhoneString" }]
)
export type PhoneString = z.infer<typeof phoneString>
/**
 * A very short string (language, zip code, floor).
 *
 * Max: 10 chars.
 */
export const veryShortString = addOpenAPI(z.string().max(10), "VeryShortString")
/**
 * A short string (country, state, number, bank account type).
 *
 * Max: 30 chars.
 */
export const shortString = addOpenAPI(z.string().max(30), "ShortString")
/**
 * A short string (title, subject, description, name, city, ...).
 *
 * Max: 80 chars.
 */
export const mediumString = addOpenAPI(z.string().max(80), "MediumString")
/**
 * A long string, such as notes.
 *
 * __DOES NOT include markdown__.
 * Max: 2,000 chars.
 */
export const longString = addOpenAPI(z.string().max(2000), "LongString")
/**
 * A long string, such as notes.
 *
 * Max: 2,000 chars.
 */
export const shortMarkdownString = addOpenAPI(z.string().max(2000), "ShortMarkdownString")
/**
 * A long string, such as email or post body.
 *
 * Max: 10,000 chars.
 */
export const longMarkdownString = addOpenAPI(z.string().max(10000), "LongMarkdownString")
/**
 * A very long html string.
 *
 * Max: 260,000 chars.
 */
export const veryLongHtmlString = addOpenAPI(z.string().max(260000), "VeryLongHtmlString")
/**
 * A very long string, such as email or post body.
 *
 * Max: 260,000 chars.
 */
export const veryLongMarkdownString = addOpenAPI(z.string().max(260000), "VeryLongMarkdownString")
