import { z } from "zod"
import { emailString, mediumString, uidString } from "./BaseStrings"
import { localeSchema } from "./Language"
import { addOpenAPI } from "./zodExtensions"

export const globalUserSchema = addOpenAPI(
    z.object({
        name: mediumString.optional(),
        uid: uidString,
        email: emailString,
        /** Whether the user has uploaded a signature. */
        hasSignature: z.boolean().optional(),
        /** The preferred locale of the user. */
        locale: localeSchema.optional(),
    }),
    "GlobalUser"
)
export type GlobalUser = z.infer<typeof globalUserSchema>
