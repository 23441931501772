import z from "zod"
import { addOpenAPI } from "./zodExtensions"

/** A schema of the data describing a file. */
export const fileUploadSchema = addOpenAPI(
    z.object({
        /** The document data, as a base64 encoded Data URL.
         * @example "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAABaElEQVR42mNk"
         */
        dataURL: z.string(),
        /** The MIME type of the file. */
        mimeType: z.string(),
        /** The name of the file, including the extension. This is a user-facing value. */
        fileName: z.string(),
    }),
    "FileUpload"
)
/** The data describing a file. */
export type FileUpload = z.infer<typeof fileUploadSchema>

/** A schema of the data describing a file, but only for PDF files. */
export const fileUploadSchemaOnlyPDF = addOpenAPI(
    fileUploadSchema.extend({
        /** The document data, as a base64 encoded Data URL.
         * @example "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAABaElEQVR42mNk"
         */
        dataURL: z.string(),
        /** The MIME type of the file (only "application/pdf"). */
        mimeType: z.literal("application/pdf"),
        /** The name of the file, including the extension. This is a user-facing value. */
        fileName: z.string().endsWith(".pdf"),
    }),
    "FileUploadOnlyPDF"
)
/** The data describing a PDF file. */
export type FileUploadOnlyPDF = z.infer<typeof fileUploadSchemaOnlyPDF>
