import z from "zod"
import { ID_PREFIXES, idSchemaGenerator, personUUIDSchema, unitUUIDSchema } from "../Id"
import { personSchema } from "../Person"
import { baseUnitSchema } from "../Unit"
import { shortMarkdownString, uidString } from "../BaseStrings"
import { addOpenAPI } from "../zodExtensions"

export const changeRequestIdSchema = idSchemaGenerator(ID_PREFIXES.CHANGE_REQUEST)
export type ChangeRequestId = z.infer<typeof changeRequestIdSchema>

const personChangeFieldsSchema = personSchema
    .pick({
        address: true,
        // We do not allow users to request changes to their emails, as it poses a security risk
        firstName: true,
        lastName: true,
        phones: true,
        locale: true,
        gender: true,
    })
    .extend({ emergencyContact: personSchema.shape.emergencyContact.unwrap().partial() })
    .partial()
const unitChangeFieldsSchema = z
    .object({
        waterHeater: baseUnitSchema.shape.waterHeater.unwrap().partial(),
        insurance: baseUnitSchema.shape.insurance.unwrap().partial(),
    })
    .partial()

const coreChangeRequestSchema = z.object({
    id: changeRequestIdSchema,
    /** Timestamp when the change was requested. */
    requestedAt: z.number(),
    /** The uid of the user who requested the change. */
    requestedBy: uidString,
    /** Reason for the change. */
    reason: shortMarkdownString.optional(),
})

const coreCRUnitSchema = z.object({
    kind: z.literal("units"),
    /** The uuid of the unit being changed */
    unitUUID: unitUUIDSchema,
    /** New values for the changed fields */
    changes: unitChangeFieldsSchema,
})
export const changeRequestUnitSchema = coreChangeRequestSchema.merge(coreCRUnitSchema)

const coreCRPersonSchema = z.object({
    kind: z.literal("people"),
    /** The uuid of the person being changed */
    personUUID: personUUIDSchema,
    /** New values for the changed fields */
    changes: personChangeFieldsSchema,
})
export const changeRequestPersonSchema = coreChangeRequestSchema.merge(coreCRPersonSchema)

/** A change requested to the database's content by a unit owner. */
export const changeRequestSchema = addOpenAPI(
    z.discriminatedUnion("kind", [changeRequestUnitSchema, changeRequestPersonSchema]),
    "ChangeRequest"
)
/** A change requested to the database's content by a unit owner. */
export type ChangeRequest = z.infer<typeof changeRequestSchema>
