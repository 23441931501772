import { z } from "zod"
import {
    accountWithRecurringBillsSchema,
    accountWithRecurringBillsValidator,
    accountWithoutRecurringBillsSchema,
} from "./RecurringBill"
import {
    accountSchema,
    fiscalYearSchema,
    objectWithEncryptedBankAccountDetailsSchema,
    phonesSchema,
} from "./Common"
import { addressSchema } from "./Address"
import { PAYMENT_METHODS } from "../@constants/PaymentMethod"
import { aidString, emailString, mediumString, shortMarkdownString } from "./BaseStrings"
import { addOpenAPI } from "./zodExtensions"
import { SUPPLIER_MAX_NUMBER_OF_EMAILS } from "./Constants"
import { ParsingErrors } from "./parsingErrors"
import { supplierUUIDSchema } from "./Id"

/** A supplier without recurring bills.
 * WARNING: this should almost never be used! Use `supplierSchema` instead.
 */
export const baseSupplierSchema = accountSchema
    .merge(objectWithEncryptedBankAccountDetailsSchema)
    .extend({
        uuid: supplierUUIDSchema,
        fiscalYear: fiscalYearSchema,
        archived: z.boolean().optional(),
        /** The date of the last edit. */
        lastModified: z.number().optional(),
        address: addressSchema.optional(),
        emails: z
            .array(emailString)
            .max(SUPPLIER_MAX_NUMBER_OF_EMAILS, { message: ParsingErrors.too_many_emails })
            .optional(),
        phones: phonesSchema.optional(),
        name: mediumString.optional(),
        /** Default counterpart's aid for new transactions. */
        category: aidString.optional(),
        notes: shortMarkdownString.optional(),
        paymentMethod: z.enum(PAYMENT_METHODS).optional(),
    })

export const supplierSchema = addOpenAPI(
    z.union([
        baseSupplierSchema.merge(accountWithRecurringBillsSchema),
        baseSupplierSchema.merge(accountWithoutRecurringBillsSchema),
    ]),
    "Supplier"
).refine(accountWithRecurringBillsValidator)

export type Supplier = z.infer<typeof supplierSchema>
