import { Currency, Dinero } from "dinero.js"
import { Account } from "../@appnflat-types/Common"
import { sumAmountsByDate } from "./sumAmountsByDate"
import { absoluteValue, safeDineroFactory } from "./dineroExtensions"
import { DateTime } from "./dates"
import { Category } from "../@appnflat-types/Category"

/** The method used to calculate an account's balance. */
export enum BalanceCalculationMethod {
    /** `unit`: balance = due to syndicate = starting balance + debits (≈invoiced by syndicate) - credits (≈paid by unit) */
    unit = 1, // We start at one to prevent cases where we accidentally check `!method => !0 => false`
    /** `supplier`: balance = due to supplier = starting balance + credits (≈invoiced by supplier) - debits (≈paid by syndicate) */
    supplier,
    /** `bankOperatingAccount`: Show the bank balance as the inverse of how it would appear on a bank statement, which is how it is recorded in our system. balance = starting balance + credits - debits */
    bankOperatingAccount,
    /** `bankStatement`: Show the bank balance as it would appear on a bank statement. balance = -1 * (starting balance + credits - debits) */
    bankStatement,
    /** `categoryExpense`: balance = credit - debit */
    categoryExpense,
    /** `categoryRevenue`: balance = debit - credit */
    categoryRevenue,
    /** `SB+D-C`: balance = starting balance + debits - credits */
    SBPlusDMinusC,
    /** `SB+C-D`: balance = starting balance + credits - debits */
    SBPlusCMinusD,
    /** `D-C`: balance = debits - credits */
    DMinusC,
    /** `C-D`: balance = credits - debits */
    CMinusD,
}

function getSign(method: BalanceCalculationMethod) {
    switch (method) {
        case BalanceCalculationMethod.DMinusC:
        case BalanceCalculationMethod.SBPlusDMinusC:
        case BalanceCalculationMethod.unit:
        case BalanceCalculationMethod.categoryRevenue:
            return -1
        case BalanceCalculationMethod.CMinusD:
        case BalanceCalculationMethod.SBPlusCMinusD:
        case BalanceCalculationMethod.supplier:
        case BalanceCalculationMethod.bankOperatingAccount:
        case BalanceCalculationMethod.categoryExpense:
        case BalanceCalculationMethod.bankStatement:
            return 1
        default: {
            const _exhaustiveCheck: never = method
            throw new Error(`Unhandled balance calculation method: ${_exhaustiveCheck}`)
        }
    }
}

/**
 * Calculates the balance of an account.
 *
 * @param method The method used to calculate the balance. {@link BalanceCalculationMethod}
 */
export function calculateBalance(
    obj: Pick<Account, "startingBalance" | "credits" | "debits">,
    currency: Currency | undefined,
    method: BalanceCalculationMethod,
    { startDate, endDate }: { startDate?: DateTime; endDate?: DateTime } = {}
): Dinero {
    const ignoreSB = shouldIgnoreStartingBalance(method)
    const debits = sumAmountsByDate(obj.debits, currency, {
        startDate: ignoreSB ? startDate : undefined,
        endDate,
    })
    const credits = sumAmountsByDate(obj.credits, currency, {
        startDate: ignoreSB ? startDate : undefined,
        endDate,
    })

    let balance = safeDineroFactory(ignoreSB ? {} : obj.startingBalance, currency)
    const sign = getSign(method)
    balance = balance.add(credits.multiply(sign)).subtract(debits.multiply(sign))
    if (shouldInvertBalance(method)) balance = balance.multiply(-1)

    if (balance.isZero()) return absoluteValue(balance)
    return balance
}

/**
 * Adds an amount to a balance, using the method used to calculate the balance.
 *
 * @param balance The balance to add the amount to.
 * @param amount The amount to add to the balance.
 * @param method The method used to calculate the balance. {@link BalanceCalculationMethod}
 */
export function addAmountToBalance(
    balance: Dinero,
    amount: Dinero,
    method: BalanceCalculationMethod
) {
    const sign = getSign(method)
    return balance.add(amount.multiply(sign))
}

/**
 * Gets the balance calculation method for an account.
 *
 * @param collection The collection of the account.
 * @param account The account.
 */
export function getBalanceCalculationMethod(
    collection: "units" | "categories" | "banks" | "suppliers",
    account: Account | Category
) {
    switch (collection) {
        case "units":
            return BalanceCalculationMethod.unit
        case "suppliers":
            return BalanceCalculationMethod.supplier
        case "banks":
            return BalanceCalculationMethod.bankStatement
        case "categories": {
            const parent = "parent" in account ? account.parent : undefined
            if (!parent) return BalanceCalculationMethod.SBPlusCMinusD
            switch (parent) {
                case "revenues":
                    return BalanceCalculationMethod.categoryRevenue
                case "expenses":
                    return BalanceCalculationMethod.categoryExpense
                case "assets":
                case "liabilities":
                case "capital":
                case "accounts-payable":
                    return BalanceCalculationMethod.SBPlusCMinusD
                case "accounts-receivable":
                    // case "overpaid":
                    return BalanceCalculationMethod.SBPlusDMinusC
                default: {
                    const _exhaustiveCheck: never = parent
                    throw new Error(`Unhandled category parent: ${_exhaustiveCheck}`)
                }
            }
        }
        default: {
            const _exhaustiveCheck: never = collection
            throw new Error(`Unhandled collection: ${_exhaustiveCheck}`)
        }
    }
}

/**
 * Whether the balance should be inverted when calculating the balance.
 *
 * @param calculationMethod The method used to calculate the balance. {@link BalanceCalculationMethod}
 */
export function shouldInvertBalance(calculationMethod: BalanceCalculationMethod) {
    return calculationMethod === BalanceCalculationMethod.bankStatement
}

/**
 * Whether the starting balance should be ignored when calculating the balance.
 *
 * @param calculationMethod The method used to calculate the balance. {@link BalanceCalculationMethod}
 */
export function shouldIgnoreStartingBalance(calculationMethod: BalanceCalculationMethod) {
    return (
        calculationMethod === BalanceCalculationMethod.DMinusC ||
        calculationMethod === BalanceCalculationMethod.CMinusD ||
        calculationMethod === BalanceCalculationMethod.categoryRevenue ||
        calculationMethod === BalanceCalculationMethod.categoryExpense
    )
}
