import { ZodError, ZodInvalidTypeIssue, ZodIssue } from "zod"
import { getFieldDescription } from "./fieldDescription"
import { DateTime } from "../dates"
import {
    ParsingErrors,
    ParsingErrorsEN,
    ParsingErrorsFR,
} from "../../@appnflat-types/parsingErrors"

/** Format a Zod error into a localized string displayed to the user.
 *
 * @param error The error to describe.
 * @param lang The language to describe the error in.
 * @returns The description of the error in a user-friendly format.
 */
export function zodErrorDescription(error: ZodError, lang: "fr" | "en"): string {
    return pickGreatestPriorityIssue(error.issues, lang).message
}

const enum IssuePriority {
    /** The error is a custom error. This is the highest priority. */
    custom = 10,
    /** The error is a well-known parsing error. */
    parsing = 5,
    /** The error is a well-known enum error. */
    enum = 4,
    /** The error is a generic error. */
    generic = 0,
    /** The error is unknown. */
    unknown = -1,
}

type FormattedIssue = { message: string; priority: IssuePriority }

function unknownIssue(lang: "fr" | "en"): FormattedIssue {
    return {
        message: { fr: "Erreur inconnue", en: "Unknown error" }[lang],
        priority: IssuePriority.unknown,
    }
}

function pickGreatestPriorityIssue(
    issues: (ZodIssue | FormattedIssue)[],
    lang: "fr" | "en"
): FormattedIssue {
    return issues.reduce((greatest: FormattedIssue, current) => {
        const formattedIssue = "priority" in current ? current : zodIssueToMessage(current, lang)
        return formattedIssue.priority > greatest.priority ? formattedIssue : greatest
    }, unknownIssue(lang))
}

function zodIssueToMessage(issue: ZodIssue, lang: "fr" | "en"): FormattedIssue {
    if (issue.message in ParsingErrors) {
        const key = ParsingErrors[issue.message as keyof typeof ParsingErrors]
        return {
            message: lang === "fr" ? ParsingErrorsFR[key] : ParsingErrorsEN[key],
            priority: IssuePriority.custom,
        }
    }

    switch (issue.code) {
        case "invalid_union": {
            // We should pick the union case with the least amount of errors, then select the
            // greatest priority issue from that union case.
            const subErrors = issue.unionErrors
                .map(
                    (error) =>
                        [
                            error.issues.length,
                            pickGreatestPriorityIssue(error.issues, lang),
                        ] satisfies [number, FormattedIssue]
                )
                .sort((a, b) => {
                    // First, we sort by the highest priority issue.
                    if (a[1].priority !== b[1].priority) return b[1].priority - a[1].priority
                    // Then, we sort by the lowest number of issues.
                    return a[0] - b[0]
                })
            console.log(subErrors)

            return subErrors[0]?.[1] ?? unknownIssue(lang)
        }

        case "too_big": {
            switch (issue.type) {
                case "array":
                case "set": {
                    const fieldDescription = getFieldDescription(issue.path, {
                        fallback: { fr: "valeurs", en: "values" },
                    })
                    return {
                        message:
                            lang === "fr" ?
                                `Il y a trop de ${fieldDescription.fr}, le maximum autorisé est de ${issue.maximum}.`
                            :   `There are too many ${fieldDescription.en}. The maximum allowed is ${issue.maximum}.`,
                        priority: IssuePriority.parsing,
                    }
                }
                case "number":
                case "bigint": {
                    const fieldDescription = getFieldDescription(issue.path, {
                        withArticle: true,
                        capitalize: true,
                        fallback: { fr: "la valeur", en: "the value" },
                    })
                    return {
                        message:
                            lang === "fr" ?
                                `${fieldDescription.fr} est trop grand, le maximum est ${issue.maximum}.`
                            :   `${fieldDescription.en} is too large, the maximum is ${issue.maximum}.`,
                        priority: IssuePriority.parsing,
                    }
                }

                case "date": {
                    const fieldDescription = getFieldDescription(issue.path, {
                        withArticle: true,
                        capitalize: true,
                        fallback: { fr: "la valeur", en: "the value" },
                    })
                    return {
                        message:
                            lang === "fr" ?
                                `${fieldDescription.fr} est trop tardive, le plus tard possible est le ${new DateTime(Number(issue.maximum)).toString()}.`
                            :   `${fieldDescription.en} is too late, the latest possible date is ${new DateTime(Number(issue.maximum)).toString()}.`,
                        priority: IssuePriority.parsing,
                    }
                }

                case "string": {
                    const fieldDescription = getFieldDescription(issue.path, {
                        withArticle: true,
                        capitalize: true,
                        fallback: { fr: "la valeur", en: "the value" },
                    })
                    return {
                        message:
                            lang === "fr" ?
                                `${fieldDescription.fr} est trop long, le maximum est ${issue.maximum} caractères.`
                            :   `${fieldDescription.en} is too long, the maximum is ${issue.maximum} characters.`,
                        priority: IssuePriority.parsing,
                    }
                }
            }
        }

        case "too_small": {
            switch (issue.type) {
                case "array":
                case "set": {
                    const fieldDescription = getFieldDescription(issue.path, {
                        fallback: { fr: "valeurs", en: "values" },
                    })
                    return {
                        message:
                            lang === "fr" ?
                                `Il y a trop peu de ${fieldDescription.fr}. Il doit y avoir au moins ${issue.minimum} éléments.`
                            :   `There are too few ${fieldDescription.en}. It must contain at least ${issue.minimum} elements.`,
                        priority: IssuePriority.parsing,
                    }
                }

                case "date": {
                    const fieldDescription = getFieldDescription(issue.path, {
                        capitalize: true,
                        withArticle: true,
                        fallback: { fr: "la valeur", en: "the value" },
                    })
                    return {
                        message:
                            lang === "fr" ?
                                `${fieldDescription.fr} est trop tôt, le plus tôt possible est le ${new DateTime(Number(issue.minimum)).toString()}.`
                            :   `${fieldDescription.en} is too early, the earliest possible date is ${new DateTime(Number(issue.minimum)).toString()}.`,
                        priority: IssuePriority.parsing,
                    }
                }

                case "string": {
                    const fieldDescription = getFieldDescription(issue.path, {
                        capitalize: true,
                        withArticle: true,
                        fallback: { fr: "la valeur", en: "the value" },
                    })
                    return {
                        message:
                            lang === "fr" ?
                                `${fieldDescription.fr} est trop court, le minimum est de ${issue.minimum} caractères.`
                            :   `${fieldDescription.en} is too short, the minimum is ${issue.minimum} characters.`,
                        priority: IssuePriority.parsing,
                    }
                }

                case "number":
                case "bigint": {
                    const fieldDescription = getFieldDescription(issue.path, {
                        withArticle: true,
                        capitalize: true,
                        fallback: { fr: "la valeur", en: "the value" },
                    })
                    return {
                        message:
                            lang === "fr" ?
                                `${fieldDescription.fr} est trop petit, le minimum est ${issue.minimum}.`
                            :   `${fieldDescription.en} is too small, the minimum is ${issue.minimum}.`,
                        priority: IssuePriority.parsing,
                    }
                }
            }
        }

        case "invalid_string": {
            switch (issue.validation) {
                case "email": {
                    const fieldDescription = getFieldDescription(issue.path, {
                        withArticle: true,
                        fallback: { fr: "la valeur", en: "the value" },
                    })
                    return {
                        message:
                            lang === "fr" ?
                                `Le format de l'email est invalide pour ${fieldDescription.fr}.`
                            :   `The email format is invalid for ${fieldDescription.en}.`,
                        priority: IssuePriority.parsing,
                    }
                }

                case "url": {
                    const fieldDescription = getFieldDescription(issue.path, {
                        withArticle: true,
                        fallback: { fr: "la valeur", en: "the value" },
                    })
                    return {
                        message:
                            lang === "fr" ?
                                `Le format de l'URL est invalide pour ${fieldDescription.fr}.`
                            :   `The URL format is invalid for ${fieldDescription.en}.`,
                        priority: IssuePriority.parsing,
                    }
                }

                case "uuid":
                case "cuid":
                case "cuid2":
                case "ulid": {
                    const fieldDescription = getFieldDescription(issue.path, {
                        fallback: { fr: "champ", en: "field" },
                    })
                    return {
                        message:
                            lang === "fr" ?
                                `L'identifiant ${fieldDescription.fr} est invalide.`
                            :   `The identifier ${fieldDescription.en} is invalid.`,
                        priority: IssuePriority.parsing,
                    }
                }

                default: {
                    const fieldDescription = getFieldDescription(issue.path, {
                        fallback: { fr: "champ", en: "field" },
                    })
                    return {
                        message:
                            lang === "fr" ?
                                `Le format de ${fieldDescription.fr} est invalide.`
                            :   `The format of ${fieldDescription.en} is invalid.`,
                        priority: IssuePriority.parsing,
                    }
                }
            }
        }

        case "invalid_literal":
        case "invalid_enum_value": {
            const fieldDescription = getFieldDescription(issue.path, { withTrailingSpace: true })
            const options = "options" in issue ? issue.options.join(", ") : String(issue.expected)
            return {
                message:
                    lang === "fr" ?
                        `La valeur ${fieldDescription?.fr ?? ""}est invalide. Elle doit être un des éléments suivants : ${options}.`
                    :   `The value ${fieldDescription?.en ?? ""}is invalid. It must be one of the following: ${options}.`,
                priority: IssuePriority.enum,
            }
        }

        case "invalid_type": {
            const fieldDescription = getFieldDescription(issue.path, {
                withArticle: true,
                capitalize: true,
                fallback: { fr: "la valeur", en: "the value" },
            })
            if (issue.received === "undefined" || issue.received === "null") {
                return {
                    message:
                        lang === "fr" ?
                            `${fieldDescription.fr} est requis.`
                        :   `${fieldDescription.en} is required.`,
                    priority: IssuePriority.parsing,
                }
            }
            const isPrimaryType =
                issue.expected === "date" ||
                issue.expected === "boolean" ||
                issue.expected === "number" ||
                issue.expected === "bigint" ||
                issue.expected === "string" ||
                issue.expected === "nan" ||
                issue.expected === "float" ||
                issue.expected === "integer"
            return {
                message:
                    lang === "fr" ?
                        `${fieldDescription.fr} devrait être ${typeToDescription(issue.expected, lang)} mais c'est ${typeToDescription(issue.received, lang)}.`
                    :   `${fieldDescription.en} should be ${typeToDescription(issue.expected, lang)} but it is ${typeToDescription(issue.received, lang)}.`,
                priority: isPrimaryType ? IssuePriority.parsing : IssuePriority.generic,
            }
        }

        default: {
            const fieldDescription = getFieldDescription(issue.path, { withTrailingSpace: true })
            return {
                message:
                    lang === "fr" ?
                        `La valeur ${fieldDescription?.fr ?? ""}est invalide.`
                    :   `The value ${fieldDescription?.en ?? ""}is invalid.`,
                priority: IssuePriority.unknown,
            }
        }
    }
}

/** Convert a type to a description.
 *
 * @param type The type to convert.
 * @param lang The language to convert to.
 * @param withArticle Whether to include an article or determiner in the description.
 * @returns The description of the type.
 */
function typeToDescription(
    type: ZodInvalidTypeIssue["expected"],
    lang: "fr" | "en",
    withArticle: boolean = true
) {
    switch (type) {
        case "string":
            return (
                lang === "fr" ?
                    withArticle ? "du texte"
                    :   "texte"
                : withArticle ? "text"
                : "text"
            )

        case "float":
        case "bigint":
        case "number":
            return (
                lang === "fr" ?
                    withArticle ? "un nombre"
                    :   "nombre"
                : withArticle ? "a number"
                : "number"
            )

        case "integer":
            return (
                lang === "fr" ?
                    withArticle ? "un nombre entier"
                    :   "nombre entier"
                : withArticle ? "an integer number"
                : "integer number"
            )

        case "nan":
            return (
                lang === "fr" ?
                    withArticle ? "un nombre invalide"
                    :   "nombre invalide"
                : withArticle ? "an invalid number"
                : "invalid number"
            )

        case "boolean":
            return (
                lang === "fr" ?
                    withArticle ? "un booléen"
                    :   "booléen"
                : withArticle ? "a boolean"
                : "boolean"
            )

        case "void":
        case "null":
        case "undefined":
            return (
                lang === "fr" ?
                    withArticle ? "une valeur non définie"
                    :   "valeur non définie"
                : withArticle ? "an undefined value"
                : "undefined value"
            )

        case "map":
        case "object":
            return (
                lang === "fr" ?
                    withArticle ? "un objet"
                    :   "objet"
                : withArticle ? "an object"
                : "object"
            )

        case "date":
            return (
                lang === "fr" ?
                    withArticle ? "une date"
                    :   "date"
                : withArticle ? "a date"
                : "date"
            )

        case "array":
        case "set":
            return (
                lang === "fr" ?
                    withArticle ? "une liste"
                    :   "liste"
                : withArticle ? "a list"
                : "list"
            )

        case "never":
        case "unknown":
        case "promise":
        case "symbol":
        case "function":
        default:
            return (
                lang === "fr" ?
                    withArticle ? "une valeur inconnue"
                    :   "valeur inconnue"
                : withArticle ? "an unknown value"
                : "unknown value"
            )
    }
}
