import { LocalizedString } from "../../@appnflat-types/types"
import { objectDescriptionMap, ObjectType } from "./objectDescription"

const fieldDescriptionMap: Record<
    string,
    {
        /** The description of the field in French.
         * The first string is the description of the field without the article.
         * The second string is the description of the field with the article.
         */
        fr: [string, string]
        /** The description of the field in English.
         * The first string is the description of the field without the article.
         * The second string is the description of the field with the article.
         */
        en: [string, string]
        /** If true, the field can be skipped in the description. */
        skippable?: boolean
        /** If true, the field is the last field in the description. */
        terminal?: boolean
    }
> = {
    buildingRef: {
        fr: ["identifiant du bâtiment", "l'identifiant du bâtiment"],
        en: ["building reference", "the building reference"],
    },
    email: {
        fr: ["adresse courriel", "l'adresse courriel"],
        en: ["email", "the email"],
    },
    emails: {
        fr: ["adresses courriels", "les adresses courriels"],
        en: ["email addresses", "the email addresses"],
    },
    address: {
        fr: ["adresse", "l'adresse"],
        en: ["address", "the address"],
    },
    city: {
        fr: ["ville", "la ville"],
        en: ["city", "the city"],
    },
    country: {
        fr: ["pays", "le pays"],
        en: ["country", "the country"],
    },
    state: {
        fr: ["province", "la province"],
        en: ["state", "the state"],
    },
    zip: {
        fr: ["code postal", "le code postal"],
        en: ["zip code", "the zip code"],
    },
    street: {
        fr: ["rue", "la rue"],
        en: ["street", "the street"],
    },
    notes: {
        fr: ["notes", "les notes"],
        en: ["notes", "the notes"],
    },
    emergencyContact: {
        fr: ["contact d'urgence", "le contact d'urgence"],
        en: ["emergency contact", "the emergency contact"],
    },
    phone: {
        fr: ["numéro de téléphone", "le numéro de téléphone"],
        en: ["phone number", "the phone number"],
    },
    phones: {
        fr: ["numéros de téléphone", "les numéros de téléphone"],
        en: ["phone numbers", "the phone numbers"],
        terminal: true,
    },
    type: {
        fr: ["type", "le type"],
        en: ["type", "the type"],
    },
    kind: {
        fr: ["type", "le type"],
        en: ["type", "the type"],
    },
    locale: { en: ["language", "the language"], fr: ["langue", "la langue"] },
    name: { fr: ["nom", "le nom"], en: ["name", "the name"] },
    firstName: { fr: ["prénom", "le prénom"], en: ["first name", "the first name"] },
    lastName: { fr: ["nom de famille", "le nom de famille"], en: ["last name", "the last name"] },
    uid: { fr: ["identifiant", "l'identifiant"], en: ["identifier", "the identifier"] },
    uuid: { fr: ["identifiant", "l'identifiant"], en: ["identifier", "the identifier"] },
    supplierUUID: {
        fr: ["identifiant du fournisseur", "l'identifiant du fournisseur"],
        en: ["supplier identifier", "the supplier identifier"],
    },
    fiscalYear: {
        fr: ["année fiscale", "l'année fiscale"],
        en: ["fiscal year", "the fiscal year"],
    },
    number: { fr: ["numéro", "le numéro"], en: ["number", "the number"] },
    gender: { fr: ["sexe", "le sexe"], en: ["sex", "the sex"] },
    date: { fr: ["date", "la date"], en: ["date", "the date"] },
    amount: { fr: ["montant", "le montant"], en: ["amount", "the amount"] },
    invoice: { fr: ["facture", "la facture"], en: ["invoice", "the invoice"] },
    supplier: { fr: ["fournisseur", "le fournisseur"], en: ["supplier", "the supplier"] },
    unit: { fr: ["unité", "l'unité"], en: ["unit", "the unit"] },
    bank: { fr: ["banque", "la banque"], en: ["bank", "the bank"] },
    building: { fr: ["bâtiment", "le bâtiment"], en: ["building", "the building"] },
}

const pluralSuffixes = {
    en: new Map([
        ["one", "st"],
        ["two", "nd"],
        ["few", "rd"],
        ["other", "th"],
    ]),
    fr: new Map([
        ["one", "er"],
        ["two", "e"],
        ["few", "e"],
        ["other", "e"],
    ]),
}

/** Returns a human-readable description of a field.
 *
 * @param field - The field to describe.
 * @param withArticle - If true, the description will be prefixed with the appropriate article.
 * @param capitalize - If true, the first letter of the description will be capitalized.
 * @param fallback - The fallback description to use if the field is not found in the map.
 * @param withTrailingSpace - If true, the description will be suffixed with a space.
 * @returns A human-readable description of the field.
 *
 * @example
 * getFieldDescription("buildingRef").en // "building reference"
 * getFieldDescription("buildingRef").fr // "identifiant du bâtiment"
 * getFieldDescription("buildingRef", true).en // "the building reference"
 * getFieldDescription("buildingRef", true).fr // "l'identifiant du bâtiment"
 */
export function getFieldDescription(
    field: string | (string | number)[],
    options: {
        withArticle?: boolean
        capitalize?: boolean
        fallback: LocalizedString
        withTrailingSpace?: boolean
    }
): LocalizedString
export function getFieldDescription(
    field: string | (string | number)[],
    options?: {
        withArticle?: boolean
        capitalize?: boolean
        fallback?: LocalizedString
        withTrailingSpace?: boolean
    }
): LocalizedString | null
export function getFieldDescription(
    field: string | (string | number)[],
    {
        withArticle = false,
        capitalize = false,
        fallback,
        withTrailingSpace = false,
    }: {
        withArticle?: boolean
        capitalize?: boolean
        fallback?: LocalizedString
        withTrailingSpace?: boolean
    } = {}
): LocalizedString | null {
    if (field.length === 0) {
        if (fallback) return capitalizeLocalizedString(fallback, capitalize, withTrailingSpace)
        return null
    }
    const descriptions: { en: string[]; fr: string[] } = {
        en: [],
        fr: [],
    }
    const individualFields = Array.isArray(field) ? field : field.split(".")

    const pr = new Intl.PluralRules("en-US", { type: "ordinal" })
    const formatOrdinals = (n: number) => {
        const rule = pr.select(n)
        return {
            en: `${n}${pluralSuffixes.en.get(rule)}`,
            fr: `${n}${pluralSuffixes.fr.get(rule)}`,
        }
    }

    for (let i = 0; i < individualFields.length; i++) {
        const field = individualFields[i]
        if (field === undefined || field === "") continue
        const isNumber = typeof field === "number"
        if (isNumber || field.match(/^\d+$/)) {
            const index = (isNumber ? field : parseInt(field)) + 1
            const formatted = formatOrdinals(index)
            descriptions.en.push(formatted.en)
            descriptions.fr.push(formatted.fr)
        } else {
            const fieldDescription =
                fieldDescriptionMap[field] ?? objectDescriptionMap[field as ObjectType]
            if (!fieldDescription) {
                descriptions.en.push(field)
                descriptions.fr.push(field)
            } else if (
                typeof fieldDescription.en === "string" &&
                typeof fieldDescription.fr === "string"
            ) {
                descriptions.en.push(fieldDescription.en)
                descriptions.fr.push(fieldDescription.fr)
            } else {
                const index = withArticle && i === 0 ? 1 : 0
                descriptions.en.push(fieldDescription.en[index])
                descriptions.fr.push(fieldDescription.fr[index])
            }
        }
    }
    const local = {
        en: descriptions.en.join(" > "),
        fr: descriptions.fr.join(" > "),
    }
    return capitalizeLocalizedString(local, capitalize, withTrailingSpace)
}

/** Capitalize the first letter of a string.
 *
 * @param str - The string to capitalize.
 * @returns The capitalized string.
 */
function capitalizeLocalizedString(
    str: LocalizedString,
    capitalize: boolean,
    withTrailingSpace: boolean
): LocalizedString {
    let en = str.en
    let fr = str.fr
    if (capitalize) {
        en = en.charAt(0).toUpperCase() + en.slice(1)
        fr = fr.charAt(0).toUpperCase() + fr.slice(1)
    }
    if (withTrailingSpace) {
        en += " "
        fr += " "
    }
    return { en, fr }
}
