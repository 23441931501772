import { DeepPartialWithRequired } from "../@appnflat-types/helpers"
import { Bank } from "../@appnflat-types/Bank"
import { Budget } from "../@appnflat-types/Budget"
import { Building } from "../@appnflat-types/Building"
import { Category } from "../@appnflat-types/Category"
import { Check } from "../@appnflat-types/Check"
import { EmailTemplate } from "../@appnflat-types/EmailTemplate"
import { InvitedUser } from "../@appnflat-types/InvitedUser"
import { Locker } from "../@appnflat-types/Locker"
import { OtonomBatch } from "../@appnflat-types/OtonomBatch"
import { Parking } from "../@appnflat-types/Parking"
import { CustomPenalty, DefaultPenalty } from "../@appnflat-types/Penalty"
import { Person } from "../@appnflat-types/Person"
import { Post } from "../@appnflat-types/Post"
import { OtonomFileId } from "../@appnflat-types/PreparedOtonomFile"
import { Supplier } from "../@appnflat-types/Supplier"
import { Transaction } from "../@appnflat-types/Transaction"
import { Unit } from "../@appnflat-types/Unit"
import { BuildingUser } from "../@appnflat-types/BuildingUser"
import { MetadataDocuments } from "../@appnflat-types/FirestoreCollections"
import { Request } from "../@appnflat-types/Request"
import { RequestTag } from "../@appnflat-types/BuildingGroup/RequestTag"
import { RequestEmailDocument } from "../@appnflat-types/RequestEmail"
import { Log } from "../@appnflat-types/Log"
import { DBLock } from "../@appnflat-types/DBLock"
import { CreditNote } from "../@appnflat-types/CreditNote"
import { BuildingGroup } from "../@appnflat-types/BuildingGroup/BuildingGroup"
import { PostTemplate } from "../@appnflat-types/BuildingGroup/PostTemplate"
import { ChangeRequest } from "../@appnflat-types/Building/ChangeRequest"
import { RequestEmailTemplate } from "../@appnflat-types/BuildingGroup/RequestEmailTemplate"

export type IdForCollectionInput =
    | {
          collection: "units"
          value: DeepPartialWithRequired<Unit, "uuid" | "fiscalYear">
      }
    | {
          collection: "suppliers"
          value: DeepPartialWithRequired<Supplier, "uuid" | "fiscalYear">
      }
    | {
          collection: "banks"
          value: DeepPartialWithRequired<Bank, "uuid" | "fiscalYear">
      }
    | {
          collection: "people"
          value: DeepPartialWithRequired<Person, "uuid" | "fiscalYear">
      }
    | {
          collection: "categories"
          value: DeepPartialWithRequired<Category, "uuid" | "fiscalYear">
      }
    | {
          collection: "parkings"
          value: DeepPartialWithRequired<Parking, "uuid" | "fiscalYear">
      }
    | {
          collection: "lockers"
          value: DeepPartialWithRequired<Locker, "uuid" | "fiscalYear">
      }
    | {
          collection: "transactions"
          value: DeepPartialWithRequired<Transaction, "uuid" | "fiscalYear">
      }
    | {
          collection: "unreconciledTransactions"
          value: DeepPartialWithRequired<Transaction, "uuid" | "fiscalYear">
      }
    | {
          collection: "otonomBatches"
          value: DeepPartialWithRequired<OtonomBatch, "uuid">
      }
    | {
          collection: "posts"
          value: DeepPartialWithRequired<Post, "uuid">
      }
    | {
          collection: "penalties"
          value:
              | DeepPartialWithRequired<CustomPenalty, "uuid" | "kind">
              | DeepPartialWithRequired<DefaultPenalty, "kind">
      }
    | {
          collection: "checks"
          value: DeepPartialWithRequired<Check, "uuid">
      }
    | {
          collection: "requests"
          value: DeepPartialWithRequired<Request, "uuid">
      }
    | {
          collection: "requestEmails"
          value: DeepPartialWithRequired<RequestEmailDocument, "uuid">
      }
    | {
          collection: "requestTags"
          value: DeepPartialWithRequired<RequestTag, "id">
      }
    | {
          collection: "budgets"
          value: DeepPartialWithRequired<Budget, "uuid">
      }
    | {
          collection: "preparedOtonomFiles"
          value: OtonomFileId
      }
    | {
          collection: "buildings"
          value: DeepPartialWithRequired<Building, "buildingRef">
      }
    | {
          collection: "users"
          value: DeepPartialWithRequired<BuildingUser, "uid">
      }
    | {
          collection: "invitedUsers"
          value: DeepPartialWithRequired<InvitedUser, "email">
      }
    | {
          collection: "emailTemplates"
          value: DeepPartialWithRequired<EmailTemplate, "kind">
      }
    | {
          collection: "secretsBankAccounts"
          value: { aid: string }
      }
    | {
          collection: "metadata"
          value: { id: MetadataDocuments }
      }
    | {
          collection: "logs"
          value: DeepPartialWithRequired<Log, "uuid">
      }
    | {
          collection: "locks"
          value: DeepPartialWithRequired<DBLock, "name">
      }
    | {
          collection: "creditNotes"
          value: DeepPartialWithRequired<CreditNote, "uuid">
      }
    | {
          collection: "buildingGroups"
          value: DeepPartialWithRequired<BuildingGroup, "id">
      }
    | {
          collection: "postTemplates"
          value: DeepPartialWithRequired<PostTemplate, "id">
      }
    | {
          collection: "changeRequests"
          value: DeepPartialWithRequired<ChangeRequest, "id">
      }
    | {
          collection: "requestEmailTemplates"
          value: DeepPartialWithRequired<RequestEmailTemplate, "id">
      }

/** Returns the id for a collection. */
export function idForCollection({ collection, value }: IdForCollectionInput): string {
    switch (collection) {
        case "metadata":
        case "buildingGroups":
        case "postTemplates":
        case "changeRequests":
        case "requestEmailTemplates":
        case "requestTags":
            return value.id
        case "users":
            return value.uid
        case "invitedUsers":
            return value.email
        case "emailTemplates":
            return value.kind
        case "buildings":
            return value.buildingRef
        case "otonomBatches":
        case "posts":
        case "checks":
        case "requests":
        case "requestEmails":
        case "budgets":
        case "logs":
        case "creditNotes":
            return value.uuid
        case "locks":
            return value.name
        case "penalties":
            return value.kind === "customPenalty" || value.kind === "customFee" ?
                    value.uuid
                :   value.kind
        case "preparedOtonomFiles":
            return value.kind === "units" ?
                    "all-units"
                :   `supplier-${value.supplierUUID}#${value.fiscalYear}`
        case "secretsBankAccounts":
            return value.aid
        case "banks":
        case "categories":
        case "parkings":
        case "lockers":
        case "transactions":
        case "unreconciledTransactions":
        case "people":
        case "units":
        case "suppliers":
            return `${value.uuid}#${value.fiscalYear}`
        default: {
            const exhaustiveCheck: never = collection
            throw new Error(`Unhandled collection in idForCollection: ${exhaustiveCheck}`)
        }
    }
}
